import Layout from '@/layout/index.vue'

const messageRouter = {
  path: '',
  component: Layout,
  meta: { title: '消息管理' },
  children: [
    {
      path: '/message/app/list',
      component: () => import('@/views/operation/message/appMessage/list.vue'),
      meta: {
        title: 'APP消息推送列表'
      }
    },
    {
      path: '/message/app/detail',
      component: () => import('@/views/operation/message/appMessage/detail.vue'),
      meta: {
        title: 'APP消息推送详情'
      }
    },
    {
      path: '/message/list',
      component: () => import('@/views/ldx/message/list.vue'),
      meta: {
        title: '消息列表'
      }
    },
    {
      path: '/message/msgDetail', name: 'MsgDetail',
      component: () => import('@/views/ldx/message/msgDetail.vue'),
      meta: {
        title: '运营主动推送详情'
      }
    },
    {
      path: '/message/nodes', name: 'MsgNodes',
      component: () => import('@/views/ldx/message/nodes.vue'),
      meta: {
        title: '节点消息'
      }
    }
  ]
}

export default messageRouter

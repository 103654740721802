import * as createForm from './forms';
import * as createTable from './tables';

function formInit(vm, forms = 'forms') {
  return {
    init: data => vm[forms].fApi = data
  };
}


export const commentList = vm => createPage(vm, {table: 'commentList'});
export const contentList = vm => createPage(vm, {table: 'contentList'});
export const signUp = vm => createPage(vm, {form: 'signUp', table: 'signUp'});
export const rankingList = vm => createPage(vm, {form: 'rankingList', table: 'rankingList'});
export const userVoucher = vm => createPage(vm, {form: 'userVoucher', table: 'userVoucher'});


export const offlineList = vm => {
  const componentArr = [
    {type: 'Forms', attr: 'forms', on: {...formInit(vm)}},
    {type: 'Tables', attr: 'tables'},
    {type: 'Modal', attr: 'dialogCode'}
  ];

  const dialogCode = {
    ref: 'dialog',
    infos: {
      type: 'showCode',
      attrs: {title: '现场签到码', width: '800'},
      config: {ds: {}},
    }
  };

  return {
    componentArr, dialogCode,
    ...createForm.offlineList(vm),
    ...createTable.offlineList(vm)
  };
}

function createPage(vm, options = {}, data = {}) {
  const componentArr = [
    {type: 'Forms', attr: 'forms', on: {...formInit(vm)}, iscreate: options.form ? false : true},
    {type: 'Tables', attr: 'tables', iscreate: options.table ? false : true},
    {type: 'Modal', attr: 'dialogForm', iscreate: options.dialog ? false : true}
  ];

  const dialogForm = {
    ref: 'dialog',
    infos: {
      type: options.dialogType || 'dialogForm',
      attrs: {title: '', width: '500px', class: options.dialog || '', ...options.dialogAttr || {}},
      config: {dialogType: options.dialog || '', ds: ''}
    }
  };

  const from = options.form ? createForm[options.form](vm) : {};
  const table = options.table ? createTable[options.table](vm) : {};
  const dialog = options.dialog ? dialogForm : {};

  return {componentArr, ...data, ...from, ...table, dialogForm: dialog};
}
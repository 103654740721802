import { mapState, mapActions } from 'vuex';

const WIDTH = 992 // refer to Bootstrap's responsive design

export default {
  name: 'ResizeMixin',
  computed: {
    ...mapState({
      device: (state) => state.app.device,
      sidebar: (state) => state.app.sidebar,
    })
  },
  watch: {
    $route() {
      if (this.device === "Mobile" && this.sidebar.opened) {
        this.CloseSideBar(false)
      }
    }
  },

  beforeMount() {
    window.addEventListener('resize', this.resizeHandler)
  },

  mounted() {
    const isMobile = this.isMobile()
    if (isMobile) {
      this.ToggleDevice('Mobile');
      this.CloseSideBar(true)
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler)
  },
  methods: {
    ...mapActions({
      CloseSideBar: "app/CloseSideBar",
      ToggleDevice: "app/ToggleDevice"
    }),
    isMobile() {
      const rect = document.body.getBoundingClientRect()
      return rect.width - 1 < WIDTH
    },
    resizeHandler() {
      if (!document.hidden) {
        const isMobile = this.isMobile()
        this.ToggleDevice(isMobile ? 'Mobile' : 'Desktop')
        if (isMobile) {
          this.CloseSideBar(true)
        }
      }
    }
  }
}

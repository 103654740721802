import Layout from '@/layout/index.vue';

const activityRouter = {
  path: '',
  component: Layout,
  meta: { title: '活动' },
  children: [
    {
      path: '/nb/activity/apply/list',
      component: () => import('@/views/nb/activity/apply/list.vue'),
      meta: {
        title: '活动报名',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/nb/activity/apply/detail',
      component: () => import('@/views/nb/activity/apply/detail.vue'),
      meta: {
        title: '活动详情',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/nb/activity/apply/check/list',
      component: () => import('@/views/nb/activity/apply/check/index.vue'),
      meta: {
        title: '报名商品',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/nb/activity/apply/check/addGoods',
      component: () => import('@/views/nb/activity/apply/check/addGoods.vue'),
      meta: {
        title: '添加商品',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/nb/activity/group/list',
      component: () => import('@/views/nb/activity/group/list.vue'),
      meta: {
        title: '商品分组',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/nb/activity/group/goodsList',
      component: () => import('@/views/nb/activity/group/goodsList.vue'),
      meta: {
        title: '分组商品',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    // {
    //   path: '/nb/activity/apply/bigPromotion/list',
    //   component: () => import('@/views/nb/activity/apply/bigPromotion/list.vue'),
    //   meta: {
    //     title: '商品分组-大促',
    //     icon: 'iconcaiwurenminbizhifux'
    //   }
    // },
    // {
    //   path: '/nb/activity/apply/bigPromotion/chooseGoods',
    //   component: () => import('@/views/nb/activity/apply/bigPromotion/chooseGoods.vue'),
    //   meta: {
    //     title: '分组-选择商品',
    //     icon: 'iconcaiwurenminbizhifux'
    //   }
    // },
    {
      path: '/nb/activity/live/index',
      component: () => import('@/views/nb/activity/live/index.vue'),
      meta: {
        title: '直播',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/nb/activity/live/activityAdd',
      component: () => import('@/views/nb/activity/live/activity/addAndEdit.vue'),
      meta: {
        title: '直播详情',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/nb/activity/live/activityControl',
      component: () => import('@/views/nb/activity/live/activity/control.vue'),
      meta: {
        title: '直播管理',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/operation/entryCode/index',
      component: () => import('@/views/operation/entryCode/index.vue'),
      meta: {
        title: '入场码',
      },
    },
    {
      path: '/operation/meeting/sign',
      component: () => import('@/views/operation/meetingSign/list.vue'),
      meta: {
        title: '签到白名单',
      },
    },
    {
      path: '/nb/exhibition/apply/',
      component: () => import('@/views/nb/exhibition/apply/index.vue'),
      meta: {
        title: '展会报名',
        icon: 'iconcaiwurenminbizhifux',
      },
    },{
      path: '/nb/exhibition/detail',
      component: () => import('@/views/nb/exhibition/detail/index.vue'),
      meta: {
        title: '展会详情',
        icon: 'iconcaiwurenminbizhifux',
      },
    }
  ],
};

export default activityRouter;

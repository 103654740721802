const base = APPLICATION_ENV.SCM_API;
const scmApis = {
  supply_settlement: {
    page: `${base}platform/supply-settlement/settlement/page`, // 结算单列表
    download_settlement: `${base}platform/supply-settlement/download-settlement`, // 导出结算单
    download_settlement_goods: `${base}platform/supply-settlement/download-settlement-goods`, // 导出结算单明细
  },
  supply_withdraw: {
    page: `${base}platform/supply-withdraw/page`, // 运营后台提现列表
    batch_agree: `${base}platform/supply-withdraw/batch-agree`, // 批量打款
    batch_reject: `${base}platform/supply-withdraw/batch-reject`, // 驳回打款
    download: `${base}platform/supply-withdraw/download`, // 导出报表
    download_attachment: `${base}platform/supply-withdraw/download-attachment`, // 导出打款附件
  },
};

export default scmApis;

import tracker from './config'

const resources = ['SCRIPT', 'IMG', 'LINK', 'VIDEO', 'AUDIO'];

window.addEventListener('error', (e) => {
    if (!(e instanceof ErrorEvent)) {
        if (resources.includes(e.target.tagName)) {
            // 上报属于资源类型的错误
            return tracker.sendImmediate({
                type: 'resourceError',
                content: {
                    HTMLElement: e.target.tagName,
                    src: e.target.src || e.target.href,
                }
            })
        }
    } else {
        // JS运行错误
        return tracker.sendImmediate({
            type: 'jsError',
            content: {
                target: e.target.tagName,
                file: e.filename,
                lineno: e.lineno,
                colno: e.colno,
                message: e.error?.message,
                stack: e.error?.stack
            }
        })
    }
}, true)

import {
  login,
  logout,
  getUserInfo
} from '@/service/userService'
import {
  getToken,
  setToken,
  removeToken
} from '@/utils/cookies'


const state = {
  token: getToken() || '',
  name: '',
  avatar: '',
  introduction: '',
  roles: []
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  }
}

const actions = {
  Login({ commit }, userInfo) {
    let { username, password } = userInfo
    username = username.trim()

    return new Promise(resolve => {
      login({ userName: username, password }).then(res => {
        if(res.code === 0) {
          localStorage.setItem('token', res.data.token);
          setToken(res.data.token);
          commit('SET_TOKEN', res.data);
        }
        resolve(res);
      });
    })
  },
  ResetToken({ commit }) {
    removeToken()
    commit('SET_TOKEN', '');
    commit('SET_ROLES', []);
  },
  GetUserInfo({ commit, state }) {
    if (state.token === '') {
      throw Error('GetUserInfo: token is undefined!')
    }


    getUserInfo().then(res => {
      if (!res.data) {
        throw Error('Verification failed, please Login again.')
      }
      let { roleIdList, userName, avatarImage, introduction } = res.data;
      if (!roleIdList || roleIdList.length <= 0) {
        roleIdList = []
      }
      commit('SET_ROLES', roleIdList);
      commit('SET_NAME', userName);
      commit('SET_AVATAR', avatarImage);
      commit('SET_INTRODUCTION', introduction);
    })

  },
  async LogOut({ commit, state }) {
    if (state.token === '') {
      throw Error('LogOut: token is undefined!')
    }
    await logout()
    removeToken()
    commit('SET_TOKEN', '');
    commit('SET_ROLES', []);
  }
}


export default {
  namespaced: true,
  state,
  mutations,
  actions
}

import axios from '@/http/axios'
import api from '@/http/api'
import _ from 'lodash'
import qs from 'qs'
import { message } from '@/utils/message'
import mixinOpt from '../mixins';

const install = function(Vue, options) {
    //属性扩展
    Vue.prototype.$axios = axios;
    Vue.prototype.$api = api;
    Vue.prototype._ = _;
    Vue.prototype.$qs = qs;
    Vue.prototype.$message = message;

    //混合
    Vue.mixin(mixinOpt);
}


export default {install};
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "navbar",
      style: { background: _vm.subjectObj.subjectColor },
    },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { ToggleSideBar: _vm.toggleSideBar },
      }),
      _c("breadcrumb", {
        staticClass: "breadcrumb-container",
        attrs: { id: "breadcrumb-container" },
      }),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "click" },
            },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("img", {
                  staticClass: "user-avatar",
                  attrs: {
                    src: _vm.userInfo.avatar
                      ? _vm.userInfo.avatar
                      : "https://wsnbh-img.hzanchu.com/acimg/176f2e304e062651a38fda4b384810fb.jpeg?x-oss-process=image/resize,l_300",
                  },
                }),
                _c("span", [_vm._v(_vm._s(_vm.userInfo.userName))]),
                _c("i", { staticClass: "el-icon-caret-bottom" }),
              ]),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c("el-dropdown-item", [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.editInfo },
                      },
                      [_vm._v(" 编辑资料 ")]
                    ),
                  ]),
                  _c("el-dropdown-item", [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: {
                          click: function ($event) {
                            _vm.pswVisible = true
                            _vm.setShow = false
                          },
                        },
                      },
                      [_vm._v(" 修改密码 ")]
                    ),
                  ]),
                  _c("el-dropdown-item", { attrs: { divided: "" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.logout },
                      },
                      [_vm._v(" 退出登录 ")]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.dialogShow
        ? _c("edit-info", {
            on: { closeDialog: _vm.closeDialog, getInfo: _vm.getInfo },
          })
        : _vm._e(),
      _c("change-psw", {
        attrs: { "psw-visible": _vm.pswVisible },
        on: { cancelPsw: _vm.cancelPsw },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Layout from '@/layout/index.vue'

const leaseRouter = {
  path: '',
  component: Layout,
  meta: { title: '农房租赁' },
  children: [
    {
      path: '/lease/list',
      component: () => import('@/views/lease/manage.vue'),
      meta: {
        title: '房租管理'
      }
    },
    {
      path: '/lease/info',
      component: () => import('@/views/lease/info.vue'),
      meta: {
        title: '房屋详情'
      }
    },
    {
      path: '/lease/intention',
      component: () => import('@/views/lease/intention.vue'),
      meta: {
        title: '用户意向信息'
      }
    },
  ]
}

export default leaseRouter
import Layout from '@/layout/index.vue';

const goodsRouter = {
  path: '',
  component: Layout,
  meta: { title: '私域工具' },
  children: [
    {
      path: '/privateSphere/userList',
      component: () => import('@/views/privateSphere/userList.vue'),
      meta: {
        title: '用户列表',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/privateSphere/groupPurchaseList',
      component: () => import('@/views/privateSphere/groupPurchaseList.vue'),
      meta: {
        title: '团购列表',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/privateSphere/groupPurchaseInfo',
      component: () => import('@/views/privateSphere/groupPurchaseInfo.vue'),
      meta: {
        title: '团购详情',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/privateSphere/goodsList',
      component: () => import('@/views/privateSphere/goodsList.vue'),
      meta: {
        title: '商品列表',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/privateSphere/goodsInfo',
      component: () => import('@/views/privateSphere/goodsInfo.vue'),
      meta: {
        title: '商品详情',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/privateSphere/orderList',
      component: () => import('@/views/privateSphere/orderList.vue'),
      meta: {
        title: '订单列表',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/privateSphere/orderInfo',
      component: () => import('@/views/privateSphere/orderInfo.vue'),
      meta: {
        title: '订单详情',
        icon: 'iconcaiwurenminbizhifux',
      },
    }
  ],
};

export default goodsRouter;

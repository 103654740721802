import Layout from '@/layout/index.vue'

const templateManageRouter = {
  path: '',
  component: Layout,
  meta: { title: '模板管理' },
  children: [
    // {
    //   path: '/templates/list',
    //   component: () => import('@/views/templates/list.vue'),
    //   meta: {
    //     title: '模板管理',
    //     icon: 'iconcaiwurenminbizhifux'
    //   }
    // },
    // {
    //   path: '/templates/pageList',
    //   component: () => import('@/views/templates/pageList.vue'),
    //   meta: {
    //     title: '页面管理',
    //     icon: 'iconcaiwurenminbizhifux'
    //   }
    // }
  ]

}

export default templateManageRouter

<template>
  <div
    :class="[
      'menu-wrapper',
      isCollapse ? 'simple-mode' : 'full-mode',
      { 'first-level': isFirstLevel },
    ]"
  >
    <template v-if="!item.children || item.children.length === 0">
      <sidebar-item-link
        v-if="item.router"
        :to="resolvePath(item.router)"
      >
        <el-menu-item
          :index="resolvePath(item.router)"
          :class="{ 'submenu-title-noDropdown': isFirstLevel }"
        >
          <i
            v-if="item.icon"
            :class="['iconfont', item.icon]"
          ></i>
          <span
            v-if="item.name"
            slot="title"
          >{{ item.name }}</span>
        </el-menu-item>
      </sidebar-item-link>
    </template>
    <el-submenu
      v-else
      :index="resolvePath(item.router)"
      popper-append-to-body
    >
      <template slot="title">
        <i
          v-if="item.icon && item.icon !== 0"
          :class="['iconfont', item.icon]"
        ></i>
        <span
          v-if="item.name"
          slot="title"
        >{{ item.name }}</span>
      </template>
      <template v-if="item.children">
        <sidebar-item
          v-for="child in item.children"
          :key="child.menu_id"
          :item="child"
          :is-collapse="isCollapse"
          :is-first-level="false"
          :base-path="resolvePath(child.router)"
          class="nest-menu"
        />
      </template>
    </el-submenu>
  </div>
</template>

<script>
import path from 'path';
import { isExternal } from '@/utils/validate';
import SidebarItemLink from './SidebarItemLink.vue';

export default {
  name: 'sidebar-item',
  components: {
    SidebarItemLink,
  },
  props: {
    item: {
      require: true,
    },
    isCollapse: {
      default: false,
    },
    isFirstLevel: {
      default: true,
    },
    basePath: {
      default: '',
    },
  },
  computed: {
    showingChildNumber() {
      if (this.item.children) {
        const showingChildren = this.item.children.filter((item) => {
          if (item.meta && item.meta.hidden) {
            return false;
          }
          return true;
        });
        return showingChildren.length;
      }
      return 0;
    },
    theOnlyOneChild() {
      if (this.showingChildNumber > 1) {
        return null;
      }
      if (this.item.children) {
        for (let child of this.item.children) {
          if (!child.meta || !child.meta.hidden) {
            return child;
          }
        }
      }
      // If there is no children, return itself with path removed,
      // because this.basePath already conatins item's path information
      return { ...this.item, path: '' };
    },
  },
  methods: {
    resolvePath(routePath) {
      // /a/b?url=http://aaa.com  判断有url=，编码url=后面的内容
      const reList = routePath.match(/url=(\S*)/);
      if (reList) {
        routePath = routePath.replace(reList[1], encodeURIComponent(reList[1]));
      }

      if (isExternal(routePath)) {
        console.log(routePath);
        return routePath;
      }
      if (isExternal(this.basePath)) {
        return this.basePath;
      }
      return path.resolve(this.basePath, routePath);
    },
  },
};
</script>

<style lang="scss">
.full-mode {
  .nest-menu .el-submenu > .el-submenu__title,
  .el-submenu .el-menu-item {
    min-width: $sideBarWidth !important;
    &:hover {
      background-color: #3bccf8 !important;
      color: #fff !important;
    }
  }
}

.full-mode {
  .el-menu-item {
    color: #222 !important;
    background: transparent !important;
    .iconfont {
      color: #3bccf8;
    }
  }
  .el-menu-item:hover {
    color: #fff !important;
    .iconfont {
      color: #fff !important;
    }
  }
}
.full-mode {
  // .router-link-active {
  //   .el-menu-item {
  //     color: #fff !important;
  //     background: #3BCCF8 !important;
  //     .iconfont {
  //       color: #fff;
  //     }
  //   }
  // }
}

.simple-mode {
  &.first-level {
    .submenu-title-noDropdown {
      padding: 0 !important;
      position: relative;
      .el-tooltip {
        padding: 0 !important;
        .iconfont {
          margin-left: 20px;
        }
      }
    }
    .el-submenu {
      overflow: hidden;
      & > .el-submenu__title {
        padding: 0px !important;
        .el-submenu__icon-arrow {
          display: none;
        }
        & > .iconfont {
          margin-left: 20px;
        }
        & > span {
          visibility: hidden;
          display: none;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.iconfont {
  color: #3bccf8;
}
span {
  margin-left: 10px;
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isExternal(_vm.to)
    ? _c(
        "router-link",
        { attrs: { to: "/web/page?link=" + _vm.to } },
        [_vm._t("default")],
        2
      )
    : _c("router-link", { attrs: { to: _vm.to } }, [_vm._t("default")], 2)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
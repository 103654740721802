<template>
<div class="pbulic-temp" :class="$route.name + '-page'">
  <component v-show="!item.hidden" :is="item.type" v-bind="vm[item.attr]" v-on="item.on || {}" v-for="(item, index) in filterComponents" :key="index"></component>
</div>
</template>

<script>
import * as datas from './configure/datas';
import * as mounteds from './configure/mounted';
import {importAll} from './common/com';

const components = importAll(require.context('./components/', false, /\.(vue|js)$/), true);

export default {
  components: {...components},
  data() {
    const name = this.$route.name;

    return {
      vm: this,
      name,
      ...datas[name](this)
    };
  },
  computed: {
    filterComponents() {
      return this.componentArr.filter(item => !item.iscreate);
    }
  },
  mounted() {
    this.getData();
    this.init();
  },
  methods: {
    getData() {
      if(mounteds[this.name] && mounteds[this.name].getData) mounteds[this.name].getData.call(this);
    },
    init() {
      if(mounteds[this.name] && mounteds[this.name].init) mounteds[this.name].init.call(this);
    }
  },
  destroyed() {
    if(mounteds[this.name] && mounteds[this.name].destroyed) mounteds[this.name].destroyed.call(this);
  },
  beforeRouteLeave (to, from, next) {
    if(mounteds[this.name] && mounteds[this.name].beforeRouteLeave) mounteds[this.name].beforeRouteLeave.call(this, to, from, next);
    else next();
  }
};
</script>

<style lang="scss">
.pbulic-temp{
  padding: 15px;
  .block-style{padding: 20px; margin-bottom: 15px; background: #fff;}

  .com-myform{
    @extend .block-style;
    .el-form-item__label{line-height: 20px; font-size: 12px; text-align: left;}
    .el-input__inner{height: 28px;}
    .el-form-item{display: inline-flex; flex-direction: column; margin-bottom: 0; vertical-align: bottom;}
  }

  .my-table{
    @extend .block-style;

    .el-table{
      th{background-color:#d9dde1; color: #0d0202;}
    }
    .el-pagination{text-align:right;}
  }
}
</style>
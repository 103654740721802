const base = APPLICATION_ENV.MANAGE_API;
// const base = 'http://10.10.11.155:8089/'
// const base = 'http://10.10.10.154:8089/' 富强
const adminApis = {
  adPosition: {
    list: `${base}backplat/adPosition/list`, // 广告位列表
    view: `${base}backplat/adPosition/view`, // 广告位查看
    add: `${base}backplat/adPosition/add`, // 广告位新增
    remove: `${base}backplat/adPosition/remove`, // 广告位删除
    edit: `${base}backplat/adPosition/edit`, // 广告位编辑
  },
  ad: {
    list: `${base}backplat/ad/list`, // 广告列表
    view: `${base}backplat/ad/view`, // 广告查看
    add: `${base}backplat/ad/add`, // 广告新增
    remove: `${base}backplat/ad/remove`, // 广告删除
    edit: `${base}backplat/ad/edit`, // 广告编辑
    editSort: `${base}backplat/ad/editSort`, // 广告排序
  },
  config_center: {
    list: `${base}backplat/config_center/list`, // 配置中心列表
    detail: `${base}backplat/config_center/detail`, // 配置中心详情
    add: `${base}backplat/config_center/add`, // 配置中心新增
    edit: `${base}backplat/config_center/edit`, // 配置中心编辑
  },
  config_field: {
    view: `${base}backplat/config_field/view`, // 金刚区查看
    add: `${base}backplat/config_field/add`, // 金刚区新增
    operate: `${base}backplat/config_field/operate/config_field`, // 金刚区删除,推荐，取消推荐，排序，显示，隐藏
    edit: `${base}backplat/config_field/edit`, // 金刚区编辑
    list: `${base}backplat/config_field/list`, // 金刚区列表
    detail: `${base}backplat/config_field/detail`, // 金刚区详情
  },
  version_config: {
    detail: `${base}backplat/version/appLiveDetail`, // 直播入口配置详情
    applyLoginVersion: `${base}backplat/version/applyLoginVersion`, // 苹果登录版本详情
    setDetail: `${base}backplat/version/editApplyLoginVersion`, // 苹果登录版本编辑
    set: `${base}backplat/version/editAppLive`, // 配置直播入口
    list: `${base}backplat/version/list`, // 版本配置中心列表
    edit: `${base}backplat/version/edit`, // 版本配置中心编辑
  },
  mainActivity: { // 入场码
    entryCode: {
      statList: `${base}mallmanage/trade-partic-stat/getPartStatistics`, // 运营后台--人数统计
      setPersonNum: `${base}mallmanage/trade-partic-stat/editTotalNum`, // 运营后台--人数控制
      entryPersonList: `${base}mallmanage/trade-partic-member/page`, // 运营后台--参会人员管理列表
      addPersonRole: `${base}mallmanage/trade-partic-member/backplatAdd`, // 增加入场人员
      auditPerson: `${base}mallmanage/trade-partic-member/audit`, // 运营后台--参会人员审核
      importData: `${base}mallmanage/trade-partic-member/importMembers`, // 批量导入入场人员信息
      detail: `${base}mallmanage/trade-partic-member/detail`, // 运营后台--参会人员详情
    },
    // 签到
    meetingWhiteList: {
      list: `${base}/backplat/white_list/list`, // 列表
      add: `${base}/backplat/white_list/add`, // 新增
      remove: `${base}/backplat/white_list/remove`, // 删除
      set_sign: `${base}partic_member_detail/set_sign`, // 设置参会人员已签到
    },
  },
  standardApp: { // 标准化小程序
    goodsList: `${base}backplat/standardApp/marketing/template/goods/list`, // 选择商品列表
    queryByCommonIds: `${base}backplat/standardApp/marketing/queryByCommonIds`, // 根据组件内ids查询渲染数据
    getBackplatCouponList: `${base}backplat/standardApp/marketing/backplat/coupon/list`, // 平台券选择列表
    getSupplierCouponList: `${base}backplat/standardApp/marketing/supplier/coupon/list`, // 商家店铺券选择列表
    getCouponDetail: `${base}backplat/standardApp/marketing/backplat/coupon/detail`, // 券包详情
    getCategoryList: `${base}backplat/standardApp/marketing/front/category/list`, // 前台类目列表
    addPage: `${base}backplat/standardApp/marketing/template/add`, // 新建页面
    editPage: `${base}backplat/standardApp/marketing/template/edit`, // 编辑页面
    getPageDetail: `${base}backplat/standardApp/marketing/template/detail`, // 页面详情表
    getPageList: `${base}backplat/standardApp/marketing/template/list`, // 模板页面列表
    page_publish: `${base}backplat/standardApp/marketing/template/public`, // 模板页面发布
    page_delete: `${base}backplat/standardApp/marketing/template/delete`, // 模板页面删除
    page_copy: `${base}backplat/standardApp/marketing/template/copy`, // 模板页面复制
    setHomePage: `${base}backplat/standardApp/marketing/template/home/page`, // 设置首页
    getNavigationList: `${base}backplat/trade-channel-navigation/list`, // 导航列表
    updateNavigationList: `${base}backplat/trade-channel-navigation/edit`, // 编辑导航
  },

  // 地图管理
  appletsMap: {
    mapList: `${base}backplat/channel_map/page`,
    mapAction: `${base}backplat/channel_map/active`,
    mapRemove: `${base}backplat/channel_map/remove`,
    mapDetail: `${base}backplat/channel_map/detail`,
  },
  channelTelephone: {
    page: `${base}mallmanage/channel_telephone/page`, // 便民电话列表
    active: `${base}mallmanage/channel_telephone/active`, // 便民电话列表
    remove: `${base}mallmanage/channel_telephone/remove`, // 便民电话列表
  },
  channelColor: {
    detail: `${base}/backplat/trade-channel-color/detail`, // 色系详情
    edit: `${base}/backplat/trade-channel-color/edit`, // 色系编辑
  },
  // 排行榜
  rank: {
    list: `${base}backplat/ranking/page`, // 列表
    enableRank: `${base}backplat/ranking/enable`, // 启用
    disableRank: `${base}backplat/ranking/disable`, // 禁用
    goodsList: `${base}backplat/ranking/goods_list_add`, // 选择商品列表
    storeList: `${base}backplat/ranking/store_list_add`, // 选择店铺列表
    promList: `${base}backplat/ranking/prom_list_add`, // 选择活动列表
    backCategoryList: `${base}backplat/ranking/backCategory_list_add`, // 选择后台类目列表
    siteList: `${base}backplat/ranking/site_list_add`, // 选择场馆类目列表
    add: `${base}backplat/ranking/add`, // 新增排行榜
    edit: `${base}backplat/ranking/edit`, // 编辑排行榜
    view: `${base}backplat/ranking/view`, // 查看排行榜
    delete: `${base}backplat/ranking/delete`, // 删除
    viewBind: `${base}backplat/ranking/view_bind_page`, // 查看排行榜分组绑定分页信息
    bind: `${base}backplat/ranking/goods_batch_bind`, // 批量绑定商品id
    bindStore: `${base}backplat/ranking/store_batch_bind`, // 批量绑定店铺id
    tradePage: `${base}/backplat/trade-consumer-top/page`, // 消费排行榜分页
    tradeDetail: `${base}/backplat/trade-consumer-top/detail`, // 查看消费排行榜
    tradeDelete: `${base}/backplat/trade-consumer-top/delete`, // 删除消费排行榜
    tradeAdd: `${base}/backplat/trade-consumer-top/active`, // 新增/编辑
  },
  // 首页氛围
  atmosphere: {
    configList: `${base}backplat/home_config/list`,
    editConfig: `${base}backplat/home_config/add`,
    atmosphereList: `${base}backplat/home_atmosphere/page`,
    edit: `${base}backplat/home_atmosphere/edit`,
    operate: `${base}/backplat/home_config/operate`,
  },
  feedback: {
    page: `${base}backplat/feedback/page`, // 运营端-预警管理-用户投诉举报处理-投诉建议列表
    feedbackType: `${base}/backplat/violation_type/list`, // 查询投诉类型（包含场景）
    listByViolationId: `${base}/backplat/violation_description/listByViolationId`, // 运营端-预警管理-用户投诉举报处理-根据场景获取所有违规定义
    reply: `${base}/backplat/feedback/reply`, // 运营端-预警管理-用户投诉举报处理-仅回复
    relatevaildate: `${base}/backplat/feedback/relatevaildate`, // 运营端-预警管理-用户投诉举报处理-回复并关联商家违规
    listProductQuality: `${base}backplat/violation_description/listProductQuality`, // 运营端-预警管理-用户投诉举报处理-获取质量问题定义
    detail: `${base}/backplat/feedback/detail`, // 运营端-预警管理-用户投诉举报处理-投诉建议详情
    download: `${base}backplat/feedback/download`, // 下载提现列表数据
  },

  // 三色码预警审核
  warningAudit: {
    list: `${base}backplat/violation_approve/list`, // 审核列表
    rejectByPlatform: `${base}backplat/violation_approve/rejectByPlatform`, // 平台驳回(申诉违规/黄转绿/红转绿)
    agreeByPlatform: `${base}backplat/violation_approve/agreeByPlatform`, // 平台通过(申诉违规/黄转绿)
    redToGreen: `${base}backplat/violation_approve/agreeByPlatformAndCommitToGov`, // 平台通过并提交主管部门审核(红转绿)
    detail: `${base}backplat/violation_approve/detail`, // 查看详情
    approval: `${base}backplat/violation_approve/approval`, // 审批
    agreeByPlatformOnRed2Green: `${base}backplat/violation_approve/agreeByPlatformOnRed2Green`, // 鱼米之乡-红转绿-通过审核
  },
  // 三色码预警管理
  colorCode: {
    list: `${base}backplat/violation_statistics/list`, // 运营端-三色码预警列表
    threeColorDetail: `${base}backplat/violation_detail/list`, // 运营端-三色码预警列表-详情
    add: `${base}backplat/violation_detail/list`, // 运营端-三色码预警列表-新增
    detail: `${base}/backplat/feedback/detail`, // 运营端-预警管理-用户投诉举报处理-投诉建议详情
    commitToGov: `${base}/backplat/violation_approve/commitToGov`, // 提交主管部门
    transcodeLog: `${base}/backplat/supplier_transcode_log/page`, // 运营端-三色码-转码记录
    removeThreeColorDetail: `${base}/backplat/violation_detail/delete`, // 运营端-三色码-查看详情删除
    listProductQuality: `${base}/backplat/violation_description/listProductQuality`, // 质量定义
    addViolation: `${base}backplat/violation_statistics/add`, // 新增三色码商家违规
    queryGoodInfo: `${base}/backplat/violation_statistics/order_goods`, // 根据订单查询商品信息
  },
  // 企业排行榜-企业管理
  topCompany: {
    companyList: `${base}backplat/top-company/company/list`, // 排行榜公司列表
    add: `${base}backplat/top-company/add`, // 新增公司
    update: `${base}backplat/top-company/update`, // 编辑/禁用公司
    staffList: `${base}backplat/top-company/staff/list`, // 员工列表
    delete: `${base}backplat/top-company/delete`, // 删除公司
  },
  vote: {
    list: `${base}backplat/vote/activity/list`, // 投票活动-活动列表
    delete: `${base}backplat/vote/activity/delete`, // 投票活动-删除活动
    add: `${base}backplat/vote/activity/add`, // 投票活动-新增活动
    detail: `${base}backplat/vote/activity/detail`, // 投票活动-获取活动详情
    edit: `${base}backplat/vote/activity/edit`, // 投票活动-编辑活动
    goodsList: `${base}/backplat/vote/activity/goods/list`, // 投票活动-商品列表
    addGoods: `${base}/backplat/vote/activity/goods/add`,
    deleteGoods: `${base}/backplat/vote/activity/goods/delete/`, // 投票活动-删除商品
    editGoods: `${base}/backplat/vote/activity/goods/edit`,
    export: `${base}/backplat/vote/activity/goods/export`, // 投票活动-导出活动商品
    // 添加内容版本
    activity_content_list: `${base}/backplat/vote/activity/content/list`, // 投票活动-内容列表
    activity_content_addList: `${base}/backplat/vote/activity/content/getAlreadyAddList`, // 投票活动-新增内容时获取已添加的内容列表
    activity_content_add: `${base}/backplat/vote/activity/content/add`, // 投票活动-新增内容
    activity_content_edit: `${base}/backplat/vote/activity/content/edit`, // 投票活动-编辑活动内容（隐藏/显示）
    activity_content_delete: `${base}/backplat/vote/activity/content/delete/`, // 投票活动-删除内容
    activity_goods_addList: `${base}/backplat/vote/activity/goods/getAlreadyAddList`, // 投票活动-新增商品时获取已添加的商品列表
    activity_goods_add: `${base}/backplat/vote/activity/goods/add`, // 投票活动-新增商品
    activity_goods_detailsByIds: `${base}/backplat/vote/activity/goods/detailsByIds`, // 投票活动-根据商品ID展示商品信息
  },
  recommends: {
    list: `${base}backplat/suit_region/list`, // 推荐位列表 不分页  用于适用地区模块获取数据
    add: `${base}backplat/suit_region/add`, // 新增、编辑适用地区
    remove: `${base}backplat/suit_region/remove_region`, // 删除适用地区
    // 推荐位列表
    pageList: type => { // 4 农博优选
      const path = type == 4 ? '/cross/trade-search-recommend' : '/trade-search-recommend';
      return `${base}backplat${path}/page`;
    },
    // 添加、编辑推荐位
    active: type => { // 4 农博优选
      const path = type == 4 ? '/cross/trade-search-recommend' : '/trade-search-recommend';
      return `${base}backplat${path}/active`;
    },
    // 推荐位详情
    detail: type => { // 4 农博优选
      const path = type == 4 ? '/cross/trade-search-recommend' : '/trade-search-recommend';
      return `${base}backplat${path}/detail`;
    },
    // 删除推荐位
    reomove_recommend: type => { // 4 农博优选
      const path = type == 4 ? '/cross/trade-search-recommend' : '/trade-search-recommend';
      return `${base}backplat${path}/reomove/recommend`;
    },
    // 更改推荐状态
    status: type => { // 4 农博优选
      const path = type == 4 ? '/cross/trade-search-recommend' : '/trade-search-recommend';
      return `${base}backplat${path}/change/status`;
    }
  },
  // 营销工具 - 榜单管理
  searchRank: {
    list: `${base}backplat/search_rank/list`, // 榜单列表
    update: `${base}backplat/search_rank/update`, // 更新榜单状态
    sort: `${base}backplat/search_rank/sort`, // 排序（上移、下移、置顶）
    add: `${base}backplat/search_rank/add`, // 新增榜单
    edit: `${base}backplat/search_rank/edit`, // 编辑榜单
    detail: `${base}backplat/search_rank/detail/`, // 榜单详情
    rankDelete: `${base}backplat/search_rank/delete/`, // 删除榜单

    rank_detail_list: `${base}backplat/user_search_rank_detail/list`, // 查看榜单关键词/词条列表
    addShieldedRankKeyWords: `${base}backplat/user_search_rank_detail/addShieldedRankKeyWords`, // 添加榜单屏蔽关键词
    listShielded: `${base}backplat/user_search_rank_detail/listShielded`, // 查看榜单所有屏蔽关键词
    deleteShieldedRankKeyWords: `${base}backplat/user_search_rank_detail/deleteShieldedRankKeyWords`, // 删除榜单屏蔽关键词
    delete: `${base}backplat/user_search_rank_detail/delete`, // 删除榜单关键词/词条
    insert: `${base}backplat/user_search_rank_detail/insert`, // 插入榜单关键词/词条
    editRank: `${base}backplat/user_search_rank_detail/edit`, // 设置词条或者设置推荐位
    listBackend: `${base}backplat/user_search_rank_detail/listBackend`, // 查看插入关键词列表
    copyRank: `${base}backplat/search_rank/copyRank`, // 复制地区所有榜单
  },
  // 黑名单
  blacklist: {
    add: `${base}/backplat/trade-search-black-list/add`, // 新增
    batch_add: `${base}/backplat/trade-search-black-list/batch_add`, // 新增
    list: `${base}/backplat/trade-search-black-list/page`, // 列表
    remove: `${base}/backplat/trade-search-black-list/remove_black_list`, // 删除
  },
  // 常见问题
  helps: {
    page: `${base}backplat/trade-common-question/page`, // 页列表
    remove: `${base}backplat/trade-common-question/remove_question`, // 删除
    handle: `${base}backplat/trade-common-question/add_question`, // 新增、编辑、修改状态
    detail: `${base}backplat/trade-common-question/question_detail`, // 详情
  },
  // 关键词推荐位
  keywords: {
    list: `${base}backplat/match/rule/list`, // 列表
    add: `${base}backplat/match/rule/add`, // 新增
    edit: `${base}backplat/match/rule/edit`, // 编辑
    detail: `${base}backplat/match/rule/detail/`, // 详情
    data: `${base}backplat/match/rule/data/`, // 查看数据
    delete: `${base}backplat/match/rule/delete/`, // 删除
  },
  // 新金刚区
  kings: {
    add: `${base}backplat/config_vajra_district/add`, // 新增
    edit: `${base}backplat/config_vajra_district/edit`, // 编辑
    list: `${base}backplat/config_vajra_district/list`, // 根据区域id获取金刚区列表
    detail: `${base}backplat/config_vajra_district/detail`, // 详情
    handle: `${base}backplat/config_vajra_district/operate/config_vajra_district`, // 操作
  },
  kingst: {
    add: `${base}backplat/config_vajra_v2/add`, // 新增
    edit: `${base}backplat/config_vajra_v2/edit`, // 编辑
    detail: `${base}backplat/config_vajra_v2/detail`, // 详情
    list: `${base}backplat/config_vajra_v2/list`, // 金刚区列表
    handle: `${base}backplat/config_vajra_v2/operate`, // 操作
  },
  // 专题模板列表
  templates_list: `${base}backplat/trade_plugin_template/page`,

  tradePluginTemplate: {
    add: `${base}backplat/trade_plugin_template/add`, // 专题模板新增
    page: `${base}backplat/trade_plugin_template/page`, // 专题模板列表
    detail: `${base}backplat/trade_plugin_template/detail`, // 专题模板详情
    edit: `${base}backplat/trade_plugin_template/edit`, // 专题模板编辑
    remove: `${base}backplat/trade_plugin_template/remove`, // 专题模板删除
    selectGoods: `${base}backplat/trade_plugin_template/selectGoods`, // 查询商品信息
    getListGoods: `${base}backplat/trade_plugin_template/getListGoods`, // 专题模板列表商品
    getListCateGoods: `${base}backplat/trade_plugin_template/getListCateGoods`, // 专题模板分类商品
    edit_info: `${base}backplat/trade_plugin_template/editTemplateInfo`, // 编辑模板信息

    get_prom_list: `${base}backplat/trade_plugin_template/getPromGoodsGrouping`,
    get_select_prom_list: `${base}backplat/trade_plugin_template/selectedPromGoodsGrouping`,
    get_prom_goods_list: `${base}backplat/trade_plugin_template/getGoodsInfoByGroupId`,
    group_list: `${base}backplat/trade_plugin_template/listProm`, // 专题模板获取大促分组列表
    group_goods_list: `${base}backplat/trade_plugin_template/selectPromGoods`, // 专题模板通过分组id获取商品
    groupGoods: `${base}backplat/trade_plugin_template/group/goods`, // 专题拼团商品列表
  },
  tradePluginTemplateData: {
    list: `${base}/backplat/tradePluginTemplateData/list`, // 专题模板数据列表
  },

  // 南浦溪-大厨管理
  trade_applet_chef: {
    page: `${base}backplat/trade_applet_chef/page`, // 大厨列表查询
    detail: `${base}backplat/trade_applet_chef/detail`, // 大厨详情查询
    audit: `${base}backplat/trade_applet_chef/audit`, // 大厨审核
  },
  // 南浦溪-预约记录列表
  guide_appoint_record: {
    page: `${base}backplat/guide_appoint_record/page`, // 分页列表
  },
  // 南浦溪-导览景点-预约信息设置
  guide_appoint: {
    add: `${base}backplat/guide_appoint/add`, // 添加
    detail: `${base}backplat/guide_appoint/detail`, // 详情
  },
  // 用户端底部菜单管理
  home_menu: {
    list: `${base}backplat/home_menu/list`, // 列表
    edit: `${base}backplat/home_menu/edit`, // 编辑
    detail: `${base}backplat/home_menu/detail`, // 详情
    set_sort: `${base}backplat/home_menu/set_sort`, // 设置排序值
  },
  // 积分
  points: {
    user_score_record_page: `${base}backplat/tradeAppletScore/user_score_record_page`, // 分页查询用户积分明细
    addAppletShop: `${base}backplat/tradeAppletShop/add`, // 添加店铺
    removeAppletShop: `${base}backplat/tradeAppletShop/remove`, // 店铺删除
    editAppletShop: `${base}backplat/tradeAppletShop/edit`, // 编辑店铺
    update_status: `${base}backplat/tradeAppletShop/update_status`, // 店铺上下架
    pageAppletShop: `${base}backplat/tradeAppletShop/page`, // 店铺分页查询
    detailAppletShop: `${base}backplat/tradeAppletShop/detail`, // 店铺详情查询
    score_info: `${base}backplat/tradeAppletScore/score_info`, // 查询积分信息
    edit_score_info: `${base}backplat/tradeAppletScore/edit_score_info`, // 编辑积分信息
  },
  // 智能问答
  intelligent: {
    page: `${base}backplat/intelligent_qa/page`, // 列表查询
    add: `${base}backplat/intelligent_qa/add`, // 新增
    delete: `${base}backplat/intelligent_qa/delete`, // 删除
  },
  // 渠道活码（）
  dynamic_code: {
    page: `${base}backplat/dynamic_code/page`, // 列表查询
    add: `${base}backplat/dynamic_code/add`, // 新增
    edit: `${base}backplat/dynamic_code/edit`, // 编辑
    delete: `${base}backplat/dynamic_code/delete`, // 删除
    detail: `${base}backplat/dynamic_code/detail`, // 详情
    update_logo: `${base}backplat/dynamic_code/update_logo`, // 修改logo
    download: `${base}backplat/dynamic_code/download`, // 下载码
    get_dynamic_code_static_list: `${base}dynamic_code_static/get_dynamic_code_static_list`, // 获取动态码统计列表
    excel_dynamic_code_static_list: `${base}dynamic_code_static/excel_dynamic_code_static_list`, // 导出动态码统计列表
  },
  // 频道页
  subjectChannel: {
    style_page: `${base}backplat/component/style/page`, // 分页查询样式列表
    style_getActivityPage: `${base}backplat/component/style/getActivityPage`, // 分页查询活动商品样式列表
    style_detail: `${base}backplat/component/style/detail`, // 查询样式详情
    style_goods: `${base}backplat/component/style/goods`, // 查询商品列表或者店铺列表
    style_add: `${base}backplat/component/style/add`, // 新建样式
    style_edit: `${base}backplat/component/style/edit`, // 编辑样式
    style_enable: `${base}backplat/component/style/enable`, // 启用或停用样式
    style_delete: `${base}backplat/component/style/delete`, // 样式删除
    style_preview: `${base}backplat/component/style/preview`, // 查询样式预览
    getComponentGoodsStyleInfo: `${base}backplat/component/style/getComponentGoodsStyleInfo`, // 单项检索样式选品池商品信息
    getPromGoodsStyleInfo: `${base}backplat/component/style/getPromGoodsStyleInfo`, // 单项检索样式活动商品信息
    entrance_add: `${base}backplat/component/entrance/add`, // 模块入口图新建
    entrance_delete: `${base}backplat/component/entrance/delete`, // 模块入口图删除
    entrance_detail: `${base}backplat/component/entrance/detail`, // 模块入口图详情
    entrance_edit: `${base}backplat/component/entrance/edit`, // 模块入口图编辑
    entrance_page: `${base}backplat/component/entrance/page`, // 模块入口图分页查询
    category_add: `${base}backplat/component/category/add`, // 频道页分类新建
    category_delete: `${base}backplat/component/category/delete`, // 频道页分类删除
    category_detail: `${base}backplat/component/category/detail`, // 频道页分类详情
    category_edit: `${base}backplat/component/category/edit`, // 频道页分类编辑
    category_pages: `${base}backplat/component/category/page`, // 频道页分类分页查询
    category_enable: `${base}backplat/component/category/enable`, // 频道页分类启用
    page_add: `${base}backplat/component/page/add`, // 频道页分类页面新建
    page_delete: `${base}backplat/component/page/delete`, // 频道页分类页面删除
    page_detail: `${base}backplat/component/page/detail`, // 频道页分类页面详情
    page_edit: `${base}backplat/component/page/edit`, // 频道页分类页面编辑
    page_page: `${base}backplat/component/page/page`, // 频道页分类页面分页查询
    entrance_preview: `${base}backplat/component/entrance/preview`, // 模块入口图详情预览
  },
  couponTemplate: {
    templateQueryByCouponIds: `${base}backplat/trade_plugin_template/queryByCouponIds`,
  },
  manageLocal: {
    pageApplyRecord: `${base}/local/specialty//pageApplyRecord`, // 展会列表
    detail: `${base}/local/specialty/detail`, // 详情/查看
    edit: `${base}/local/specialty/edit`, // 编辑
    specialtyCategoryList: `${base}/local/specialty/category/list`, // 产品类目下拉列表列表
    batchDelete: `${base}/local/specialty/batchDelete`,
    specialtyDownload: `${base}/local/specialty/download`,
    specialtyBatchAuditPass: `${base}/local/specialty/batchAuditPass`, // 批量审核通过
    specialtyBatchAuditRefuse: `${base}/local/specialty/batchAuditRefuse`, // 批量审核拒绝
  },
  manageBaseData: {
    region: `${base}base/data/child/region`, // 下级区域 regionCode
    province: `${base}base/data/province/list`,
  }
};

export default adminApis;

import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import Vue from 'vue';
import Router from 'vue-router';
import Layout from '@/layout/index.vue';
import goodsRouterNew from './modules/goods';
import settingRouter from './modules/setting'; // 设置模块
import distributionRouter from './modules/distribution'; // 分销模块
import financeRouter from './modules/finance';
import userRouter from './modules/user';
import storeRouter from './modules/store';
import merchantsRouter from './modules/merchants';
import serviceRouter from './modules/service';
import orderRouter from './modules/order';
import activityRouter from './modules/activity'; // 新船-活动模块
import marketingToolRouter from './modules/marketingTool'; // 新船-营销工具模块
import ContentRouter from './modules/contentManage';
import applicationRouter from './modules/application'; // 外部应用管理
import messageRouter from './modules/message'; // 消息管理
import guideRouter from './modules/guide';
import templatesRouter from './modules/templates';
import templateManageRouter from './modules/templateManang';
import enterpriseRouter from './modules/enterprise'; // 企业管理
import subjectChannelRouter from './modules/subjectChannel'; // 频道页管理
import LeaseRouter from './modules/lease'; // 房屋租赁
import privateSphereRouter from './modules/privateSphere' // 私域工具

NProgress.configure({ showSpinner: false });
Vue.use(Router);

const router = new Router({
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
  mode: 'history',
  base: '/', // process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      component: () => import(/* webpackChunkName: "login" */ '@/views/login/index.vue'),
      meta: {
        title: '登录',
        hidden: true,
      },
    },
    {
      path: '/404',
      component: () => import(/* webpackChunkName: "404" */ '@/views/404.vue'),
      meta: { hidden: true },
    },
    {
      path: '/',
      component: Layout,
      redirect: '/home/index',
      children: [
        {
          path: '/home/dashboard',
          component: () => import(/* webpackChunkName: "dashboard" */ '@/views/home/dashboard/index.vue'),
          meta: {
            title: '概览',
            icon: 'icongailanx',
          },
        },
        {
          path: '/home/message/list',
          component: () => import(/* webpackChunkName: "messagelist" */ '@/views/home/messageList/index.vue'),
          meta: {
            title: '消息通知',
          },
        },
        {
          path: '/home/index',
          component: () => import(/* webpackChunkName: "dashboard" */ '@/views/home/index.vue'),
          meta: {
            title: '首页',
            icon: 'icongailanx',
          },
        },
      ],
    },
    {
      path: '',
      component: Layout,
      meta: { title: '网上农博' },
      children: [
        {
          path: '/web/page',
          component: () => import('@/views/webPage.vue'),
          meta: {
            title: 'iframe页面',
          },
        },
      ],
    },
    {
      path: '',
      component: Layout,
      meta: { title: '网上农博' },
      children: [
        {
          path: '/web/open',
          component: () => import('@/views/webOpen.vue'),
          meta: {
            title: '跳转',
          },
        },
      ],
    },
    {
      path: '',
      component: Layout,
      meta: { title: '测试' },
      children: [
        {
          path: '/test/icon',
          component: () => import('@/views/-test-code-/icon.vue'),
          meta: {
            title: '图标',
          },
        },
      ],
    },

    {
      path: '/lottery',
      name: 'lottery',
      meta: { title: '农博会抽奖' },
      component: () => import('@/pages/lottery/index.vue'),
    },

    // 分销模块
    distributionRouter,
    // 财务模块
    financeRouter,
    // 用户模块
    userRouter,
    // 店铺模块
    storeRouter,
    // 商家模块
    merchantsRouter,
    // 服务商模块
    serviceRouter,
    // 订单模块
    orderRouter,
    goodsRouterNew,
    // 营销模块
    marketingToolRouter,
    // 设置模块
    settingRouter,
    // 活动模块
    activityRouter,
    // 农民说
    ContentRouter,
    // 外部应用管理
    applicationRouter,
    // 消息管理
    messageRouter,
    guideRouter,
    {
      path: '*',
      redirect: '/404',
      meta: { hidden: true },
    },
    templatesRouter,
    templateManageRouter,
    enterpriseRouter, // 企业管理
    subjectChannelRouter, // 频道页管理
    LeaseRouter, // 农房租赁
    privateSphereRouter,
  ],
});

router.beforeEach(async (to, _, next) => {
  NProgress.start();
  let token = localStorage.getItem('token');
  if (token || to.name === 'lottery') { // 有token直接进入当前页面
    next();
  } else { // 没有token
    if (to.path === '/login') { // 直接进入登录页
      next();
    } else {
      next({
        path: '/login',
      });
      NProgress.done();
    }
  }
});

router.afterEach((to) => {
  NProgress.done();
  document.title = to.meta.title;
});

export default router;

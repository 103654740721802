<template>
  <div class="com-myform">
    <el-form ref="elForm" :model="formData" :rules="valid" v-bind="option&&option.form" v-on="(option&&option.form&&option.form.on)||{}">
      <el-form-item :label="item.title" v-bind="item.formItem" :prop="item.required?item.field:''" v-for="(item,index) in rules" :key="index">
        <Render v-if="item.beforeRender" :render="item.beforeRender" :data="item"></Render>
          <component
            :ref='item.field' :is="item.is" v-model.trim="formData[item.field]" v-bind="item.attrs" v-on="item.on||{}"
            @keypress.native="keypress(item)" @keyup.native="keyup(item)">
            <template v-if="'children' in item">
              <component
                :is="child.is" v-bind="child" v-on="child.on||{}" v-for="(child,index) in item.children" :key="index"
                @keypress.native="keypress(child)" @keyup.native="keyup(child)"
              >
                <span v-if="child.innerText" class="innerText">{{child.innerText}}</span>
                <Render v-if="child.render" :render="child.render" :data="child"></Render>
              </component>
            </template>
            <span v-if="item.innerText" class="innerText">{{item.innerText}}</span>
            <template v-if="item.slotRender" v-slot="slotProps">
              <Render :render="item.slotRender" :data="slotProps"></Render>
            </template>
            <Render v-if="item.render" :render="item.render" :data="item"></Render>
          </component>
          
        <span v-if="item.afterText" class="afterText">{{item.afterText}}</span>
        <Render v-if="item.afterRender" :render="item.afterRender" :data="item"></Render>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Render from '../common/render.js'
import CutImgUp from '@/components/common/cutImgUp';
import Editor from '../editor'

export default {
  name: 'myForm',
  components: {Render, CutImgUp, Editor},
  props: {
    fApi: Object,
    rule: {
      type: Array,
      default () {
        return [];
      }
    },
    validate: {
      type: Object,
      default () {
        return {};
      }
    },
    option: Object
  },
  data () {
    const defData = {};
    const arr = this.rule.filter(item => !item.hidden);
    arr.forEach(item => {
      if (item.field) defData[item.field] = item.value;
    });

    return {
      defData,
      formData: {},
      valid: this.validate
    }
  },
  computed: {
    rules() {
      return this.rule.filter(item => !item.hidden);
    }
  },
  watch: {
    fApi: {
      handler (newVal) {this.formData = newVal},
      deep: true
    },
    /* formData: {
      handler (newVal) {this.$emit('input', newVal)},
      deep: true
    } */
  },
  created () {
    this.formData = JSON.parse(JSON.stringify(this.defData));
    this.$emit('init', this.formData);
  },
  methods: {
    keypress(item) {
      if (item.keypress) item.keypress();
    },
    keyup(item) {
      if (item.keyup) item.keyup();
    }
  }
}
</script>
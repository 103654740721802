var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "com-myform" },
    [
      _c(
        "el-form",
        _vm._g(
          _vm._b(
            { ref: "elForm", attrs: { model: _vm.formData, rules: _vm.valid } },
            "el-form",
            _vm.option && _vm.option.form,
            false
          ),
          (_vm.option && _vm.option.form && _vm.option.form.on) || {}
        ),
        _vm._l(_vm.rules, function (item, index) {
          return _c(
            "el-form-item",
            _vm._b(
              {
                key: index,
                attrs: {
                  label: item.title,
                  prop: item.required ? item.field : "",
                },
              },
              "el-form-item",
              item.formItem,
              false
            ),
            [
              item.beforeRender
                ? _c("Render", {
                    attrs: { render: item.beforeRender, data: item },
                  })
                : _vm._e(),
              _c(
                item.is,
                _vm._g(
                  _vm._b(
                    {
                      ref: item.field,
                      refInFor: true,
                      tag: "component",
                      nativeOn: {
                        keypress: function ($event) {
                          return _vm.keypress(item)
                        },
                        keyup: function ($event) {
                          return _vm.keyup(item)
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          item.slotRender
                            ? {
                                key: "default",
                                fn: function (slotProps) {
                                  return [
                                    _c("Render", {
                                      attrs: {
                                        render: item.slotRender,
                                        data: slotProps,
                                      },
                                    }),
                                  ]
                                },
                              }
                            : null,
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: _vm.formData[item.field],
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            item.field,
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formData[item.field]",
                      },
                    },
                    "component",
                    item.attrs,
                    false
                  ),
                  item.on || {}
                ),
                [
                  "children" in item
                    ? _vm._l(item.children, function (child, index) {
                        return _c(
                          child.is,
                          _vm._g(
                            _vm._b(
                              {
                                key: index,
                                tag: "component",
                                nativeOn: {
                                  keypress: function ($event) {
                                    return _vm.keypress(child)
                                  },
                                  keyup: function ($event) {
                                    return _vm.keyup(child)
                                  },
                                },
                              },
                              "component",
                              child,
                              false
                            ),
                            child.on || {}
                          ),
                          [
                            child.innerText
                              ? _c("span", { staticClass: "innerText" }, [
                                  _vm._v(_vm._s(child.innerText)),
                                ])
                              : _vm._e(),
                            child.render
                              ? _c("Render", {
                                  attrs: { render: child.render, data: child },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      })
                    : _vm._e(),
                  item.innerText
                    ? _c("span", { staticClass: "innerText" }, [
                        _vm._v(_vm._s(item.innerText)),
                      ])
                    : _vm._e(),
                  item.render
                    ? _c("Render", {
                        attrs: { render: item.render, data: item },
                      })
                    : _vm._e(),
                ],
                2
              ),
              item.afterText
                ? _c("span", { staticClass: "afterText" }, [
                    _vm._v(_vm._s(item.afterText)),
                  ])
                : _vm._e(),
              item.afterRender
                ? _c("Render", {
                    attrs: { render: item.afterRender, data: item },
                  })
                : _vm._e(),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
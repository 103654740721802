var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dialog-code" }, [
    _c("img", { staticClass: "img", attrs: { src: _vm.ds.data, alt: "" } }),
    _c(
      "a",
      {
        staticClass: "downLoad",
        attrs: { href: _vm.ds.data, download: "现场签到二维码" },
      },
      [_vm._v("下载")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: "修改密码", visible: _vm.dialogShow, size: "tiny" },
      on: {
        "update:visible": function ($event) {
          _vm.dialogShow = $event
        },
        close: _vm.close,
      },
    },
    [
      _c("vue-form", {
        ref: "ruleForm",
        attrs: {
          "ui-schema": _vm.uiSchema,
          schema: _vm.schema,
          "custom-rule": _vm.rules,
          "error-schema": _vm.errorSchema,
        },
        on: { "on-submit": _vm.handlerSubmit, "on-cancel": _vm.close },
        model: {
          value: _vm.ruleForm,
          callback: function ($$v) {
            _vm.ruleForm = $$v
          },
          expression: "ruleForm",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <el-dialog
    title="编辑信息"
    :visible.sync="dialogVisible"
    size="tiny"
    :before-close="handleClose"
  >
    <el-form
      label-width="80px"
      label-position="left"
    >
      <el-form-item label="用户头像:">
        <el-upload
          class="avatar-uploader"
          action="/apiadmini/api/Oss/upload_image"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
        >
          <img
            v-if="imageUrl"
            :src="imageUrl"
            class="avatar"
          >
          <i
            v-else
            class="el-icon-plus avatar-uploader-icon"
          />
        </el-upload>
        <el-button
          size="mini"
          type="primary"
          @click="changeInfo('head')"
        >
          确认
        </el-button>
      </el-form-item>

      <el-form-item label="用户名:">
        <p>{{ user_name }}</p>
      </el-form-item>
      <el-form-item label="真实姓名:">
        <p>{{ realname }}</p>
      </el-form-item>
      <el-form-item label="手机号:">
        <el-input
          v-if="editMobile"
          v-model="mobile"
          size="mini"
          style="width:240px;margin-right:10px;"
          maxlength="11"
        />
        <span
          v-if="!editMobile"
          style="margin-right:10px;"
        >{{ mobile }}</span>
        <el-button
          v-if="!editMobile"
          type="primary"
          size="mini"
          @click="changeMobile"
        >
          修改
        </el-button>
        <el-button
          v-if="editMobile"
          size="mini"
          @click="changeInfo('mobile')"
        >
          确认
        </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
// import LoadImg from '@/components/common/loadImg'
export default {
  name: 'EditInfo',
  // components: {
  //   loadImg
  // },
  data() {
    return {
      dialogVisible: true,
      user_name: '',
      realname: '', // 真实姓名
      mobile: '',
      psw: '',
      imageUrl: '',
      imgData: {
        fileList: [],
        imgCode: []
      },
      editMobile: false // 修改手机号
    }
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false
      this.$emit('closeDialog')
    },
    // 获取图片信息
    getFile(e) {
      this.imageUrl = e.fileList[0].url
    },
    // 删除图片
    clearFiles() {
      this.imgData = {
        fileList: [],
        imgCode: []
      }
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
      this.imgData.imgCode[0] = res.data.image_no
    },
    // 编辑手机号
    changeMobile() {
      this.editMobile = true
    },
    // 修改用户信息
    changeInfo(type) {
      let empty = ''
      let mobile = ''
      let head = ''
      if (type === 'head') {
        mobile = ''
        head = this.imgData.imgCode[0]
        if (head === '') {
          this.$message('头像不能为空')
          return null
        }
      } else {
        let head = ''
        mobile = this.mobile
        // 现在只支持13,15,18号段的手机号码
        if (mobile === '') {
          this.$message('请输入手机号')
          return null
        } else if (mobile.length === 0) {
          this.$message('请输入手机号')
          return null
        } else if (mobile.length !== 11) {
          this.$message('请输入正确的手机号')
          return null
        } else if (!/^1[3456789]\d{9}$/.test(mobile)) {
          this.$message('手机号码格式不正确')
          return null
        }
      }
      this.$axios({
        method: 'post',
        url: this.$api.admin.update_admin_info,
        data: {
          avatar_imageid: head,
          mobile: mobile
        }
      }).then((res) => {
        let r = res
        if (r.error === 0) {
          this.$emit('getInfo')
          this.$message('修改成功')
          this.editMobile = false
        } else {
          this.$message('修改失败')
        }
      })
    },
    // 获取用户信息
    getInfo() {
      this.$axios({
        method: 'get',
        url: this.$api.admin.get_admin_info
      }).then((res) => {
        let r = res
        if (r.error === 0) {
          this.imageUrl = r.data.avatar
          this.imgData.fileList.push({ name: '头像', url: r.data.avatar })
          this.imgData.imgCode.push(r.data.avatar_imageid)
          this.mobile = r.data.mobile
          this.user_name = r.data.user_name
          this.realname = r.data.realname
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar {
    width: 46px;
    height: 46px;
    display: block;
    border-radius: 23px;
}
</style>

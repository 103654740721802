import Vue from 'vue'
import Vuex from 'vuex'
import IAppState from './modules/app'
import IUserState from './modules/user'
import IEleState from './modules/element'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app: IAppState,
    user: IUserState,
    element: IEleState
  }
})

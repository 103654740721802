import {handleTime} from '../common/com';
import {CONTENT_TYPE} from '@/views/nb/goods/data.js';


function pageArgs(vm, page = 'currentPage', size = 'pageSize', table = 'tables') {
  return {
    [page]: vm[table].usePagAttrs['current-page'],
    [size]: vm[table].usePagAttrs['page-size']
  };
}


export const userVoucher = {
  getData() {
    const httpFn = this.$axios.post(this.$api.coupon.selectUserCouponInfo, {...this.forms.fApi, ...pageArgs(this)});
    const state = ['否', '是'];
    const type = ['', '平台券', '商家券'];

    requestFn.call(this, httpFn, {
      callback: () => {
        this.tables.list.forEach(item => {
          item.createTimes = handleTime(item.createTime);
          item.useTimes = handleTime(item.useTime);
          item.beginTimes = handleTime(item.beginTime);
          item.endTimes = handleTime(item.endTime);
          item.backflowStates = state[item.backflowState];
          item.isDeletes = state[item.isDelete];
          item.isUseds = state[item.isUsed];
          item.couponTypes = type[item.couponType];
        });
      }
    });
  }
};

export const rankingList = {
  getData() {
    const httpFn = this.$axios.post(this.$api.rank.tradePage, {...this.forms.fApi, ...pageArgs(this)});
    requestFn.call(this, httpFn, {
      callback: () => {
        this.tables.list.forEach(item => {
          item.delBtnType = 'danger';
        });
      }
    });
  }
};

export const commentList = {
  getData() {
    const httpFn = this.$axios.post(this.$api.tradeOffLineActive.commentList, pageArgs(this));
    requestFn.call(this, httpFn, {
      callback: () => {
        this.tables.list.forEach(item => {
          item.updateTimes = handleTime(item.updateTime);
          item.contentTitle = item.tradeContentDto.contentTitle;

          item.hideBtnType = item.delBtnType = 'danger';
          item.hideBtnLoad = item.showBtnLoad = false;

          item.hideBtnHide = item.isHidden == 1 ? true : false;
          item.showBtnHide = !item.hideBtnHide;
        });
      }
    });
  }
};

export const contentList = {
  getData() {
    // const types = ['--', '线路推荐', '景区导览', '美食品鉴', '农事体验', '非遗展示'];
    const types = CONTENT_TYPE.reduce((pre, curr) => {
      pre[curr.value] = curr.label;
      return pre;
    }, {});
    const send = {channelId: +this.$route.query.channelId, ...pageArgs(this)}
    const httpFn = this.$axios.post(this.$api.tradeOffLineActive.contentList, send);
    requestFn.call(this, httpFn, {
      callback: () => {
        this.tables.list.forEach(item => {
          if (item.beginTime && item.endTime) item.times = handleTime(item.beginTime) + '--' + handleTime(item.endTime);
          item.types = types[item.contentType];
          item.delBtnType = 'danger';
        });
      }
    });
  }
}


export const signUp = {
  getData() {
    const id = this.$route.query.id;
    if (!id) return;

    const send = {activeId: id, ...this.forms.fApi, ...pageArgs(this)}
    const httpFn = this.$axios.post(this.$api.tradeOffLineActive.userList, send);
    requestFn.call(this, httpFn, {
      callback: () => {
        this.tables.list.forEach(item => {
          item.subReservationHide = item.reserveStatus == 1 ? true : false;
        });
      }
    });
  }
}


export const signUpAdd = {
  async getData() {
    const id = this.$route.query.id;
    if (!id) return;

    const res = await this.$axios.get(this.$api.tradeOffLineActive.details, {params: {id}});
    for(let key in this.forms.fApi) {
      this.forms.fApi[key] = res.data[key];
    }
    this.forms.fApi.date = [res.data.startTime, res.data.endTime];
  }
}

export const offlineList = {
  getData() {
    const {channelId, channelName} = this.$route.query;
    const httpFn = this.$axios.post(this.$api.tradeOffLineActive.activeList, {channelId, ...this.forms.fApi, ...pageArgs(this)});
    requestFn.call(this, httpFn, {
      callback: () => {
        this.tables.list.forEach(item => {
          item.channelName = channelName;
          item.times = handleTime(item.startTime, 'YYYY-MM-DD') + ' 至 ' + handleTime(item.endTime, 'YYYY-MM-DD');
          item.delBtnType = 'danger';
        });
      }
    });
  }
}

async function requestFn(httpFn, config = {}) {
  const _default = {key: 'data', tableKey: 'tables'};
  const opt = this._.merge(_default, config);
  
  this[opt.tableKey].load = true;
  this[opt.tableKey].list = [];

  const res = await httpFn;
  this[opt.tableKey].load = false;

  if (res.code === 0) {
    this[opt.tableKey].list = res[opt.key].records;
    this[opt.tableKey].usePagAttrs.total = +res[opt.key].total;
    opt.callback && opt.callback(res[opt.key]);
  }
}


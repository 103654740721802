<template>
  <div
    v-if="page.item_total > 0"
    class="page"
  >
    <slot name="left" />
    <slot>
      <span>共 {{ page.item_total }} 条</span>
    </slot>
    <!-- <el-button-group> -->
    <el-button
      size="mini"
      icon="el-icon-arrow-left"
      :disabled="load"
      @click="prePage"
    >
      上一页
    </el-button>
    <b class="page-num">
      {{ page.page }}/{{ page.page_total }}
    </b>
    <el-button
      size="mini"
      :disabled="load"
      @click="nextPage"
    >
      下一页<i class="el-icon-arrow-right el-icon--right" />
    </el-button>
    <!-- </el-button-group> -->
    <el-input
      v-if="page.page_total>1"
      v-model="page.page"
      size="mini"
      style="width:100px;margin-left:10px;"
      :disabled="load"
      @blur="inputChange"
    >
      <template slot="append">
        页
      </template>
    </el-input>
    <el-select
      v-if="showSelect"
      v-model="page.page_count"
      size="mini"
      placeholder="请选择"
      style="width:100px;margin-left:10px"
      @change="getlist(1)"
    >
      <el-option
        v-for="item in pageArr"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      />
    </el-select>
    <el-button
      v-if="page.page_total>1"
      size="mini"
      type="primary"
      class="margin-l10"
      :disabled="load"
      @click="getlist(0)"
    >
      跳 转
    </el-button>
  </div>
</template>

<script>
import Vue from 'vue'
/*
  分页组件
  可以直接调用
1.传入pagination对象
2.设置按钮是否能点击的 disabled 布尔值 - load
3.设置调用父组件的getlist事件
*
* */

export default {
  props: {
    page: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: () => {
      }
    },
    load: {
      type: Boolean,
      default: false
    },
    showSelect: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pageArr: [{ value: '5', label: '5 条/页' }, { value: '10', label: '10 条/页' }, { value: '15', label: '15 条/页' }, { value: '20', label: '20 条/页' }, { value: '50', label: '50 条/页' }]
    }
  },
  mounted() {
    const vues = new Vue()
    vues.$on('searchList', (res) => {
      this.page.page = res
    })
  },
  methods: {
    inputChange() {
      if (this.page.page > this.page.page_total) {
        this.page.page = 1
      }
    },
    getlist(type) {
      if (type == 0) {
        this.$emit('getList', this.page.page)
      } else {
        this.$emit('getList', 1)
      }
    },
    nextPage() {
      if (this.page.page >= this.page.page_total) {
        this.$message('没有下一页')
        return
      }
      this.page.page++
      this.$emit('getList', this.page.page)
    },
    prePage() {
      if (this.page.page == 1) {
        this.$message('没有上一页')
        return
      }
      this.page.page--
      this.$emit('getList', this.page.page)
    }
  }
}
</script>

<style scoped>
  .page {
    line-height: 40px;
    text-align: center;
    margin: 20px 0;
  }

  .page span {
    float: left;
    margin-left: 20px;
  }
  .page-num{
    display: inline-block;
    padding: 0 10px;
    font-weight: 400;
  }
</style>

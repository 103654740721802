const base = APPLICATION_ENV.USER_API;
const userApis = {
  getOssKey: `${base}common/getOssConfig`,
  role: {
    list: `${base}backplat/role/list`, // 角色列表
    view: `${base}backplat/role/view`, // 角色查看
    add: `${base}backplat/role/add`, // 角色新增
    delete: `${base}backplat/role/delete`, // 角色删除
    edit: `${base}backplat/role/edit`, // 角色编辑
    disable: `${base}backplat/role/disable`, // 角色禁用
    enable: `${base}backplat/role/enable`, // 角色启用
  },
  operation: {
    login: `${base}backplat/login`, // 登陆
    logout: `${base}backplat/logout`, // 登陆
  },
  commonNew: {
    getAllRegion: `${base}common/getAllRegion`, // 获取所有城市
    getChildRegion: `${base}common/getChildRegion`, // 获取下级行政区域
    getChildSite: `${base}common/getChildSite`, // 获取下级行政区域
    sendCode: `${base}common/sendCode`, // 发送手机验证码
    getRegion: `${base}common/getRegion`, // 获取当前行政区域信息
    uploadFile: `${base}common/uploadFile`, // 上传文件
  },
  nav: {
    add: `${base}backplat/navigation/add`,
    edit: `${base}backplat/navigation/edit`,
    del: `${base}backplat/navigation/delete`,
    getlist: `${base}backplat/navigation/list`,
    getMenuGroupList: `${base}backplat/navigation/getMenuGroupList`,
    getMenuList: `${base}backplat/navigation/getMenuList`,
    sort: `${base}/backplat/navigation/sort`, // 编辑导航顺序
  },
  supplierNav: {
    add: `${base}backplat/supplier/navigation/add`,
    edit: `${base}backplat/supplier/navigation/edit`,
    del: `${base}backplat/supplier/navigation/delete`,
    getlist: `${base}backplat/supplier/navigation/list`,
    getMenuGroupList: `${base}backplat/supplier/navigation/getMenuGroupList`,
    getMenuList: `${base}backplat/supplier/navigation/getMenuList`,
    sort: `${base}/backplat/supplier/navigation/sort`, // 编辑导航顺序
  },
  back_supplier_Store: {
    selectCouponStore: `${base}back-supplier/Store/selectCouponStore`, // 优惠券店铺列表
  },
  userManage: {
    list: `${base}backplat/userManage/list`, // 用户列表
    detail: `${base}backplat/userManage/detail`, // 用户查看
    dealData: `${base}backplat/userManage/detail/dealData`, // 用户交易数据
    membership_card_log: `${base}backplat/userManage/detail/membership_card_log`, // 详情-会员卡消费明细
    freeze: `${base}backplat/userManage/freeze`, // 用户冻结
    unfreeze: `${base}backplat/userManage/unfreeze`, // 用户解冻
    exportInfo: `${base}backplat/userManage/exportInfo`, // 导出用户数据
  },
  servicerManage: {
    list: `${base}backplat/servicerManage/list`, // 服务商列表
    view: `${base}backplat/servicerManage/view`, // 服务商查看
    edit: `${base}backplat/servicerManage/edit`, // 服务商编辑
    add: `${base}backplat/servicerManage/add`, // 服务商新增
    accountView: `${base}backplat/servicerManage/account/view`, // 服务商查看账号
    accountEnable: `${base}backplat/servicerManage/account/enable`, // 服务商启用账号
    accountDisable: `${base}backplat/servicerManage/account/disable`, // 服务商禁用账号
    accountEdit: `${base}backplat/servicerManage/account/edit`, // 服务商编辑账号
    accountAdd: `${base}backplat/servicerManage/account/add`, // 服务商新增账号
  },
  // feedback: {
  //   reply: `${base}backplat/feedback/reply`, // 回复投诉建议
  //   list: `${base}backplat/feedback/list`, // 回复投诉列表
  //   download: `${base}backplat/feedback/download`, // 下载提现列表数据
  // },
  supplierInfoManage: {
    identifyIdCard: `${base}SupplierRegister/identifyIdCard`, // 身份证识别接口
    identifyBusinessLicense: `${base}SupplierRegister/identifyBusinessLicense`, // 营业执照识别
    list: `${base}backplat/supplierInfoManage/list`, // 商家列表
    view: `${base}backplat/supplierInfoManage/view`, // 商家查看
    add: `${base}backplat/supplierInfoManage/add`, // 商家新增
    delete: `${base}backplat/supplierInfoManage/delete`, // 商家删除
    edit: `${base}backplat/supplierInfoManage/edit`, // 商家编辑
    disable: `${base}backplat/supplierInfoManage/disable`, // 商家禁用
    enable: `${base}backplat/supplierInfoManage/enable`, // 商家启用
    remark: `${base}backplat/supplierInfoManage/remark`, // 备注
    remarkList: `${base}backplat/supplierInfoManage/remarkList`, // 备注
    makeUp: `${base}backplat/supplierInfoManage/makeUp`, // 补全商家资质
    storeInfo: `${base}backplat/supplierInfoManage/storeInfo`, // 店铺信息
    cooRelation: `${base}backplat/supplierInfoManage/cooRelation`, // 批量管理运营负责人
    downloadTemplate: `${base}backplat/supplierInfoManage/download/template`, // 下载导入模板
    exportInfo: `${base}backplat/supplierInfoManage/exportInfo`, // 导出商家数据
    importSupplier: `${base}backplat/supplierInfoManage/import/supplier`, // 导入商家数据
    passReset: `${base}backplat/supplierInfoManage/passReset`, // 重置密码
    limit: `${base}/backplat/supplierInfoManage/limit`, // 限制商家功能
    removeLimit: `${base}/backplat/supplierInfoManage/removeLimit`, // 取消限制
    offlineCode: `${base}/backplat/supplierInfoManage/store/offline/code`, // 下载线下支付二维码
    getQualificationList: `${base}common/getQualificationList`, // 获取商家资质类型列表
    detail: `${base}backplat/supplier-audit/detail`, // 查看商家详情
    edit2: `${base}backplat/supplier-audit/supplier/edit`, // 商家编辑 新增
    save: `${base}backplat/supplier-audit/supplier/save`, // 商家编辑 新增
    get_store_Page: `${base}backplat/supplierInfoManage/get_store_Page`, // 分页查询店铺页
    downloadSupplierInviteTemplate: `${base}backplat/supplierInfoManage/download/supplierInviteTemplate`, // 下载商家邀约模版
    importSupplierInviteInfo: `${base}backplat/supplierInfoManage/import/supplierInviteInfo`, // 商家邀约导入
    inviteSupplier: `${base}backplat/supplierInfoManage/invite/supplier`, // 商家邀约
  },
  financeManage: {
    list: `${base}backplat/financeManage/receive/list`, // 收款账户列表
    review: `${base}backplat/financeManage/receive/review`, // 收款账户审核
    view: `${base}backplat/financeManage/receive/view`, // 收款账户查看
    updBankJointCode: `${base}backplat/financeManage/receive/updBankJointCode`, // 修改行联号
  },
  home: { // 首页
    userData: `${base}/backplat/home/userData`, // 用户统计
    goodsRelated: `${base}/backplat/home/goodsRelated`, // 相关数据商品相关
    supplierRelated: `${base}/backplat/home/supplierRelated`, // 相关数据商家相关
    userRelated: `${base}/backplat/home/userRelated`, // 相关数据用户相关
  },
  common: { // 公用
    logisticList: `${base}/common/getLogisticList`, // 获取商家发货快递列表
  },
  // 标准化小程序
  applets: {
    guideList: `${base}backplat/guide_label/list`,
    guideAction: `${base}backplat/guide_label/action`,
    guideRemove: `${base}backplat/guide_label/remove`,
    guideShow: `${base}backplat/guide_label/show`,
    guideSort: `${base}backplat/guide_label/sort`,

    // 导览关联服务点
    guideReleveServeList: `${base}backplat/label/relation/list`,
    guideReleveAdd: `${base}backplat/label/relation/add`,
    guideReleveOperate: `${base}backplat/label/relation/operate`,
    guideReleveLineList: `${base}backplat/label/relation/route_list`,
    guideReleveGetStore: `${base}backplat/label/relation/store_info`,

    serveList: `${base}backplat/guide_route_detail/list`,
    serveAction: `${base}backplat/guide_route_detail/add`,
    serveRemove: `${base}backplat/guide_route_detail/remove`,
    serveShow: `${base}backplat/guide_route_detail/show`,
    serveSort: `${base}backplat/guide_route_detail/sort`,
    serveBeforeList: `${base}backplat/guide/list`, // 服务点列表
    serveAdd: `${base}backplat/guide/add`, // 添加服务点
    serveEdit: `${base}backplat/guide/edit`, // 编辑服务点
    serveOperate: `${base}backplat/guide/operate`, // 操作服务点

    lineList: `${base}backplat/guide_route/list`,
    lineAction: `${base}backplat/guide_route/action`,
    lineRemove: `${base}backplat/guide_route/remove`,
    lineShow: `${base}backplat/guide_route/show`,
    lineSort: `${base}backplat/guide_route/sort`,
    // 下载导入模板
    guideDownloadTemplate: `${base}backplat/guide/download/template`,
    guideImport: `${base}backplat/guide/import`, // 导入导览

  },
  refresh: {
    refreshCache: `${base}home/refresh_cache`,
  },
  supplierAudit: {
    list: `${base}backplat/supplier-audit/list`, // 商家审核列表
    detail: `${base}backplat/supplier-audit/detail`, // 商家审核详情
    changeDetail: `${base}backplat/supplier-audit/change-detail`, // 商家审核详情
    audit: `${base}backplat/supplier-audit/operation-audit`,
  },
  // 社区团购-审核
  team_audit: {
    auditTeamPage: `${base}back-supplier/team-audit/auditTeamPage`, // 团长申请列表
    auditTeamApply: `${base}back-supplier/team-audit/auditTeamApply`, // 团长申请审核
  },
  // 黑名单列表-风控同盾
  tongdun_risk_black: {
    list: `${base}backplat/risk/black/list`, // 列表
    remove: `${base}backplat/risk/remove`, // 移除黑名单
  },
  // 企业认证
  enterprise_certification: {
    audit: `${base}backplat/enterprise/certification/audit`, // 审核
    detail: `${base}backplat/enterprise/certification/detail/`, // 详情
    listByPage: `${base}backplat/enterprise/certification/listByPage`, // 分页查询
  },
  // 关联游记攻略、特色内容配置
  attractionReservation: {
    add: `${base}backplat/guide_relate_feature_note/add`, // 增加、编辑, 后端: 薛建强
    detail: `${base}backplat/guide_relate_feature_note/list`, // 详情, 后端: 薛建强
    delete: `${base}backplat/guide_relate_feature_note/delete_relation`, // 详情, 后端: 薛建强
  },
  mallaccount: {
    confirmSigning: `${base}mallaccount/trade-supplier-platform-settle/confirmSigning`,
    getSigningInfo: `${base}mallaccount/trade-supplier-platform-settle/getSigningInfo`,
    getSigningSupInfo: `${base}mallaccount/trade-supplier-platform-settle/getSigningSupInfo`,
    getSupName: `${base}mallaccount/trade-supplier-platform-settle/getSupName`,
    list: `${base}mallaccount/trade-supplier-platform-settle/list`,
    platformProcess: `${base}mallaccount/trade-supplier-platform-settle/platformProcess`,
    settleVerify: `${base}mallaccount/trade-supplier-platform-settle/settleVerify`,
    submitInvite: `${base}mallaccount/trade-supplier-platform-settle/submitInvite`,
  },

  // 商品详情-获取所有省份列表
  provinceListApi: `${base}common/province/list`,
  // 商品详情-下级区域
  regionShortNameApi: `${base}common/child/region/short/name`,
  // 商品简称列表
  regionPagejianchengApi: `${base}backplat/region/page`,
  // 编辑区域简称 
  regionPagejianchengEditApi: `${base}backplat/region/edit`,
};

export default userApis;

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "has-logo" },
    [
      _c("logo", { attrs: { collapse: _vm.isCollapse } }),
      _c(
        "el-scrollbar",
        { attrs: { "wrap-class": "scrollbar-wrapper" } },
        [
          _c(
            "el-menu",
            {
              attrs: {
                collapse: _vm.isCollapse,
                "background-color": "#fff",
                "text-color": "#222",
                "active-text-color": "#fff",
                "unique-opened": true,
                "collapse-transition": false,
                mode: "vertical",
              },
            },
            _vm._l(_vm.menulist, function (route) {
              return _c("sidebar-item", {
                key: route.path,
                attrs: { item: route, "is-collapse": _vm.isCollapse },
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { render, staticRenderFns } from "./SidebarItem.vue?vue&type=template&id=2d2bbdc2&scoped=true&"
import script from "./SidebarItem.vue?vue&type=script&lang=js&"
export * from "./SidebarItem.vue?vue&type=script&lang=js&"
import style0 from "./SidebarItem.vue?vue&type=style&index=0&id=2d2bbdc2&lang=scss&"
import style1 from "./SidebarItem.vue?vue&type=style&index=1&id=2d2bbdc2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.10.1_css-loader@6.8.1_lodash@4.17.21_vue-template-compiler@2.7.14_webpack@5.88.2/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d2bbdc2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/web_acadmin_online_docker/node_modules/.pnpm/vue-hot-reload-api@2.3.4/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2d2bbdc2')) {
      api.createRecord('2d2bbdc2', component.options)
    } else {
      api.reload('2d2bbdc2', component.options)
    }
    module.hot.accept("./SidebarItem.vue?vue&type=template&id=2d2bbdc2&scoped=true&", function () {
      api.rerender('2d2bbdc2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layout/components/Sidebar/SidebarItem.vue"
export default component.exports
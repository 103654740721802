// 监控http请求
import axios from 'axios';
import tracker from './config';

axios.interceptors.response.use((response) => response, (error) => {
  // 对请求错误做些什么
  const response = error.response || {};
  tracker.sendImmediate({
    type: 'httpError',
    content: {
      status: response.status,
      url: error.config.url,
      data: error.config.data,
      params: error.config.params,
      method: error.config.method,
      message: error.message,
    },
  });
});

<template>
  <div
    class="navbar"
    :style="{ background: subjectObj.subjectColor }"
  >
    <hamburger
      id="hamburger-container"
      :is-active="sidebar.opened"
      class="hamburger-container"
      @ToggleSideBar="toggleSideBar"
    />
    <breadcrumb
      id="breadcrumb-container"
      class="breadcrumb-container"
    />
    <div class="right-menu">
      <el-dropdown
        class="avatar-container right-menu-item hover-effect"
        trigger="click"
      >
        <div class="avatar-wrapper">
          <img
            :src="
              userInfo.avatar
                ? userInfo.avatar
                : 'https://wsnbh-img.hzanchu.com/acimg/176f2e304e062651a38fda4b384810fb.jpeg?x-oss-process=image/resize,l_300'
            "
            class="user-avatar"
          />
          <span>{{ userInfo.userName }}</span>
          <i class="el-icon-caret-bottom"></i>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>
            <span
              style="display: block"
              @click="editInfo"
            > 编辑资料 </span>
          </el-dropdown-item>
          <el-dropdown-item>
            <span
              style="display: block"
              @click="
                pswVisible = true;
                setShow = false;
              "
            >
              修改密码
            </span>
          </el-dropdown-item>
          <el-dropdown-item divided>
            <span
              style="display: block"
              @click="logout"
            > 退出登录 </span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- 编辑信息 -->
    <edit-info
      v-if="dialogShow"
      @closeDialog="closeDialog"
      @getInfo="getInfo"
    />
    <!-- 修改密码 -->
    <change-psw
      :psw-visible="pswVisible"
      @cancelPsw="cancelPsw"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { UserModule } from '@/store/modules/user';
import Breadcrumb from '@/components/Breadcrumb/index.vue';
import Hamburger from '@/components/Hamburger/index.vue';
import ResizeMixin from '../../mixin/resize';
import editInfo from '@/components/common/editInfo.vue';
import changePsw from '@/components/common/changePsw/index.vue';
import { getUserInfo } from '@/service/userService';
import { projectConfigDetail } from '@/utils/projectConfig';

export default {
  name: 'navbar',
  components: {
    Breadcrumb,
    Hamburger,
    editInfo,
    changePsw,
  },
  mixins: [ResizeMixin],
  data() {
    return {
      userInfo: {
        is_read: '',
        admin_type: 0,
      },
      dialogShow: false,
      pswVisible: false,
      subjectObj: {
        subjectColor: '#000A41',
      },
    };
  },
  computed: {
    appObj() {
      return {
        isMobile: this.device === 'Mobile',
      };
    },
    ...mapState({
      sidebar: (state) => state.app.sidebar,
      avatar: (state) => state.user.avatar,
    }),
  },
  methods: {
    ...mapActions({
      _ToggleSideBar: 'app/ToggleSideBar',
    }),
    toggleSideBar() {
      this._ToggleSideBar(false);
    },
    async logout() {
      this.$axios.get(this.$api.operation.logout).then((res) => {
        if (res.code === 0) {
          sessionStorage.removeItem('menuIndex');
          localStorage.removeItem('token');
          // this.$store.commit(types.LOGOUT);
          this.$router.push('/login');
        } else {
          this.$message('你已经退出了请重新登录');
          localStorage.removeItem('token');
          this.$router.push('/login');
        }
      });
    },
    async getUserInfo() {
      const res = await getUserInfo();
      if (res.code === 0) {
        this.userInfo = res.data;
        localStorage.setItem('userInfo', JSON.stringify(this.userInfo));
        localStorage.setItem('projectCode', this.userInfo.projectCode);
      }
    },
    closeDialog() {
      this.dialogShow = false;
    },
    cancelPsw() {
      this.pswVisible = false;
    },
    getInfo() {
      console.log(1);
    },
    editInfo() {
      this.$router.push('/user/account/info');
    },
    getPageInfo() {
      this.subjectObj = projectConfigDetail;
    },
  },

  created() {},
  mounted() {
    this.getPageInfo();
    this.getUserInfo();
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  // background: #000A41;
  .hamburger-container {
    height: 100%;
    float: left;
    padding: 0 15px 0 16px;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;
    display: flex;
    align-items: center;
    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;
    display: flex;
    align-items: center;
    &:focus {
      outline: none;
    }
    .drawer-item {
      margin-right: 16px;
    }
    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;
      display: flex;
      align-items: center;
      .avatar-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #fff;
        .user-avatar {
          width: 33px;
          height: 33px;
          border-radius: 100%;
        }
        span {
          font-size: 14px;
          padding: 0 10px;
        }
        .el-icon-caret-bottom {
          font-size: 12px;
        }
      }
    }
  }
}
</style>

import Layout from '@/layout/index.vue'

const templatesRouter = {
  path: '/application/appSettings/pageManage/editor/:id',
  component: () => import('@/views/application/appSettings/pageManage/editor.vue'),
  meta: {
    title: '页面编辑'
  }
}

export default templatesRouter

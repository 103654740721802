<template>
  <div class="editor-component">
    <LoadImgOss ref="LoadImgOss" v-show="false" :preview="false" event-trigger @onSuccess="onSuccessUploadImg"/>
    <quill-editor ref="editor" v-maxWindow v-model="content" :options="editorOption" @change="changefn" @focus="focus($event)"/>
  </div>
</template>

<script>
import LoadImgOss from '@/components/common/loadImgOss.vue'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'


const domList = [
  {
    dom: `<svg  t="1637824425355" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="10463"><path d="M243.2 780.8v-179.2H153.6v179.2c0 49.28 40.32 89.6 89.6 89.6h179.2v-89.6H243.2zM780.8 153.6h-179.2v89.6h179.2v179.2h89.6V243.2c0-49.28-40.32-89.6-89.6-89.6zM243.2 243.2h179.2V153.6H243.2c-49.28 0-89.6 40.32-89.6 89.6v179.2h89.6V243.2z m537.6 537.6h-179.2v89.6h179.2c49.28 0 89.6-40.32 89.6-89.6v-179.2h-89.6v179.2z" p-id="10464" fill="#000000"></path></svg>`,
    tit: "最大化",
    id: "maxId",
    display: "block",
  },
  {
    dom: `<svg t="1637824296192" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6336"><path d="M341.065143 910.189714v-146.285714c0-53.686857-43.885714-97.572571-97.572572-97.572571h-146.285714a48.786286 48.786286 0 0 0 0 97.499428h146.285714v146.285714a48.786286 48.786286 0 1 0 97.499429 0z m-292.571429-617.910857c0 26.916571 21.796571 48.786286 48.713143 48.786286h146.285714c53.686857 0 97.572571-43.885714 97.572572-97.572572v-146.285714a48.786286 48.786286 0 0 0-97.499429 0v146.285714h-146.285714a48.786286 48.786286 0 0 0-48.786286 48.786286z m910.409143 0a48.786286 48.786286 0 0 0-48.713143-48.786286h-146.285714v-146.285714a48.786286 48.786286 0 1 0-97.499429 0v146.285714c0 53.686857 43.885714 97.572571 97.499429 97.572572h146.285714a48.786286 48.786286 0 0 0 48.713143-48.786286z m0 422.765714a48.786286 48.786286 0 0 0-48.713143-48.713142h-146.285714c-53.686857 0-97.572571 43.885714-97.572571 97.572571v146.285714a48.786286 48.786286 0 1 0 97.499428 0v-146.285714h146.285714a48.786286 48.786286 0 0 0 48.786286-48.786286z" fill="#000000" p-id="6337"></path></svg>`,
    tit: "还原",
    id: "minId",
    display: "none",
  },
];

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // 加粗，斜体，下划线，删除线
  ['blockquote', 'code-block'], // 引用 代码块
  [{ header: 1 }, { header: 2 }], // 1,2级标题
  [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
  [{ script: 'sub' }, { script: 'super' }], // 上、下标
  [{ indent: '-1' }, { indent: '+1' }], // 缩进
  [{ 'direction': 'rtl' }], // 文本方向
  [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
  [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色】
  [{ font: [] }], // 字体种类
  [{ align: [] }], // 对其方式
  ['clean'], // 清楚文本格式
  ['link', 'image'] // ['link', 'image', 'video'] // 链接图片视频
]

export default {
  components: {LoadImgOss, quillEditor},
  props: {
    value: {type: String, default: ''},
    readonly: {type: Boolean, default: false}
  },
  data() {
    return {
      content: this.value,
      editorOption: {
        placeholder: '请输入文本',
        theme: 'snow',
        modules: {
          toolbar: {
            container: toolbarOptions,
            handlers: {
              'image': value => {
                if (value && !this.readonly) {
                  this.$refs.LoadImgOss.handleUpload();
                } else {
                  this.quill.format('image', false);
                }
              }
            }
          }
        }
      }
    }
  },
  watch: {
    value(newVal) {
      this.content = newVal;
    }
  },
  methods: {
    changefn() {
      this.$emit('input', this.content)
    },
    focus(evt) {
      if (this.readonly) evt.enable(false);
    },
    onSuccessUploadImg(data) {
      this.handleSuccess(data[0])
      return data;
    },
    // * 富文本图片获取上传成功后
    handleSuccess(res) {
      const quillEl = this.$refs.editor.quill
      if (res) {
        // 获取光标的位置
        const cursor = quillEl.selection.savedRange.index
        // 插进
        console.log('插入图片')
        quillEl.focus()
        quillEl.insertEmbed(cursor, 'image', res.url)
        // 调整光标位置
        quillEl.setSelection(cursor + 1)
        // this.$refs.editor.value = this.$refs.editor.value + `<img src='${res.url}' />`
        this.content = this.$refs.editor.value
        quillEl.update()
        this.$forceUpdate()
      } else {
        this.$message.error('上传失败，请重新上传')
      }
    },
    into(str) {
      const quillEl = this.$refs.editor.quill;
      const index = quillEl.selection.savedRange.index;
      quillEl.insertText(index, str);
      quillEl.setSelection(index + str.length);
    }
  },
  directives: {
    maxWindow: {
      //属性名称maxWindow，前面加v- 使用
      bind(el, binding, vnode, oldVnode) {
        setTimeout(() => {
          const editorEl = document.querySelector('.editor-component');
          const dialogHeaderEl = el.querySelector(".ql-toolbar");
          domList.map((item) => {
            const dom = document.createElement("span");
            dom.className = "ql-formats";
            dom.innerHTML = `<button title="${item.tit}" style="display:${item.display}" id="${item.id}"  type="button" class="ql-clean">${item.dom}</button>`;
            dialogHeaderEl.appendChild(dom);
          });

          //最大化
          document.getElementById("maxId").onclick = () => {
            el.style.width = 100 + "vw";
            el.style.height = 100 + "vh";
            el.style.position = "fixed";
            el.style.top = 0;
            el.style.left = 0;
            el.style.zIndex = 9999;
            el.style.background = "white";
            editorEl.querySelector('.ql-container').style.height = 'calc(100% - 46px)';
            document.getElementById("maxId").style.display = "none";
            document.getElementById("minId").style.display = "block";
          };
          //最小化
          document.getElementById("minId").onclick = () => {
            el.style.width = "auto";
            el.style.height = "auto";
            el.style.position = "inherit";
            editorEl.querySelector('.ql-container').style.height = '300px';
            document.getElementById("maxId").style.display = "block";
            document.getElementById("minId").style.display = "none";
          };
        }, 0);
      }
    }
  }
}
</script>

<style>
.editor-component .ql-container{height: 300px;}
</style>
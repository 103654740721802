export default {
  props: {
    render: {
      type: Function
    },
    data: [Number, String, Array, Object]
  },
  render(h) {
    return this.render(h, this.data);
  }
}
import Layout from '@/layout/index.vue'

const storeRouter = {
  path: '',
  component: Layout,
  meta: { title: '店铺管理' },
  children: [
    {
      path: '/user/store/list',
      component: () => import('@/views/nb/user/store/storeList.vue'),
      meta: {
        title: '店铺列表'
      }
    },
    {
      path: '/user/store/detail',
      component: () => import('@/views/nb/user/store/storeDetail.vue'),
      meta: {
        title: '店铺详情'
      }
    }

  ]
}

export default storeRouter

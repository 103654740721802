var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isshow
    ? _c(
        "div",
        { staticClass: "modal-box" },
        [
          _c(
            "el-dialog",
            _vm._b(
              {
                attrs: { visible: _vm.isshow },
                on: {
                  "update:visible": function ($event) {
                    _vm.isshow = $event
                  },
                },
              },
              "el-dialog",
              _vm.modal.attrs,
              false
            ),
            [
              _vm.modal.render
                ? _c("Render", {
                    attrs: {
                      render: _vm.modal.render,
                      data: _vm.modal.data || {},
                    },
                  })
                : _vm._e(),
              _vm.modal.type && _vm.isshow
                ? _c(
                    _vm.modal.type,
                    _vm._b(
                      { tag: "component" },
                      "component",
                      _vm.modal.config || {},
                      false
                    )
                  )
                : _vm._e(),
              _vm._t("content"),
              _vm.modal.showBtn
                ? _c(
                    "div",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _vm.modal.showCancelBtn
                        ? _c(
                            "el-button",
                            {
                              attrs: { size: "small" },
                              on: { click: _vm.closeModal },
                            },
                            [_vm._v(_vm._s(_vm.modal.cancelBtnText))]
                          )
                        : _vm._e(),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.submitModal },
                        },
                        [_vm._v(_vm._s(_vm.modal.submitBtnText))]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <el-dialog
    title="修改密码"
    :visible.sync="dialogShow"
    size="tiny"
    @close="close"
  >
    <vue-form
      ref="ruleForm"
      v-model="ruleForm"
      :ui-schema="uiSchema"
      :schema="schema"
      :custom-rule="rules"
      :error-schema="errorSchema"
      @on-submit="handlerSubmit"
      @on-cancel="close"
    />
  </el-dialog>
</template>

<script>
import {
  formData,
  fromSchema,
  uiSchema,
  customRule,
  errorSchema,
} from './schema';
import { changePass } from '@/service/userService';

export default {
  name: 'change-psw',
  props: {
    pswVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      old_password: '',
      new_password: '',
      re_psw: '',
      ruleForm: { ...formData },
      schema: { ...fromSchema },
      uiSchema: { ...uiSchema },
      rules: customRule,
      errorSchema: { ...errorSchema },
    };
  },
  computed: {
    dialogShow: {
      get() {
        return this.pswVisible;
      },
      set() {

      },
    },
  },
  methods: {
    async handlerSubmit(formData) {
      let { code } = await changePass({
        oldPassword: formData.old_password,
        newPassword: formData.new_password,
      });
      if (code === 0) {
        this.$message.success('修改成功');
        this.$emit('cancelPsw');
      }
    },
    close() {
      this.$emit('cancelPsw');
    },
  },
};
</script>

import { render, staticRenderFns } from "./index.vue?vue&type=template&id=42857496&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=42857496&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.1_css-loader@6.8.1_lodash@4.17.21_vue-template-compiler@2.7.14_webpack@5.88.2/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/web_acadmin_online_docker/node_modules/.pnpm/vue-hot-reload-api@2.3.4/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('42857496')) {
      api.createRecord('42857496', component.options)
    } else {
      api.reload('42857496', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=42857496&", function () {
      api.rerender('42857496', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/template/index.vue"
export default component.exports
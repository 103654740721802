import { getSidebarStatus, setSidebarStatus } from '@/utils/cookies'

const state = {
  sidebar: {
    opened: getSidebarStatus() !== 'closed',
    withoutAnimation: false
  },
  device: 'Desktop'
}

const mutations = {
  TOGGLE_SIDEBAR: (state, withoutAnimation) => {
    state.sidebar.opened = !state.sidebar.opened;
    state.sidebar.withoutAnimation = withoutAnimation
    if (state.sidebar.opened) {
      setSidebarStatus('opened')
    } else {
      setSidebarStatus('closed')
    }
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    state.sidebar.opened = false
    state.sidebar.withoutAnimation = withoutAnimation
    setSidebarStatus('closed')
  },
  TOGGLE_DEVICE: (state, device) => {
    state.device = device
  }
}

const actions = {
  ToggleSideBar({ commit }, withoutAnimation) {
    commit('TOGGLE_SIDEBAR', withoutAnimation);
  },
  CloseSideBar({ commit }, withoutAnimation) {
    commit('CLOSE_SIDEBAR', withoutAnimation);
  },
  ToggleDevice({ commit }, device) {
    commit('TOGGLE_DEVICE', device);
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

export const isValidUsername = (str) => ['admin', 'editor'].indexOf(str.trim()) >= 0

export const isExternal = (path) => /^(https?:|mailto:|tel:)/.test(path)

export const phoneCheck = (phone) => {
  if (!(/^1[3456789]\d{9}$/.test(phone))) {
    return false
  }
  return true
}

export function limitNumber(input, lower=0, upper=100) {
  const regNumber = /^[0-9]+$/
  return regNumber.test(input) && input >= lower && input <= upper
}

export function limitNumberValidate(message, lower=0, upper=100, required=true) {
  return (rule, value, cb) => {
    if (!required && value?.replace?.(/\s*/g,"") === '' || limitNumber(value, lower, upper)) {
      return cb()
    } else  {
      return cb(new Error(message))
    }
  }
}
const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
const isSiming = userInfo.userName === 'siminghl';

const formData = {
  old_password: '',
  new_password: '',
  re_psw: ''
}
const fromSchema = {
  type: 'object',
  required: [
    'old_password',
    'new_password',
    're_psw'
  ],
  properties: {
    old_password: {
      type: 'string',
      title: '原密码'
    },
    new_password: {
      type: 'string',
      title: '新密码',
      description: isSiming ? '请输入新密码' : '请输入6-12位的密码'
    },
    re_psw: {
      type: 'string',
      title: '新密码确认'
    }
  }
}
const uiSchema = {
  old_password: {
    'ui:options': {
      placeholder: '请输入原密码',
      type: 'password'
    }
  },
  new_password: {
    'ui:options': {
      placeholder: '请输入新密码',
      type: 'password',
      minLength: isSiming ? '' : 6,
      maxLength: isSiming ? '' : 12
    }
  },
  re_psw: {
    'ui:options': {
      placeholder: '再次输入新密码',
      type: 'password'
    }
  }
}
// 校验
const errorSchema = {
  old_password: {
    'err:options': {
      required: '请输入原密码'
    }
  },
  new_password: {
    'err:options': {
      required: '请输入新密码'
    }
  },
  re_psw: {
    'err:options': {
      required: '请再次输入新密码'
    }
  }
}
const customRule = ({
  field,
  value,
  rootFormData,
  callback
}) => {
  const rules = [
    {
      rule: 'old_password',
      validator(value) {
        if (!value) return '请输入原密码'
      }
    },
    {
      rule: 'new_password',
      validator(value) {
        if (isSiming) return isValidPassword(value);
        else {
          if (!value) return '请输入新密码';
          else if (value.length < 6) return '请输入6-12位的新密码';
        }
      }
    },
    {
      rule: 're_psw',
      validator(value, rootFormData) {
        if (!value) return '请再次输入新密码';
        else if (value !== rootFormData.new_password) return '两次输入的新密码不一致,请重新输入'
      }
    }
  ]

  for (const ruleItem of rules) {
    if ((String(ruleItem.rule) === ruleItem.rule && ruleItem.rule === field)) {
      const error = ruleItem.validator(value, rootFormData)
      if (error) return callback(error)
    }
  }
  return callback()
}

function isValidPassword(password) {console.log(password)
  const regex = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])[A-Za-z\d\W_]{8,}$/;
  if (!regex.test(password)) {
    return '密码必须包含数字、字母、特殊字符，且不少于8位';
  }

  const weakPatterns = [
    /123/,
    /12345678/,
    /123456789/,
    /123123/,
    /123123123/,
    /11111111/,
    /22222222/,
    /88888888/,
    /99999999/,
    /abc/,
    /aaa/
  ];

  for (let pattern of weakPatterns) {
    if (pattern.test(password)) {
      return '密码不能含有弱口令，如123、abc、三个以上的连续数字或字母';
    }
  }
}

export {
  formData,
  fromSchema,
  uiSchema,
  errorSchema,
  customRule
}

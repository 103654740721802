import Layout from '@/layout/index.vue'

const orderRouter = {
  path: '',
  component: Layout,
  meta: { title: '订单管理' },
  children: [
    {
      path: '/nb/order/list',
      component: () => import('@/views/nb/order/orderList.vue'),
      meta: {
        title: '订单查询'
      }
    },
    {
      path: '/nb/order/detail',
      component: () => import('@/views/nb/order/orderDetail.vue'),
      meta: {
        title: '订单详情'
      }
    },
    {
      path: '/nb/order/homestay/detail',
      component: () => import('@/views/nb/order/homestayDetail.vue'),
      meta: {
        title: '民宿订单详情'
      }
    },
    {
      path: '/nb/order/delivery/list',
      component: () => import('@/views/nb/order/delivery/list.vue'),
      meta: {
        title: '发货中心'
      }
    },
    {
      path: '/nb/order/refund/list',
      component: () => import('@/views/nb/order/refund/refundList.vue'),
      meta: {
        title: '商品售后'
      }
    },
    {
      path: '/nb/order/refund/detail',
      component: () => import('@/views/nb/order/refund/refundDetail.vue'),
      meta: {
        title: '售后信息'
      }
    },
    {
      path: '/nb/order/refund/track',
      component: () => import('@/views/nb/order/refund/trackDetail.vue'),
      meta: {
        title: '物流信息'
      }
    },
    {
      path: '/nb/order/audit',
      component: () => import('@/views/nb/order/audit.vue'),
      meta: {
        title: '订单审核',
      },
    }
  ]
}

export default orderRouter

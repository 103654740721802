const base = APPLICATION_ENV.TOOLS_API;

export default {
  userList: `${base}private-tools/backplat/userManage/list`,
  updateOpenGroupStatus: `${base}private-tools/backplat/userManage/updateOpenGroupStatus`,
  updateWithdrawStatus: `${base}private-tools/backplat/userManage/updateWithdrawStatus`,
  groupGoodsList: `${base}private-tools/backplat/purGroupGoods/group/page`,
  groupGoodsInfo: `${base}private-tools/backplat/purGroupGoods/group/detail`,
  groupGoodsDel: `${base}private-tools/backplat/purGroupGoods/group/del`,
  goodsPageList: `${base}private-tools/backplat/purGroupGoods/group/goodsPage`,
  goodsInfo: `${base}private-tools/backplat/purGroupGoods/group/goodsDetail`,
  goodsDel: `${base}private-tools/backplat/purGroupGoods/group/goodsDel`,
  orderList: `${base}private-tools/backplat/order/list`,
  orderInfo: `${base}private-tools/backplat/order/detail`
};

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pbulic-temp", class: _vm.$route.name + "-page" },
    _vm._l(_vm.filterComponents, function (item, index) {
      return _c(
        item.type,
        _vm._g(
          _vm._b(
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !item.hidden,
                  expression: "!item.hidden",
                },
              ],
              key: index,
              tag: "component",
            },
            "component",
            _vm.vm[item.attr],
            false
          ),
          item.on || {}
        )
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import tracker from './config'

// 是否是微信小程序环境，不用检测是否在微信APP内
export function isInWeChatMP() {
  return (navigator.userAgent.match(/miniprogram/i) || window.__wxjs_environment === 'miniprogram')
      && (navigator.userAgent.indexOf('AliApp') < 0)
}

//获取手机系统
export function getSystem(){
  let u = navigator.userAgent.toLocaleLowerCase();
  let isAndroid = u.indexOf('android') > -1 || u.indexOf('adr') > -1;
  let isiOs = !!u.match(/\(i[^;]+;( u;)? cpu.+mac os x/);
  if(isAndroid){
    return 'android'
  }
  if(isiOs){
    return 'ios'
  }
}

export function getBrowserInfo () {
  const agent = navigator.userAgent.toLowerCase();
  const regStr_ie = /msie [\d.]+;/gi;
  const regStr_ff = /firefox\/[\d.]+/gi
  const regStr_chrome = /chrome\/[\d.]+/gi;
  const regStr_saf = /safari\/[\d.]+/gi;
  //IE
  if (agent.indexOf("msie") > 0) {
      return agent.match(regStr_ie) && agent.match(regStr_ie)[0];
  }
  //firefox
  if (agent.indexOf("firefox") > 0) {
      return agent.match(regStr_ff) && agent.match(regStr_ff)[0];
  }
  //Chrome
  if (agent.indexOf("chrome") > 0) {
      return agent.match(regStr_chrome) && agent.match(regStr_chrome)[0];
  }
  //Safari
  if (agent.indexOf("safari") > 0 && agent.indexOf("chrome") < 0) {
      return agent.match(regStr_saf) && agent.match(regStr_saf)[0];
  }

  // weixin
  if (agent.indexOf('micromessenger')) {
    return 'weixin'
  }

  return '其他浏览器'
};

export function getPlatform() {
  let platform = 'PC'
  if (isInWeChatMP()) {
    platform = "MP-WINXIN"
  } else if (getSystem()){
    platform = getSystem()
  }
  return platform.toLocaleUpperCase()
}

export function getUserInfo () {
  let userInfo = {}
  const localStorageUserInfo = localStorage?.getItem?.('userInfo')
  if (localStorageUserInfo) {
    try {
      userInfo = JSON.parse(localStorageUserInfo)
    } catch {}
  }
  return userInfo
}

export function trackerSend(type, content) {
  tracker.sendImmediate({
    type,
    content,
  })
}

export function trackerSendLog(info) {
  tracker.sendImmediate({
    type: 'logInfo',
    content: {
      info
    },
  })
}
const base = APPLICATION_ENV.ORDER_API;
// const base = 'http://10.10.10.158:8086/'  //活动
// const base = 'http://10.10.10.200:8086/'  //直播
// const base = 'http://10.10.11.17:8086/'

const orderApis = {
  // 活动相关接口
  tradeProm: {
    activity_add: `${base}backplat/tradeProm/add/prom`, // 活动新增
    activity_list: `${base}backplat/tradeProm/list`, // 活动列表
    activity_detail: `${base}backplat/tradeProm/get/prom`, // 活动详情
    activity_update: `${base}backplat/tradeProm/update/prom`, // 活动编辑
    activity_delete: `${base}backplat/tradeProm/delete/prom`, // 活动上下架

    goods_list: `${base}backplat/tradeProm/promgoodsmergelist`, // 活动商品列表
    goods_size_list: `${base}backplat/tradeProm/promgoodslist`, // 多规格列表
    goods_check: `${base}backplat/tradeProm/audit`, // 活动商品审核

    group_list: `${base}backplat/tradePromGrouping/list`, // 分组列表
    prom_group_option: `${base}backplat/tradePromGrouping/list/prom`, // 活动下拉列表
    group_status_list: `${base}backplat/tradePromGrouping/get/promgrouping`, // 分组状态列表
    group_add: `${base}backplat/tradePromGrouping/add`, // 分组添加
    group_edit: `${base}backplat/tradePromGrouping/update/groupring`, // 分组编辑
    group_delete: `${base}backplat/tradePromGrouping/delete/groupring`, // 分组删除
    group_join: `${base}backplat/tradePromGrouping/batchadd/groupingGoods`, // 商品参加分组（批量）
    group_goods_delete: `${base}backplat/tradePromGrouping/delete/groupingGoods`, // 移出分组商品（单个）
    group_goods_list: `${base}backplat/tradePromGrouping/groupinggoodslist`, // 分组商品列表
    group_goods_sort: `${base}backplat/tradePromGrouping/update/sort`, // 改变分组商品排序
    group_goods_import: `${base}backplat/tradePromGrouping/import/supplier`, // 批量导入分组商品弃用
    group_goods_import_new: `${base}backplat/tradePromGrouping/import`, // 批量导入分组商品
    group_goods_download: `${base}backplat/tradePromGrouping/download/template`, // 下载导入模板
    get_live_secKill_goods: `${base}backplat/tradePromGrouping/get-live-secKill-goods`, // 直播添加秒杀商品列表

    get_edit_goods_skulist: `${base}backplat/tradeProm/promgoodsskulist`, // 编辑时获取多规格列表
    get_add_goods_skulist: `${base}backplat/tradeProm/goodsskulist`, // 参加时获取多规格列表
    add_goods_list: `${base}backplat/tradeProm/list/goods`, // 活动报名商品列表
    edit_goods_list: `${base}backplat/tradeProm/update/promgoodssku`, // 编辑报名商品列表
    reAdd_goods_list: `${base}backplat/tradeProm/readd`, // 重新报名商品列表
    sure_goods_list: `${base}backplat/tradeProm/add`, // 确认报名商品列表
    cancel_goods_list: `${base}backplat/tradeProm/cancel`, // 移出活动报名商品列表
    download_goods_list: `${base}backplat/tradeProm/download/sku`, // 下载活动报名商品列表
    // 民宿商品
    add_hotel: `${base}backplat/tradeProm/add/hotel`, // 民宿商品报名
    add_hotel_sku: `${base}backplat/tradeProm/get/hotel-sku`, // 民宿商品规格
    re_add_hotel: `${base}backplat/tradeProm/re-add/hotel`, // 民宿商品重新报名

    activity_select_list: `${base}backplat/tradeProm/getlist`, // 活动列表(list)

    qualification_add: `${base}backplat/tradePromRequirement/add/promrequirement`, // 活动资质添加
    qualification_edit: `${base}backplat/tradePromRequirement/update/requirement`, // 活动资质编辑
    get_goods_list: `${base}backplat/tradePromRequirement/get/requirement/goods`, // 商品资质获取
    get_store_list: `${base}backplat/tradePromRequirement/get/requirement/store`, // 店铺资质获取

  },
  tradePromRequirement: {

    qualification_category: `${base}backplat/tradePromRequirement/get/backendcategory`, // 商品类目

  },

  // 优惠券相关接口
  coupon: {
    template_add: `${base}backplat/CouponController/AddCoupon`, // 创建优惠券
    template_edit: `${base}backplat/CouponController/editorCoupon`, // 编辑优惠券
    template_detail: `${base}backplat/CouponController/coupon_info`, // 优惠券详情
    template_list: `${base}backplat/CouponController/SearchCoupon`, // 优惠券列表
    searchReturnCoupon: `${base}backplat/CouponController/searchReturnCoupon`, // 优惠券返券列表
    type_list: `${base}backplat/CouponController/SelectComboBoxCouponType`, // 优惠券类型下拉列表

    template_import_store: `${base}backplat/CouponController/importCouponStore`, // 导入优惠券店铺信息
    template_import_goods: `${base}backplat/CouponController/importCouponGoods`, // 导入优惠券商品信息
    template_export: `${base}backplat/CouponController/downloadDeliveryTemplate`, // 下载导入模板

    template_group_detail: `${base}backplat/CouponController/selectActivityByCouponAssociatedIds`, // 通过优惠券关联id获取活动分组详情
    template_store_detail: `${base}backplat/CouponController/selectSuppliersByCouponAssociatedIds`, // 通过优惠券关联id获取店铺详情
    template_goods_detail: `${base}backplat/CouponController/selectGoodsByCouponAssociatedIds`, // 通过优惠券关联id获取商品详情
    coupon_goods: `${base}backplat/CouponController/coupon_goods`, // 通过优惠券关联id获取商品详情
    template_site_detail: `${base}backplat/CouponController/selectSiteByCouponAssociatedIds`, // 通过优惠券关联id获取场馆详情
    template_prom_detail: `${base}backplat/CouponController/selectPromByCouponAssociatedIds`, // 通过优惠券关联id获取活动详情
    template_prom_list: `${base}backplat/CouponController/selectCouponProm`, // 优惠券获取活动列表

    package_add: `${base}backplat/CouponController/AddCouponPackage`, // 优惠券包新建
    package_search_coupon: `${base}backplat/CouponController/SearchBGCoupon`, // 优惠券包添加关联优惠券
    package_edit: `${base}backplat/CouponController/updataCouponPackage`, // 优惠券包编辑
    package_change: `${base}backplat/CouponController/changeCouponPackage`, // 优惠券包下架
    package_show: `${base}backplat/CouponController/changeCouponPackageShow`, // 优惠券包是否显示
    change_coupon_show: `${base}backplat/CouponController/changeCouponShow`, // 商家优惠券是否显示
    package_detail: `${base}backplat/CouponController/selectCouponPackageDetailById`, // 优惠券包详情
    package_list: `${base}backplat/CouponController/SearchCouponPackage`, // 优惠券包列表

    get_plat_coupon_data: `${base}backplat/CouponController/couponBGStatisticsBG`, // 平台优惠券总的统计
    get_all_coupon_data: `${base}backplat/CouponController/couponTotalStatisticsBG`, // 优惠券总的统计数据

    get_single_coupon_data: `${base}backplat/CouponController/couponStaticsBG`, // 优惠券统计数据,统计图
    get_total_coupon_data: `${base}backplat/CouponController/couponTotalStaticsBg`, // 优惠券统计数据

    grant_add: `${base}backplat/CouponController/distributedCouponPackage`, // 指定用户发放券包
    grant_list: `${base}backplat/CouponController/selectCouponPackageGrantUser`, // 发放记录

    download_template: `${base}backplat/CouponController/download/template`, // 下载优惠券导入商品模版
    import_coupongoods: `${base}backplat/CouponController/import/coupongoods`, // 优惠券商品导入
    importCouponStore: `${base}backplat/CouponController/importCouponStore`, // 优惠券商家导入
    storeTemplate: `${base}backplat/CouponController/download/storeTemplate`, // 下载优惠券商家导入模板
    deleteCouponUseScope: `${base}/backplat/CouponController/deleteCouponUseScope`, // 删除优惠券关联商品和店铺

    associateOrders: `${base}backplat/CouponController/couponOrderStatics`, // 优惠券关联订单
    downloadCouponOrderStatics: `${base}/backplat/CouponController/downloadCouponOrderStatics`, // 导出优惠券关联订单数据
    selectUserCouponInfo: `${base}/backplat/CouponController/selectUserCouponInfo`, // 获取用户优惠券数据

    appointCouponPackage: `${base}/backplat/CouponController/appointCouponPackage`, // 一键导入发放优惠券
    findAppointCouponPackage: `${base}/backplat/CouponController/findAppointCouponPackage`, // 导入发放优惠券列表
    addCouponCount: `${base}backplat/CouponController/addCouponCount`, // 优惠券模板增加优惠券的发放数量
    reduceCouponCount: `${base}backplat/CouponController/reduceCouponCount`, // 减少优惠券模板优惠券的发放数量
    selectCouponOptriLog: `${base}backplat/CouponController/selectCouponOptriLog`, // 获取优惠券模板优惠券发放数量操作记录
    downloadCode: `${base}redeemCode/download`, // 优惠券下载
    coupon_category: `${base}backplat/CouponController/coupon_category`, // 优惠券关联类目列表
    queryByCouponIds: `${base}backplat/CouponController/queryByCouponIds`, // 根据优惠券id list查询优惠券
    queryByCouponPackageIds: `${base}backplat/CouponController/queryByCouponPackageIds`, // 根据券包id list 批量查询优惠券
  },

  order: {
    orderList: `${base}backplat/order/list`,
    orderDetail: `${base}backplat/order/detail`,
    remark: `${base}backplat/order/remark`,
    export: `${base}backplat/order/detail/export`,
    deliveryEdit: `${base}/backplat/order/delivery/records/edit`,
    extendDelivery: `${base}backplat/order/extendDelivery`,
  },
  refund: {
    refundList: `${base}backplat/refund/list/refund`,
    refund_export: `${base}backplat/refund/list/refund_export`, // 售后列表导出
    refundDetail: `${base}backplat/refund/get/refund`,
    refundGoods: `${base}backplat/refund/get/refundgoods`,
    refundConsult: `${base}backplat/refund/get/refundconsult`,
    refundAudit: `${base}backplat/refund/audit`,
    refundTrack: `${base}backplat/order/query/track`,
    getExchangeOrder: `${base}backplat/refund/get/exchange-order`, // 换购订单列表
    closeRefund: `${base}backplat/refund/close-refund`, // 关闭申诉渠道

    addressList: `${base}backplat/refund-address/list`, // 退货地址
    refundAddressList: `${base}backplat/refund/refundAddress/list`, // 售后退货地址列表
    agreeDeliver: `${base}backplat/refund/agree-deliver`, // 商家同意退货
    refuseDeliver: `${base}backplat/refund/refuse-deliver`, // 商家拒绝退货
    refuseReason: `${base}backplat/refund/get/refuse-reason`, // 拒绝理由枚举
    refuseRefund: `${base}backplat/refund/refuse-refund`, // 商家拒绝退款
    agreeRefund: `${base}backplat/refund/agree-refund`, // 商家同意退款
    interveneAddEvidence: `${base}backplat/refund-intervene/add/evidence`, // 上传举证
    interveneDetailList: `${base}backplat/refund-intervene/detail-list`, // 介入举证列表详情
    refundreason: `${base}backplat/refund/get/refund-reason`, // 售后理由枚举
    edit: `${base}backplat/refund/edit`, // 平台修改售后
    replenishType: `${base}backplat/refund-intervene/replenishType`, // 补充举证类型
    replenish: `${base}backplat/refund-intervene/replenish`, // 补充举证
    openRefundShow: `${base}/backplat/refund/open-refund-show`, // 开启售后
  },
  delivery: {
    deliveryTemplate: `${base}back-supplier/order/download/template`,
    deliveryUpload: `${base}back-supplier/order/import/delivery`,
    deliveryList: `${base}back-supplier/order/delivery/records`,
    manualDelivery: `${base}back-supplier/order/manual/delivery`,
    editDelivery: `${base}back-supplier/order/delivery/records/edit`,
  },

  // 拼团
  groupBook: {
    book_list: `${base}backplat/group_goods/page`, // 拼团列表
    book_detail: `${base}backplat/group_goods/detail`, // 拼团详情
    book_del: `${base}backplat/group_goods/remove`, // 删除
    book_stop: `${base}backplat/group_goods/stop`, // 中止
  },

  settlement: {
    list: `${base}backplat/settlement/list/settlement`, // 结算单列表
    audit: `${base}backplat/settlement/audit/withdraw`, // 确认打款
    downloadSettlement: `${base}backplat/settlement/download/settlement`, // 下载结算数据
    downloadWithdraw: `${base}backplat/settlement/download/withdraw`, // 下载提现列表数据
    paymentList: `${base}backplat/settlement/list/paymentList`, // 导出打款单附件
    withdrawList: `${base}backplat/settlement/list/withdraw`, // 提现列表
    settleDetail: `${base}backplat/settlement/settle/detail`, // 农信结算查询
    withdrawannex: `${base}backplat/settlement/list/withdrawannex`, // 导出打款附件
    reject: `${base}backplat/settlement/reject`, // 驳回打款
    reject_reason: `${base}backplat/settlement/reject-reason`, // 驳回理由
    blacklist_list: `${base}/backplat/trade-withdraw-blacklist/list`, // 提现黑名单列表
    blacklist_add: `${base}/backplat/trade-withdraw-blacklist/add`, // 添加黑名单
    blacklist_delete: `${base}/backplat/trade-withdraw-blacklist/delete`, // 删除黑名单
    settlementUnion: `${base}backplat/trade-settlement-union/list`, // 工会结算单列表
    unionList: `${base}open/member/union/type/list`, // 工会列表
    relationList: `${base}open/member/union/type/relation/list`, // 获取工会下福利券列表
    confirm: `${base}backplat/trade-settlement-union/confirm`, // 确认结算
    unSettled: `${base}/backplat/trade-settlement-union/un-settled-amount`, // 结算总金额
    download: `${base}//backplat/trade-settlement-union/download`, // 导出工会结算表
  },

  liveShare: {
    share_msg: `${base}backplat/share/share`, // 获取分享信息
    shareStoreOnOperateBg: `${base}backplat/share/shareStoreOnOperateBg`, // 分享店铺接口
  },
  share: {
    share: `${base}backplat/share/share`, // 分享接口
  },
  DistributeController: {
    inviteNewRecord: `${base}backplat/DistributeController/inviteNewRecord`, // 邀请新人明细接口
    commissionRecord: `${base}backplat/DistributeController/commissionRecord`, // 佣金明细接口
    userWithdrawRecord: `${base}backplat/DistributeController/userWithdrawRecord`, // 用户提现明细记录
    getAllGoods: `${base}backplat/DistributeController/selectDistributeGoods`, // 获取可以参与分销的所有商品
    getGoodsSku: `${base}backplat/DistributeController/new_selectGoodsSkuForDistribute`, // 获取可以参与分销的商品sku列表
    addGoodsSku: `${base}backplat/DistributeController/new_addGoodsSkuForDistribute`, // 添加商品sku为分销商品
    getSelectGoods: `${base}backplat/DistributeController/new_selectDistributeGoods`, // 获取分销商品
    delSelectGoods: `${base}backplat/DistributeController/new_deleteDistributeGoods`, // 删除分销商品
    getSelectGoodsSku: `${base}backplat/DistributeController/new_selectDistributeGoodsSku`, // 获取已添加的分销商品sku
    updateGoodsSku: `${base}backplat/DistributeController/new_updateDistributeGoodsSku`, // 更新分销商品sku信息
    updateGoodsTime: `${base}backplat/DistributeController/new_updateDistributeGoodsTime`, // 更新分销商品有效时间

    getCommissionDetail: `${base}back-supplier/DistributeController/getCommissionDetail`, // 用户提现明细商品列表
    exportDistributeGoodsDetail: `${base}backplat/DistributeController/exportDistributeGoodsDetail`, // 用户提现明细商品列表

    logList: `${base}/backplat/trade-distribute-log/distribute_log`, // 分销日志列表
    logDetail: `${base}/backplat/trade-distribute-log/distribute_log_detail`, // 查看分销详情
  },
  InviteNewController: {
    updataInviteNewConfig: `${base}backplat/InviteNewController/updataInviteNewConfig`, // 更新邀请新人配置接口
    selectInviteNewConfig: `${base}backplat/InviteNewController/selectInviteNewConfig`, // 更新邀请新人配置接口
  },
  tradeActivity: {
    add: `${base}backplat/mallorder/trade-activity/add`, // 活动文案新增
    page: `${base}backplat/mallorder/trade-activity/page`, // 活动文案列表
    detail: `${base}backplat/mallorder/trade-activity/get`, // 活动文案详情
    edit: `${base}backplat/mallorder/trade-activity/edit`, // 活动文案编辑
    delete: `${base}backplat/mallorder/trade-activity/delete`, // 活动文案上下架
  },
  season: {
    season_detail: `${base}backplat/solarTerms/detail`,
    season_edit: `${base}backplat/solarTerms/edit`,
  },
  // 领券中心配置
  coupon_center: {
    add: `${base}backplat/center/add`, // 添加
    detail: `${base}backplat/center/detail`, // 详情
  },
  // 券/券包投放店铺
  coupon_store: {
    insert_batch: `${base}backplat/coupon_store/insert_batch`, // 批量添加
    coupon_store_list: `${base}backplat/coupon_store/coupon_store_list`, // 惠券/券包投放店铺列表
    remove: `${base}backplat/coupon_store/remove`, // 删除
  },
  // 用户-会员卡管理
  membership_card: {
    log_page: `${base}backplat/membership_card/log/page`, // 获取用户消费记录
    log_export: `${base}backplat/membership_card/log/export`, // 用户消费记录导出
    page: `${base}backplat/membership_card/page`, // 分页列表
    active: `${base}backplat/membership_card/active`, // 激活会员卡
    add: `${base}backplat/membership_card/add`, // 创建会员卡
    detail: `${base}backplat/membership_card/detail`, // 查看会员卡
    export: `${base}backplat/membership_card/export`, // 会员卡列表导出
  },
  // 企业服务-充值单
  enterprise_top_up: {
    page: `${base}backplat/top_up/page`, // 充值单列表
    create: `${base}backplat/top_up/create`, // 创建会员卡
    export: `${base}backplat/top_up/export`, // 用户消费记录导出
  },
  // 企业服务-企业钱包
  enterprise_wallets: {
    detail_export: `${base}backplat/business/wallets/detail/export`, // 明细导出
    detail_page: `${base}backplat/business/wallets/detail/page`, // 明细分页
    export: `${base}backplat/business/wallets/export`, // 企业钱包列表导出
    page: `${base}backplat/business/wallets/page`, // 分页列表
  },
  order_home: { // 首页
    todayDataUrl: `${base}backplat/home/todayData`, // 今日数据
    totalDataUrl: `${base}backplat/home/totalData`, // 总体数据
    orderDataUrl: `${base}backplat/home/orderData`, // 订单统计
    saleDataUrl: `${base}backplat/home/salesAmountData`, // 销售额统计
    topDataUrl: `${base}backplat/home/top`, // 运营后台首页昨日排名
    todayData: `${base}backplat/home/today/data`, // 首页顶部今日数据查询 兼容农博、四明臻货、标准化小程序
    orderData: `${base}backplat/home/order/data`, // 订单统计V2 兼容农博、四明臻货、标准化小程序
    totalData: `${base}backplat/home/total/data`, // 总数据查询 兼容农博、四明臻货
    salesAmountData: `${base}backplat/home/sales/amount/data`, // 销售额统计 兼容农博、四明臻货
  },
  // 票券核销列表
  userExchangeCard: {
    list: `${base}backplat/userExchangeCard/list`, // 票券列表
    export: `${base}backplat/userExchangeCard/list/export`, // 票券列表导出
  },
  // 2023农博会抽奖
  nb_lottery: {
    list: `${base}offline_draw/list`, // 获取抽奖结果集
    draw: `${base}offline_draw/draw`, // 抽奖
  },
  zlgf: {
    orderLogPage: `${base}backplat/union/order/order_log_page`, // 下单记录
    audit: `${base}backplat/union/order/audit`, // 审核
  },
};

export default orderApis;

import { update } from 'lodash';

const base = APPLICATION_ENV.COMMENT_API;
const commentApis = {
  farmer: {
    list: `${base}backplat/farmer/talk/list`, // 农民说列表
    detail: `${base}backplat/farmer/talk/detail`, // 农民说查看
    add: `${base}backplat/farmer/talk/add`, // 农民说新增
    delete: `${base}backplat/farmer/talk/delete`, // 农民说删除
    edit: `${base}backplat/farmer/talk/edit`, // 农民说编辑
    pubstatus: `${base}backplat/farmer/talk/update/pubstatus`, // 农民说上架和下架
    topSort: `${base}backplat/farmer/talk/top/sort`, // 农民说排序
    recommendList: `${base}backplat/farmer/talk/recommend/list`, // 农民说推荐
    recommendSort: `${base}backplat/farmer/talk/recommend/sort`, // 农民说推荐位排序
    commentList: `${base}/backplat/farmer/talk/comment/list`, // 农民说评论列表
    commentDel: `${base}/backplat/farmer/talk/comment/delete`, // 农民说评论删除
    commentHidden: `${base}/backplat/farmer/talk/comment/hidden`, // 农民说评论显示与隐藏
  },
  topic: {
    list: `${base}topic/list`, // 话题列表
    detail: `${base}topic/detail`, // 话题查看
    add: `${base}topic/add`, // 话题新增
    delete: `${base}topic/delete`, // 话题删除
    edit: `${base}topic/edit`, // 话题编辑
    sort: `${base}topic/top/sort`, // 话题排序
    topicstatus: `${base}topic/update/topicstatus`, // 话题发布与下架
  },

  // 直播相关接口
  live: {
    live_events_list: `${base}backplat/tradeLiveActivity/selectLiveActivity`, // 直播活动列表
    live_sort: `${base}backplat/tradeLiveActivity/live_sort`, // 设置直播排序值
    live_events_add: `${base}backplat/tradeLiveActivity/addLiveActivity`, // 直播活动新增
    live_events_detail: `${base}backplat/tradeLiveActivity/selecLiveActivityDetail`, // 直播活动详情
    live_events_edit: `${base}backplat/tradeLiveActivity/editorLiveActivity`, // 直播活动编辑
    live_events_delete: `${base}backplat/tradeLiveActivity/deleteLiveActivety`, // 直播活动删除
    live_events_share: `${base}backplat/tradeLiveActivity/selectLiveActivity`, // 直播活动分享
    anchor_list: `${base}backplat/mallcomment/trade-live-actor/selectLiveActorList`, // 获取主播列表

    pull_msg: `${base}backplat/tradeLiveActivity/getPullAddress`, // 获取拉流地址
    push_msg: `${base}backplat/tradeLiveActivity/getPushAddress`, // 获取推流地址
    live_control_msg: `${base}backplat/tradeLiveActivity/getLiveInfoOnLiveManager`, // 在直播控制台获取直播信息
    live_goods_list: `${base}backplat/tradeLiveActivity/selectLiveGoodsInfo`, // 在直播控制台获取直播商品信息
    live_sure_add: `${base}backplat/tradeLiveActivity/addLiveGoods`, // 在直播控制台确认添加商品
    batch_add_live_goods: `${base}backplat/tradeLiveActivity/batch_add_live_goods`, // 通过商品ID批量添加直播商品
    live_change_remark: `${base}backplat/tradeLiveActivity/liveGoodsRemark`, // 修改直播商品备注信息
    live_push_goods: `${base}backplat/tradeLiveActivity/pushGoods`, // 在直播控制台推送商品
    live_delete_goods: `${base}backplat/tradeLiveActivity/deleteLiveGoods`, // 在直播控制台删除商品
    live_top_goods: `${base}backplat/tradeLiveActivity/OnTop`, // 在直播控制台置顶商品
    live_control_config: `${base}backplat/tradeLiveActivity/selectLiveConfig`, // 在直播控制台获取直播的相关配置
    live_add_coupon: `${base}backplat/tradeLiveActivity/addLiveCouponList`, // 在直播控制台批量添加直播优惠券
    live_delete_coupon: `${base}backplat/tradeLiveActivity/deleteLiveCoupon`, // 在直播控制台删除直播优惠券
    live_issue_coupon: `${base}backplat/tradeLiveActivity/issueCoupon`, // 在直播控制台发放直播优惠券
    live_coupon_list: `${base}backplat/tradeLiveActivity/selecLiveCouponList`, // 在直播控制台获取直播优惠券 列表
    live_get_setting: `${base}backplat/tradeLiveActivity/selectLiveConfig`, // 获取直播的相关配置
    live_shut_up: `${base}backplat/tradeLiveActivity/changeIsShutup`, // 修改是否禁言的设置
    live_open_recording: `${base}backplat/tradeLiveActivity/updateOpenRecording`, // 改变是否开启录播状态
    live_is_recommend: `${base}backplat/tradeLiveActivity/updateIsRecommend`, // 修改是否推荐的状态
    live_add_vice: `${base}backplat/tradeLiveActivity/addViceAccount`, // 添加副账号
    live_delete_vice: `${base}backplat/tradeLiveActivity/deleteViceAccount`, // 删除副账号

    live_account_list: `${base}backplat/mallcomment/trade-live-actor/list`, // 分页查询账户信息
    live_account_add: `${base}backplat/mallcomment/trade-live-actor/add`, // 新增账户信息
    live_account_detail: `${base}backplat/mallcomment/trade-live-actor/detail`, // 详情
    live_account_edit: `${base}backplat/mallcomment/trade-live-actor/edit`, // 编辑
    live_account_delete: `${base}backplat/mallcomment/trade-live-actor/remove`, // 编辑
    live_account_forbid: `${base}backplat/mallcomment/trade-live-actor/changeStatus`, // 禁播
    live_account_check: `${base}backplat/mallcomment/trade-live-actor/verify`, // 校验账户

    live_report_detail: `${base}backplat/tradeLiveActivity/selectLiveReport`, // 举报详情信息
    live_report_list: `${base}backplat/tradeLiveActivity/selectLiveReportPage`, // 举报列表详情
    live_data_statistics: `${base}backplat/tradeLiveActivity/liveStateicDate`, // 直播数据统计
    get_activity_page: `${base}backplat/tradeLiveActivity/get_activity_page`, // 获取正在进行和未开始的直播信息列表

    live_list_by_id: `${base}backplat/tradeLiveActivity/live_list_by_id`, // 专题模板-直播列表组件-查询直播列表
    temp_live: `${base}tradeLiveActivity/temp_live`, // 专题模板-直播列表组件-专题模板直播列表(新版)
    liveGame_export: `${base}backplat/liveGame/export`, // 直播大赛-导出直播排名
    live_cunbo_export: `${base}backplat/live/cunbo/export`, // 村播直播大赛-导出直播排名
    tradeLiveActivity_batchEditorLiveWatch: `${base}backplat/tradeLiveActivity/batchEditorLiveWatch`, // 批量编辑直播初始化人次接口
    updateLiveGoodsInfo: `${base}backplat/tradeLiveActivity/updateLiveGoodsInfo`, // 修改排序
  },
  // 民宿通美丽乡村相关接口
  village: {
    village_remove: `${base}backplat/village/remove`, // 删除美丽乡村信息
    village_add_edit: `${base}backplat/village/addOrEdit`, // 新增、编辑美丽乡村
    village_comment_list: `${base}backplat/village/commentList`, // 美丽乡村评论列表
    village_comment_remove: `${base}backplat/village/remove`, // 删除美丽乡村评论
    village_list: `${base}backplat/village/selectList`, // 美丽列表
    village_detail: `${base}backplat/village/info`, // 美丽乡村详情
  },
  notice: {
    noticeList: `${base}backplat/notice/list`, // 公告列表
    addNotice: `${base}backplat/notice/add`, // 新增公告
    updateNotice: `${base}/backplat/notice/update`, // 更新公告
    deleteNotice: `${base}/backplat/notice/delete`, // 更新公告
    notice_send_to_wechat: `${base}/backplat/notice/send_to_wechat`, // 发送微信订阅通知

  },
  message: {
    messageList: `${base}/backplat/message_board/list`, // 留言列表
    messageAudit: `${base}/backplat/message_board/audit`, // 留言审核
    messageRemove: `${base}/backplat/message_board/remove`, // 留言删除
  },
  userMessage: {
    geTuiMessPage: `${base}/backplat/trade-user-message/geTuiMessPage`, // 获取推送消息列表
    detail: `${base}/backplat/trade-user-message/detail`, // 获取推送消息详情
    editMess: `${base}/backplat/trade-user-message/edit`, // 编辑推送消息
    addMess: `${base}/backplat/trade-user-message/pushMess`, // 新增推送消息
  },

  // 2021-12-10 代翔添加 tradeOffLineActive
  tradeOffLineActive: {
    activeList: `${base}/backplat/tradeOffLineActive/offLiveActivePage`, // 线下活动列表
    getACode: `${base}/backplat/tradeOffLineActive/getACode`, // 签到二维码
    details: `${base}/backplat/tradeOffLineActive/detailOffLineActive`, // 线下活动详情
    editor: `${base}/backplat/tradeOffLineActive/editorActive`, // 编辑线下活动
    add: `${base}/backplat/tradeOffLineActive/addOffLineActive`, // 添加线下活动
    delete: `${base}/backplat/tradeOffLineActive/deleteActive`, // 删除线下活动
    userList: `${base}/backplat/tradeOffLineActive/activeUserPage`, // 线下活动用户列表
    reserveStatus: `${base}/backplat/tradeOffLineActive/operate_reserve_status`, // 操作线下活动用户预约状态

    contentList: `${base}backplat/trade/content/list`, // 内容列表查询
    contentDel: `${base}/backplat/trade/content/delete`, // 内容删除
    contentDetail: `${base}/backplat/trade/content/detail`, // 详情查询
    contentAdd: `${base}/backplat/trade/content/add`, // 内容新增
    contentEdit: `${base}/backplat/trade/content/edit`, // 内容编辑

    hiddenOrNo: `${base}/backplat/trade/content/comment/hiddenOrNo`, // 评论隐藏与显示
    commentDel: `${base}/backplat/trade/content/comment/delete`, // 评论删除
    commentList: `${base}/backplat/trade/content/comment/list`, // 评论列表查询
  },

  // 2022-07-12 代翔添加 videos
  videos: {
    list: `${base}backplat/video/list`, // 视频列表
    update: `${base}backplat/video/update`, // 上下架
    add: `${base}backplat/video/add`, // 新增视频
    edit: `${base}backplat/video/edit`, // 编辑视频
    detail: `${base}backplat/video/detail/`, // 视频详情
    delete: `${base}backplat/video/delete/`, // 删除视频
  }, // 农博大学
  supplierTradeUniversity: {
    list: `${base}/backplat/trade-university/list`, // 文章列表
    add: `${base}/backplat/trade-university/add`, // 创建文章
    delete: `${base}/backplat/trade-university/delete`, // 删除文章
    detail: `${base}/backplat/trade-university/detail`, // 文章详情
    edit: `${base}/backplat/trade-university/edit`, // 编辑文章,取消,排序,首页展示都用此接口,id必传
  },
  msg: {
    add: `${base}backplat/notify-message-config/add`, // 运营主动推送消息 新建
    edit: `${base}backplat/notify-message-config/edit`, // 运营主动推送消息 编辑
    detail: `${base}backplat/notify-message-config/detail`, // 运营主动推送消息 查看
    delete: `${base}backplat/notify-message-config/delete`, // 运营主动推送消息 删除
    importSupplier: `${base}backplat/notify-message-config/importSupplier`, // 自定义商家导入
    importUser: `${base}backplat/notify-message-config/importUser`, // 自定义用户导入
    page: `${base}backplat/notify-message-config/page`, // 主动推送消息列表
    push: `${base}backplat/notify-message-config/push`, // 运营主动推送消息
    export: `${base}backplat/notify-message-config/export`, // 导出模板
  },
  // 四明红旅-定制行程管理
  tripManage: {
    list: `${base}backplat/tradeCustomizedTravel/list`, // 列表查询
    create: `${base}backplat/tradeCustomizedTravel/create`, // 批量添加
    delete: `${base}backplat/tradeCustomizedTravel/delete`, // 删除
    edit: `${base}backplat/tradeCustomizedTravel/edit`, // 编辑
  },
  lease: {
    list: `${base}backplat/room/page`, // 房屋列表查询
    del: `${base}backplat/room/delete`, // 房屋删除
    info: `${base}backplat/room/detail`, // 房屋详情
    add: `${base}backplat/room/add`, // 房屋新增
    edit: `${base}backplat/room/edit`, // 房屋编辑
    intentList: `${base}backplat/intent/page`, // 意向列表
    intentInfo: `${base}backplat/intent/detail`,
    intentDel: `${base}backplat/intent/delete`,
  },
};

export default commentApis;

<template>
  <section class="app-main">
    <keep-alive :max="10">
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </section>
</template>

<script>
export default {
  name: 'app-main',
};
</script>

<style lang="scss" scoped>
.app-main {
  overflow: hidden;
  height: calc(100% - 50px);
  overflow-y: auto;

}
</style>

<template>
  <div
    id="search_panel"
    :class="{'search-panel': true, 'disflex': flex, 'fold-box': thFold === 1}"
    @keyup.enter="entryGetList"
  >
    <i
      v-if="thFold"
      :class="{'icon': true, 'el-icon-arrow-down': true, 'transform': thFold === 2}"
      @click="changeFold"
    />
    <slot />
    <div class="button-area">
      <el-button
        v-if="showBtn"
        type="primary"
        icon="search"
        name="search"
        size="mini"
        @click="getList"
      >
        {{ bText }}
      </el-button>
      <slot name="button-right" />
    </div>
  </div>
</template>

<script>
/**
 * 搜索面板模块
 * @module SearchPanel - 搜索面板
 * @property {string} bText - 自定义搜索按钮名称
 * @property {boolean} showBtn - 是否展示搜索按钮
 * @property {flex} flex - 是否开启flex布局
 * @property {fold} fold - 行高
 * @property {string} height = 搜索面板高度
 */
import Vue from 'vue'
export default {
  name: 'SearchPanel',
  props: {
    bText: {
      type: String,
      default: '搜索'
    },
    showBtn: {
      type: Boolean,
      default: false
    },
    flex: {
      type: Boolean,
      default: false
    },
    fold: {
      type: Number,
      default: 0
    },
    height: {
      type: String,
      default: ''
    },
    isEntry: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      thFold: this.fold
    }
  },
  methods: {
    entryGetList() {
      if (this.isEntry) {
        this.getList()
      } else {
        return false
      }
    },
    getList() {
      const vues = new Vue()
      vues.$emit('searchList', 1)
      this.$emit('getList', 1)
    },
    changeFold() {
      let o = document.getElementById('search_panel')
      if (this.thFold === 1) {
        o.style.height = this.height
        this.thFold = 2
      } else {
        o.style.height = '70px'
        this.thFold = 1
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .search-panel {
    position: relative;
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    transition: height 0.3s;
  }
  .fold-box {
    height: 70px;
  }
  .icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
    display: block;
    transition: 0.3s all;
  }
  .transform {
    transform: rotate(180deg);
  }
  .disflex {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    &>div {
      margin:0 20px 10px 0;
      border-radius: 4px;
      p {
        color:#3c3c3c;
        font-weight:bold;
        padding:0 0 10px 0px;
        width:130px;
        border-radius:4px;
        font-size:12px;
        margin: 12px 0 0 0;
      }
    }
    .search-panel-margin-auto {
      margin-right: auto;
    }
  }
  .button-area{
    float:left;
    margin-left: 20px;
    display: flex;
    align-items: center;
  }
</style>

import '@/monitor/index';
import Vue from 'vue';
import ElementUI from 'element-ui';
import App from '@/App.vue';
import store from '@/store';
import router from '@/router/index.js';
import vSelect from '@/directive/vSelect';
import '@/utils/vueQuillEditor';
// import '@/assets/js/aliyun-oss-sdk-5.3.1.min.js'
// import '@/assets/js/aliyun-upload-sdk-1.5.0.min.js'
import selfPlugin from './plugins';
import { NbForm, NbTable, NbShowImg } from 'nb-modules';
import { trackerSend, getUserInfo } from '@/monitor/utils';
import loadImgOssUp from '@/components/common/loadImgOssUp';
// require('es6-promise').polyfill()
// 引入样式
import 'normalize.css';
import 'nb-modules/dist/index.css';
import '@/styles/index.scss';

Vue.component('load-img-oss-up', loadImgOssUp);

// 插件
Vue.use(selfPlugin);
Vue.use(vSelect);
Vue.use(ElementUI);
Vue.use(NbForm);
Vue.use(NbTable);
Vue.use(NbShowImg);

// 组件
const VueForm = () => import('@lljj/vue-json-schema-form');
Vue.component('vue-form', VueForm);
const componentFiles = require.context('./components/global', true, /\.vue$/);
componentFiles.keys().forEach((e) => {
  const moduleName = e.replace(/^\.\/(.*)\.\w+$/, '$1');
  Vue.component(moduleName, componentFiles(e).default);
});

Vue.config.errorHandler = function (e, vm, info) {
  // vue 上报错误
  const userInfo = getUserInfo();
  console.error(e)
  trackerSend('jsError', {
    message: e?.message,
    stack: e?.stack,
    browserInfo: navigator.userAgent,
    component: vm?.$vnode?.componentOptions?.tag,
    vueLifecycle: info,
    userId: userInfo?.userId,
    mobile: userInfo?.mobile,
    url: location.href,
  });
};

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

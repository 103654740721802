var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "upload-box" },
    [
      _c(
        "div",
        { staticClass: "avatar-uploader-box" },
        [
          _vm._l(_vm.imageUrlList, function (item, i) {
            return _c(
              "div",
              { key: i, staticClass: "avatar-box" },
              [
                _c("el-image", {
                  staticStyle: { width: "100px" },
                  attrs: { src: item, "preview-src-list": _vm.imageUrlList },
                }),
                !_vm.disabled
                  ? _c(
                      "div",
                      {
                        staticClass: "delete-icon",
                        on: {
                          click: function ($event) {
                            return _vm.delImageUrl(i)
                          },
                        },
                      },
                      [_c("i", { staticClass: "el-icon-circle-close" })]
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
          _vm.type === "avatar" &&
          _vm.imageUrlList.length < _vm.maxNum &&
          !_vm.disabled
            ? _c(
                "el-upload",
                {
                  ref: "avatarUploader",
                  staticClass: "avatar-uploader",
                  attrs: {
                    action: _vm.$api.nb_common.newUpload,
                    "show-file-list": false,
                    "on-change": _vm.handleAvatarChange,
                    "auto-upload": false,
                    accept: "image/*",
                  },
                },
                [
                  _vm.imageUrlList.length < _vm.maxNum
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loading,
                              expression: "loading",
                            },
                          ],
                          staticStyle: { "line-height": "30px" },
                          attrs: {
                            "element-loading-text": "上传中",
                            "element-loading-spinner": "el-icon-loading",
                            "element-loading-background": "rgba(0, 0, 0, 0.8)",
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                          }),
                        ]
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
        ],
        2
      ),
      _c("vue-cropper-item", {
        ref: "VueCropperItem",
        on: { confirm: _vm.confirmCropper },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
// 企业服务
import Layout from '@/layout/index.vue';

const enterpriseRouter = {
  path: '',
  component: Layout,
  meta: { title: '企业服务' },
  children: [
    {
      path: '/enterprise/audit',
      component: () => import('@/views/enterprise/audit/index.vue'),
      meta: {
        title: '企业审核',
      },
    },
    {
      path: '/enterprise/refillOrder',
      component: () => import('@/views/enterprise/refillOrder/index.vue'),
      meta: {
        title: '企业充值单',
      },
    },
    {
      path: '/enterprise/wallet',
      component: () => import('@/views/enterprise/wallet/index.vue'),
      meta: {
        title: '企业钱包',
      },
    },
    {
      path: '/enterprise/memberCard',
      component: () => import('@/views/enterprise/memberCard/indev.vue'),
      meta: {
        title: '会员卡管理',
      },
    },
  ],
};

export default enterpriseRouter;

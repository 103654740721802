<template>
  <div class="dialog-code">
    <img class="img" :src="ds.data" alt="">
    <a class="downLoad" :href="ds.data" download="现场签到二维码">下载</a>
  </div>
</template>

<script>
export default {
  props: ['ds']
}
</script>

<style lang="scss" scoped>
.dialog-code{
  text-align: center;
  .img{display: block; margin: 0 auto 20px;}
  .downLoad{color: #1890ff;}
}
</style>
<template>
  <div :class="[{ 'is-active': isActive }]" @click="toggleClick">
    <svg
      width="20"
      class="amburger-svg"
      height="20"
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M408 442h480a8 8 0 0 0 8-8v-56a8 8 0 0 0-8-8H408a8 8 0 0 0-8 8v56a8 8 0 0 0 8 8zm-8 204a8 8 0 0 0 8 8h480a8 8 0 0 0 8-8v-56a8 8 0 0 0-8-8H408a8 8 0 0 0-8 8v56zm504-486H120a8 8 0 0 0-8 8v56a8 8 0 0 0 8 8h784a8 8 0 0 0 8-8v-56a8 8 0 0 0-8-8zm0 632H120a8 8 0 0 0-8 8v56a8 8 0 0 0 8 8h784a8 8 0 0 0 8-8v-56a8 8 0 0 0-8-8zM142.4 642.1L298.7 519a8.8 8.8 0 0 0 0-13.9L142.4 381.9a8.9 8.9 0 0 0-14.4 6.9v246.3a8.9 8.9 0 0 0 14.4 7z"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "Hamburger",
  props: {
    isActive: {
      default: false,
    },
  },
  methods: {
    toggleClick() {
      this.$emit("ToggleSideBar");
    },
  },
};
</script>

<style lang="scss" scoped>
.amburger-svg {
  vertical-align: middle;
  color: #fff;
  fill: currentColor;
  stroke: none;
  display: inline-block;
}

.is-active {
  transform: rotate(180deg);
}
</style>

/*
  属性列表
*/
const STOP_STATUS = [{
  value: '1',
  label: '启用',
},
{
  value: '2',
  label: '禁用',
}];
/*
  属性类型
*/
const ATTR_TYPE = [{
  value: '1',
  label: '基础属性',
},
{
  value: '2',
  label: '描述属性',
},
{
  value: '3',
  label: '规格属性',
}];
/*
  表单维护方式
*/

const CAT_RANK = [{
  value: '1',
  label: '一级类目',
}, {
  value: '2',
  label: '二级类目',
}, {
  value: '3',
  label: '三级类目',
}];

// 类目
const CAT_STATUS = [{
  value: '1',
  label: '上架',
}, {
  value: '2',
  label: '下架',
}];
// 商品审核状态
const GOODS_AUDIT_STATUS = [
  {
    value: '1',
    label: '未提审',
  },
  {
    value: '2',
    label: '审核中',
  },
  {
    value: '3',
    label: '审核成功',
  },
  {
    value: '4',
    label: '审核失败',
  },
  {
    value: '5',
    label: '已经下架',
  },
];

const TAG_TYPE = [
  { value: 1, label: '用户标签' },
  { value: 2, label: '商家标签' },
  { value: 3, label: '运营标签' },
  { value: 4, label: '内容标签' },
];

const TAG_USE_1_LIST = [
  { value: 1, label: '用户偏好' },
  { value: 2, label: '用户评价' },
];

const TAG_USE_2_LIST = [
  { value: 3, label: '商品标签' },
  { value: 4, label: '服务承诺' },
];

const TAG_USE_3_LIST = [
  { value: 5, label: '商品审核' },
  { value: 6, label: '商家管理' },
  { value: 7, label: '投诉建议' },
  { value: 9, label: '线路标签' },
];

const TAG_USE_4_LIST = [
  { value: 8, label: '桐游乡村' },
  { value: 10, label: '逛农博' },
];

const TAG_USE = [
  ...TAG_USE_1_LIST,
  ...TAG_USE_2_LIST,
  ...TAG_USE_3_LIST,
  ...TAG_USE_4_LIST,
];
const GOODS_TYPE = [
  { value: 1, label: '农副产品' },
  { value: 2, label: '精品民宿' },
  { value: 3, label: '景点门票' },
  { value: 4, label: '旅游路线' },
  { value: 5, label: '享美食' },
  { value: 6, label: '玩乐打野' },
  { value: 7, label: '认养类目' },
];
const CONTENT_TYPE = [
  { value: 1, label: '线路推荐' },
  { value: 2, label: '景区导览' },
  { value: 3, label: '美食品鉴' },
  { value: 4, label: '农事体验' },
  { value: 5, label: '非遗展示' },
  { value: 6, label: '行程主题' },
  { value: 7, label: '景点等级' },
];
/**
 * 商品类型
 */
const GOODS_BUY_TYPE_MAP = {
  0: '普通卖品',
  1: '组合卖品',
  2: '普通非卖品',
  3: '组合非卖品',
};

export {
  STOP_STATUS,
  ATTR_TYPE,
  CAT_RANK,
  CAT_STATUS,
  GOODS_AUDIT_STATUS,
  TAG_TYPE,
  TAG_USE,
  GOODS_TYPE,
  CONTENT_TYPE,
  TAG_USE_1_LIST,
  TAG_USE_2_LIST,
  TAG_USE_3_LIST,
  TAG_USE_4_LIST,
  GOODS_BUY_TYPE_MAP,
};

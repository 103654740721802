
import { createDomID } from '@/utils/utils'
import { cloneDeep } from 'lodash'

const state = {
  moduleList: [],
  targetModule: {},
  activeModule: {},
  dropEnable: false,
  pageInfo: {}
}

const mutations = {
  ADD_MODULE: (state, index) => {
    state.moduleList.splice(index, 0, state.targetModule)
  },
  UPDATE_MODULE: (state, list) => {
    state.moduleList = list
  },
  SET_TARGET: (state, module) => {
    state.targetModule = module
  },
  CLEAR_TARGET: (state) => {
    state.targetModule = {}
  },
  SET_ACTIVE: (state, module) => {
    if (state.activeModule['id']) { // 切换激活组件时保存编辑内容
      const index = state.moduleList.findIndex(item => item.id === state.activeModule['id'])
      state.moduleList[index] = state.activeModule
    }

    state.activeModule = cloneDeep(module)
  },
  SET_DROP: (state, enable) => {
    state.dropEnable = enable
  },
  DELETE_MODULE: (state, index) => {
    state.moduleList.splice(index, 1)
  },
  SET_PAGEINFO: (state, info) => {
    state.pageInfo = info
  },
  SET_MODULE_LIST: (state, list) => {
    state.moduleList = list
  }
}

const actions = {
  AddModule({ commit,state }, index) {
    if (state.dropEnable) {
      commit('ADD_MODULE', index);
      commit('SET_DROP', false);
      commit('SET_ACTIVE', state.targetModule);
      commit('CLEAR_TARGET');
    } else {
      commit('CLEAR_TARGET');
    }
  },
  UpdateModule({ commit,state }, list) {
    if (state.dropEnable) {
      commit('UPDATE_MODULE', list);
      commit('SET_DROP', false);
    }
  },
  setTarget({ commit }, module) {
    const id = createDomID(13)
    commit('SET_TARGET', {
      id,
      ...module
    });
  },
  clearTarget({ commit }) {
    commit('CLEAR_TARGET');
  },
  setActive({ commit }, module) {
    commit('SET_ACTIVE', module);
  },
  setDropEnable({ commit }, enable) {
    commit('SET_DROP', enable);
  },
  deleteModule({ commit, state }, module) {
    const index = state.moduleList.findIndex(item => item.id === module['id'])
    commit('DELETE_MODULE', index);
  },
  setPageInfo({ commit }, info) {
    commit('SET_PAGEINFO', info);
  },
  setModuleList({ commit }, list) {
    commit('SET_MODULE_LIST', list);
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

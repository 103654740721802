import request from '@/http/axios'
import Api from '@/http/api'

export const getUserInfo = (data) =>
  request({
    url: Api.admin.accountInfo,
    method: 'get',
    data
  })

export const login = (data) =>
  request({
    url: Api.operation.login,
    method: 'post',
    data
  })

export const logout = () =>
  request({
    url: '/users/logout',
    method: 'post'
  })

export const changeMobileVerify = (data) =>
  request({
    url: Api.admin.changeMobileVerify,
    method: 'post',
    data
  })

export const changeMobile = (data) =>
  request({
    url: Api.admin.changeMobile,
    method: 'post',
    data
  })
export const changePass = (data) =>
  request({
    url: Api.admin.changePass,
    method: 'post',
    data
  })
export const resetPass = (data) =>
  request({
    url: Api.admin.resetPass,
    method: 'post',
    data
  })

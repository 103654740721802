<template>
  <div
    class="sidebar-logo-container"
    :class="{'collapse': collapse}"
    :style="{background: subjectObj.subjectColor}"
  >
    <transition name="sidebarLogoFade">
      <router-link
        v-if="collapse"
        class="sidebar-logo-link"
        to="/"
      >
        <img
          :src="subjectObj.loginLogo"
          class="sidebar-logo"
        />
      </router-link>
      <router-link
        v-else
        class="sidebar-logo-link"
        to="/"
      >
        <img
          :src="subjectObj.loginLogo"
          class="sidebar-logo"
        />
        <div class="sidebar-title">
          <div class="top">
            AGRICULTURAL EXPO
          </div>
          <div class="bottom">
            <span class="left">ONLINE</span>
            <span class="right">{{ subjectObj.name }}</span>
          </div>
        </div>
      </router-link>
    </transition>
  </div>
</template>

<script>
import { projectConfigDetail } from '@/utils/projectConfig';

export default {
  name: 'sidebar-logo',
  props: {
    collapse: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      title: 'Vue Element Admin',
      subjectObj: {
        subjectColor: '#000A41',
        loginLogo: 'https://nb-img.hzanchu.com/h5/nbh_admin/img/logo.png',
        name: '网上农博',
      },
    };
  },
  mounted() {
    this.getPageInfo();
  },
  methods: {
    getPageInfo() {
      this.subjectObj = projectConfigDetail;
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 50px;
  // background: #000A41;
  text-align: center;
  overflow: hidden;
  & .sidebar-logo-link {
    height: 100%;
    width: 100%;

    & .sidebar-logo {
      width: 32px;
      vertical-align: middle;
    }

    & .sidebar-title {
      transform: scale(80%);
      display: inline-block;
      vertical-align: middle;
      color: #fff;
      font-size: 10px;
      letter-spacing: 1px;
      >div {
        line-height: 18px;
      }
      .bottom {
        letter-spacing: 2px;
      }
      .left {
        font-weight: bold;
        font-size: 14px;
        margin-right: 1px;
      }
      .right {
        font-size: 14px;
        margin-left: 1px;
      }
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0px;
    }
  }
}
</style>

<template>
  <!-- <a
    v-if="isExternal(to)"
    :href="'/link?link=' + to"
    target="_self"
    rel="noopener"
  >
    <slot />
  </a> -->
  <!-- 跳转打开第三方链接 -->
  <router-link
    v-if="isExternal(to)"
    :to="'/web/page?link=' + to "
  >
    <slot />
  </router-link>
  <router-link
    v-else
    :to="to"
  >
    <slot />
  </router-link>
</template>

<script>
import { isExternal } from '@/utils/validate'

export default {
  name: 'SidebarItemLink',
  props: {
    to: {
      required: true
    }
  },
  data() {
    return {
      isExternal: isExternal
    }
  }
}
</script>

const environment = getEnvironment();
const platformConfigList = [
  // 3300 网上农博
  {
    code: '3300',
    name: '网上农博',
    platformCode: 1,
    logo: 'https://wsnbh-img.hzanchu.com/test/279998244680314880.png', // 二维码logo
    loginLogo: 'https://nb-img.hzanchu.com/h5/nbh_admin/img/logo.png', // 登录页-展示logo
    assSpecId: 47989, // 组合规格id，组合商品使用
    siteId: 0, // 查询展馆下拉列表参数
    urlKeyword: 'nb-',
    subjectColor: '#000A41', // 导航主题色
    channelList: [ // 渠道列表
      { id: '0', name: '网上农博', logoUrl: 'https://wsnbh-img.hzanchu.com/test/308657251722874880.png' },
      { id: '114', name: '浙里田园', logoUrl: 'https://wsnbh-img.hzanchu.com/test/308657341699084288.png' },
    ],
    appList: [ // 设置-版本设置
      { value: 1, label: '网上农博APP' },
      { value: 2, label: '商家端APP' },
      { value: 3, label: '浙里田园APP' },
    ],
    recommendsAppList: [ // 推荐位管理-渠道列表
      { value: 1, label: '首页' },
      { value: 2, label: '逛农博' },
      { value: 3, label: '浙里田园' },
      { value: 4, label: '农博优选' },
    ],
    KingkongShowCategory: true, // 新金刚取-是否展示分类
    kingkongSourceList: [ // 金刚区-渠道类型
      { id: 0, name: '农博（默认）' },
      { id: 1, name: '24节气桐你游' },
      { id: 3, name: '鱼米之乡绿色频道' },
      { id: 4, name: '浙里田园' },
    ],
    operatingEnvironment: environment, // 环境
    h5Url: { // 当前环境h5地址
      master: 'https://wsnb.hzanchu.com',
      pre: 'https://pre-wsnb.hzanchu.com',
      test: 'https://wsnb-test.hzanchu.com',
    }[environment],
    subjectJumpType: [
      { label: '首页', value: 'index' },
      { label: '商品', value: 'goods' },
      { label: '链接', value: 'link' },
      { label: '优惠券', value: 'coupon' },
      { label: '券包（批量领券）', value: 'coupackage' },
      { label: '农博优选频道', value: 'youxuan' },
      { label: '直播', value: 'live' },
      { label: '直播列表', value: 'liveList' },
      // { label: '秒杀', value: 'seckill' },
      { label: '拼团列表', value: 'spellGroup' },
      { label: '商家店铺', value: 'store' },
      { label: '市级馆', value: 'site' },
      { label: '市级馆集合页', value: 'siteGroup' },
      { label: '县级馆', value: 'county' },
      { label: '第三方应用', value: 'goExternalURL' },
      { label: '逛农博', value: 'farmerSaid' },
      { label: '逛农博-话题详情', value: 'farmerSaidTopicDetail' },
      { label: '分享有礼', value: 'shareGift' },
      { label: '小程序', value: 'distribution' },
      { label: '品牌认证', value: 'brandAuth' },
      { label: '拨打电话', value: 'phoneCalls' },
    ],
    kingList: [
      { value: 1, label: '首页' },
      { value: 2, label: '农博优选' },
    ],
  },
  // 3100 鱼米之乡
  {
    code: '3100',
    name: '鱼米之乡',
    platformCode: 2,
    logo: 'https://wsnbh-img.hzanchu.com/test/279998393003487232.png',
    loginLogo: 'https://nb-img.hzanchu.com/h5/nbh_admin/img/shanghai.png',
    assSpecId: 48467,
    siteId: '41217198239621120',
    urlKeyword: 'sh-',
    subjectColor: '#4cb2fb',
    channelList: [ // 渠道列表
      { id: '0', name: '鱼米之乡', logoUrl: 'https://wsnbh-img.hzanchu.com/test/308657478752161792.png' },
    ],
    appList: [ // 设置-版本设置
      { value: 1, label: '鱼米之乡APP' },
      { value: 2, label: '商家端APP' },
    ],
    recommendsAppList: [ // 推荐位管理-渠道列表
      { value: 1, label: '首页' },
    ],
    KingkongShowCategory: true, // 新金刚取-是否展示分类
    kingkongSourceList: [ // 金刚区-渠道类型
      { id: 0, name: '鱼米之乡（默认）' },
    ],
    operatingEnvironment: environment,
    h5Url: { // 当前环境h5地址
      master: 'https://wsnb.hzanchu.com',
      pre: 'https://pre-wsnb.hzanchu.com',
      test: 'https://wsnb-test.hzanchu.com',
    }[environment],
    subjectJumpType: [
      { label: '首页', value: 'index' },
      { label: '商品', value: 'goods' },
      { label: '链接', value: 'link' },
      { label: '优惠券', value: 'coupon' },
      { label: '券包（批量领券）', value: 'coupackage' },
      { label: '直播', value: 'live' },
      { label: '直播列表', value: 'liveList' },
      // { label: '秒杀', value: 'seckill' },
      { label: '拼团列表', value: 'spellGroup' },
      { label: '商家店铺', value: 'store' },
      { label: '市级馆', value: 'site' },
      { label: '市级馆集合页', value: 'siteGroup' },
      { label: '县级馆', value: 'county' },
      { label: '第三方应用', value: 'goExternalURL' },
      { label: '农名说', value: 'farmerSaid' },
      { label: '农名说-话题详情', value: 'farmerSaidTopicDetail' },
      { label: '分享有礼', value: 'shareGift' },
      { label: '小程序', value: 'distribution' },
      { label: '品牌认证', value: 'brandAuth' },
      { label: '拨打电话', value: 'phoneCalls' },
    ],
    kingList: []
  },
  // 5100 川府农博
  {
    code: '5100',
    name: '川府农博',
    platformCode: 4,
    logo: '',
    loginLogo: 'https://nb-img.hzanchu.com/h5/nbh_admin/img/logo.png',
    assSpecId: 59681,
    siteId: 0,
    urlKeyword: 'sc-',
    subjectColor: '#C7393A',
    channelList: [ // 渠道列表
      { id: '0', name: '川府农博', logoUrl: 'https://wsnbh-img.hzanchu.com/test/308657551456227328.png' },
    ],
    appList: [ // 设置-版本设置
      { value: 1, label: '川府农博APP' },
      { value: 2, label: '商家端APP' },
    ],
    recommendsAppList: [ // 推荐位管理-渠道列表
      { value: 1, label: '首页' },
    ],
    KingkongShowCategory: false, // 新金刚取-是否展示分类
    kingkongSourceList: [ // 金刚区-渠道类型
      { id: 0, name: '川府农博（默认）' },
    ],
    operatingEnvironment: environment,
    h5Url: { // 当前环境h5地址
      master: 'https://sc.zjnongbo.com',
      pre: 'https://pre-sc.zjnongbo.com',
      test: 'https://test-sc.zjnongbo.com',
    }[environment],
    subjectJumpType: [
      { label: '首页', value: 'index' },
      { label: '商品', value: 'goods' },
      { label: '链接', value: 'link' },
      { label: '优惠券', value: 'coupon' },
      { label: '券包（批量领券）', value: 'coupackage' },
      { label: '直播', value: 'live' },
      { label: '直播列表', value: 'liveList' },
      // { label: '秒杀', value: 'seckill' },
      { label: '拼团列表', value: 'spellGroup' },
      { label: '商家店铺', value: 'store' },
      { label: '市级馆', value: 'site' },
      { label: '市级馆集合页', value: 'siteGroup' },
      { label: '县级馆', value: 'county' },
      { label: '第三方应用', value: 'goExternalURL' },
      // { label: '逛农博', value: 'farmerSaid' },
      // { label: '逛农博-话题详情', value: 'farmerSaidTopicDetail' },
      { label: '分享有礼', value: 'shareGift' },
      { label: '小程序', value: 'distribution' },
      { label: '品牌认证', value: 'brandAuth' },
      { label: '拨打电话', value: 'phoneCalls' },
    ],
    kingList: []
  },
];
const projectCode = getProjectCode();
const platformConfigObj = platformConfigList.reduce((start, result) => {
  const newObj = Object.assign(start, {
    [result.code]: result,
  });
  return newObj;
}, {});
const projectConfigDetail = platformConfigObj[projectCode] || platformConfigObj['3300'];

// 获取运行环境
function getEnvironment() {
  const { hostname } = window.location;
  if (hostname.includes('pre')) return 'pre';
  if (hostname.includes('test')) return 'test';
  if (hostname.includes('.com')) return 'master';
  return 'test';
}
function getProjectCode() {
  const name = window.location.hostname;
  let code = 3300;
  for (let item of platformConfigList) {
    if (name.includes(item.urlKeyword)) {
      code = item.code;
    }
  }
  return code;
}

export {
  platformConfigList,
  platformConfigObj,
  projectConfigDetail, // 当前平台的数据
};

import Layout from '@/layout/index.vue';

const financeRouter = {
  path: '',
  component: Layout,
  meta: { title: '财务管理' },
  children: [
    {
      path: '/finance/settlement/list',
      component: () => import('@/views/nb/finance/settlement/list.vue'),
      meta: {
        title: '结算单列表',
      },
    },
    {
      path: '/finance/settlement/detail',
      component: () => import('@/views/nb/finance/settlement/detail.vue'),
      meta: {
        title: '结算单详情',
      },
    },
    {
      path: '/finance/widthDraw/list',
      component: () => import('@/views/nb/finance/widthDraw/list.vue'),
      meta: {
        title: '提现管理',
      },
    },
    {
      path: '/finance/supplySettlement/list',
      component: () => import('@/views/nb/finance/supplySettlement/list.vue'),
      meta: {
        title: '供应链结算单列表',
      },
    },
    {
      path: '/finance/supplyWidthDraw/list',
      component: () => import('@/views/nb/finance/supplyWidthDraw/list.vue'),
      meta: {
        title: '供应链提现管理',
      },
    },
    {
      path: '/finance/collection/list',
      component: () => import('@/views/nb/finance/collection/list.vue'),
      meta: {
        title: '收款账户管理',
      },
    },
    {
      path: '/finance/collection/detail',
      component: () => import('@/views/nb/finance/collection/detail.vue'),
      meta: {
        title: '收款账户详情',
      },
    },
    {
      path: '/finance/nongxin/list',
      component: () => import('@/views/nb/finance/nongxin/list'),
      meta: {
        title: '农信结算单',
      },
    },
    {
      path: '/finance/blacklist',
      component: () => import('@/views/nb/finance/blacklist/index.vue'),
      meta: {
        title: '提现黑名单',
      },
    },
    {
      path: '/finance/unionServiceFee',
      component: () => import('@/views/nb/finance/unionServiceFee/index.vue'),
      meta: {
        title: '工会订单服务费账单',
      },
    },
    // {
    //   path: '/distribution/extract/list',
    //   component: () => import('@/views/nb/finance/distribution/extractList.vue'),
    //   meta: {
    //     title: '余额提现'
    //   }
    // },
    // {
    //   path: '/distribution/extract/detail',
    //   component: () => import('@/views/nb/finance/distribution/extractDetail.vue'),
    //   meta: {
    //     title: '裂变设置'
    //   }
    // },
  ],
};

export default financeRouter;

import SlsTracker from '@aliyun-sls/web-track-browser'

const config = {
  host: 'cn-hangzhou.log.aliyuncs.com', // 所在地域的服务入口。例如cn-hangzhou.log.aliyuncs.com
  project: 'web-wsnb-log-1f59d9a99213154e752dcb48bbe09dd4', // Project名称。
  logstore: 'web_nbacadmin', // Logstore名称。 
  // time: 1, // 发送日志的时间间隔，默认是10秒。
  // count: 100, // 发送日志的数量大小，默认是10条。
  topic: 'acadmin_test' // 项目版本
}

if (RUNENV === 'master') {
  config.topic = "acadmin_online"
} else if (RUNENV === 'pre') {
  config.topic = "acadmin_pre"
}

const tracker = new SlsTracker(config)

export default tracker
import { copyOrderId } from '../common/com';
import { projectConfigDetail } from '@/utils/projectConfig';

export function userVoucher(vm) {
  const obj = {
    thead: {
      userId: '用户id',
      mobile: '用户手机号',
      couponId: '券id',
      couponName: '券名',
      orderId: '订单id',
      orderNo: '订单编号',
      couponTypes: '订单类型',
      orderCommitId: '订单Commitid',
      createTimes: '领取时间',
      useTimes: '使用时间',
      beginTimes: '有效开始时间',
      endTimes: '有效结束时间',
      backflowStates: '是否回流',
      isDeletes: '是否删除',
      isUseds: '是否使用',
    },
    width: {
      userId: '60px',
      mobile: '105px',
      couponId: '45px',
      orderId: '60px',
      couponTypes: '70px',
      orderCommitId: '105px',
      createTimes: '100px',
      useTimes: '100px',
      beginTimes: '100px',
      endTimes: '100px',
      backflowStates: '50px',
      isDeletes: '50px',
      isUseds: '50px',
    },
    tableColumnAttributes: {
      couponName: { 'show-overflow-tooltip': true },
      orderNo: { 'show-overflow-tooltip': true },
    },
  };
  return createTable(vm, obj);
}

export function rankingList(vm) {
  const { h5Url } = projectConfigDetail;
  const obj = {
    thead: { id: 'ID', name: '名称', iconUrl: '前端链接' },
  };
  obj.handle = rowHandler(vm, {
    width: '160',
    types: { delBtn: '删除', editBtn: '编辑' },
    editBtn(row) {
      vm.$router.push({ name: 'consumptionDetails', query: { id: row.id } });
    },
    delBtn(row) {
      const options = {
        message: `确认删除(id:${row.id})`,
        requestOptions: { method: 'get', url: vm.$api.rank.tradeDelete, params: { topId: row.id } },
      };
      msgboxRequest(vm, options);
    },
  });

  obj.render = {
    iconUrl(h, { row }) {
      return h('a', {
        attrs: { href: 'javascript:;' },
        style: { color: '#1890ff' },
        on: {
          click() {
            const { projectCode } = JSON.parse(localStorage.getItem('userInfo'));
            copyOrderId(vm, `${h5Url}/newRank/user?topId=${row.id}&projectCode=${projectCode}`);
          },
        },
      }, '复制前端链接');
    },
  };

  return createTable(vm, obj);
}

export function commentList(vm) {
  const obj = {
    thead: {
      commentId: '评论ID',
      contentTitle: '评论话题',
      userName: '发布人',
      content: '评论内容',
      imageUrls: '评论图片',
      updateTimes: '评论时间',
    },
    width: { imageUrls: '100px' },
  };

  obj.handle = rowHandler(vm, {
    width: '160',
    types: { showBtn: '显示', hideBtn: '隐藏', delBtn: '删除' },
    showBtn(row) {
      isHideFn(row, 'showBtnLoad', 0);
    },
    hideBtn(row) {
      isHideFn(row, 'hideBtnLoad', 1);
    },
    delBtn(row) {
      const options = {
        message: `确认删除评论(id:${row.commentId})`,
        requestOptions: { method: 'get', url: vm.$api.tradeOffLineActive.commentDel, params: { commentId: row.commentId } },
      };
      msgboxRequest(vm, options);
    },
  });

  obj.render = {
    imageUrls(h, { row }) {
      return h('el-image', { attrs: { src: row.imageUrls }, props: { previewSrcList: [row.imageUrls] } });
    },
  };

  async function isHideFn(row, load, isHidden) {
    const options = {
      load,
      requestOptions: {
        method: 'get',
        url: vm.$api.tradeOffLineActive.hiddenOrNo,
        params: { commentId: row.commentId, isHidden },
      },
    };
    await setHttpRes.call(vm, options, row);
    vm.getData();
  }

  return createTable(vm, obj);
}

export function contentList(vm) {
  const obj = {
    thead: {
      contentTitle: '内容标题',
      types: '类型',
      contentTagNames: '标签',
      times: '时间',
      price: '参考价格',
      contentDesc: '简介',
      jumpUrl: '跳转链接',
    },
    width: {
      contentTitle: '150px', types: '80px', price: '80px', jumpUrl: '200px',
    },
    tableColumnAttributes: {
      contentTitle: { 'show-overflow-tooltip': true },
      contentDesc: { 'show-overflow-tooltip': true },
    },
  };
  obj.handle = rowHandler(vm, {
    width: '160',
    types: { editBtn: '编辑', delBtn: '删除' },
    editBtn(row) {
      // vm.$router.push({name: 'contentAdd', query: {contentId: row.contentId}});
      vm.$router.push({ name: 'contentSetEdit', query: { contentId: row.contentId, ...vm.$route.query } });
    },
    delBtn(row) {
      const options = {
        message: `确认删除标题：${row.contentTitle}`,
        requestOptions: { method: 'get', url: vm.$api.tradeOffLineActive.contentDel, params: { contentId: row.contentId } },
      };
      msgboxRequest(vm, options);
    },
  });

  obj.render = {
    jumpUrl(h, { row }) {
      return h('a', { attrs: { href: row.jumpUrl, target: '_blank' } }, row.jumpUrl);
    },
  };

  obj.tableOutHandle = {
    topHandler(h) {
      return h('el-button', {
        attrs: { type: 'primary', size: 'mini', style: 'margin-bottom: 20px;' },
        on: {
          click() {
            // vm.$router.push({name: 'contentAdd'});
            vm.$router.push({ name: 'contentSetAdd', query: vm.$route.query });
          },
        },
      }, '+ 添加');
    },
  };

  return createTable(vm, obj);
}

export function offlineList(vm) {
  const obj = {
    thead: {
      channelName: '所属应用', activeName: '活动名称', times: '活动时间', createrName: '创建人',
    },
    width: { times: 180 },
  };
  obj.handle = rowHandler(vm, {
    width: '320',
    types: {
      editBtn: '编辑', delBtn: '删除', dataBtn: '报名用户', codeBtn: '签到码',
    },
    editBtn(row) {
      vm.$router.push({ name: 'signUpAdd', query: { id: row.id, channelId: vm.$route.query.channelId } });
    },
    delBtn(row) {
      const options = {
        message: `确认删除活动：${row.activeName}`,
        requestOptions: { method: 'get', url: vm.$api.tradeOffLineActive.delete, params: { id: row.id } },
      };
      msgboxRequest(vm, options);
    },
    dataBtn(row) {
      vm.$router.push({ name: 'signUp', query: { id: row.id } });
    },
    async codeBtn(row) {
      const res = await vm.$axios.get(vm.$api.tradeOffLineActive.getACode, { params: { id: row.id } });
      if (res.code === 0) {
        vm.dialogCode.infos.config.ds = res;
        vm.$refs.dialog.isshow = true;
      }
    },
  });

  obj.tableOutHandle = {
    topHandler(h) {
      return h('el-button', {
        attrs: { type: 'primary', size: 'mini', style: 'margin-bottom: 20px;' },
        on: {
          click() {
            vm.$router.push({ name: 'signUpAdd', query: { channelId: vm.$route.query.channelId } });
          },
        },
      }, '+ 添加');
    },
  };

  return createTable(vm, obj);
}

export const signUp = (vm) => {
  const states = [
    { label: '未签到', color: 'info' },
    { label: '已签到', color: 'success' },
  ];
  const reserveStatus = [
    { label: '待确认', color: 'info' },
    { label: '预约成功', color: 'success' },
  ];
  const obj = {
    thead: {
      id: '序号', userName: '姓名', userMobile: '手机号', reserveStatuss: '预约状态', states: '签到状态',
    },
  };

  obj.render = {
    states(h, { row }) {
      return h('el-tag', { attrs: { type: states[row.singState].color, size: 'mini', effect: 'dark' } }, states[row.singState].label);
    },
    reserveStatuss(h, { row }) {
      return h('el-tag', { attrs: { type: reserveStatus[row.reserveStatus].color, size: 'mini', effect: 'dark' } }, reserveStatus[row.reserveStatus].label);
    },
  };

  obj.handle = rowHandler(vm, {
    types: { subReservation: '预约确认' },
    subReservation(row) {
      const params = { id: row.id, reserveStatus: 1 };
      vm.$axios.post(vm.$api.tradeOffLineActive.reserveStatus, params).then(
        (res) => {
          if (res.code === 0) {
            vm.$message({ type: 'success', message: '操作成功' });
            vm.getData();
          }
        },
      );
    },
  });

  return createTable(vm, obj);
};

function createTable(vm, options = {}) {
  const _default = {
    tableKey: 'tables',
    width: {},
    align: {},
    thead: {},
    attrs: {},
    render: {},
    hidden: [],
    tableOutHandle: {},
    tableColumnAttributes: {},
  };
  const opt = vm._.merge(_default, options);

  let columns = Object.keys(opt.thead).map((key) => {
    const column = {
      prop: key,
      label: opt.thead[key],
      width: opt.width[key] || '',
      align: opt.align[key] || 'center',
      hidden: opt.hidden.includes(key),
      ...(opt.tableColumnAttributes[key] || {}),
    };
    if (opt.render[key]) column.render = opt.render[key];
    return column;
  });

  if (opt.handle) {
    if (opt.handle.constructor == Object) columns.push(opt.handle);
    else if (opt.handle.constructor == Array) columns = [...columns, ...opt.handle];
  }

  if (!opt.attrs.tableKey) opt.attrs.tableKey = opt.tableKey;

  return {
    [opt.tableKey]: {
      columns,
      ...tableAttrs(vm, opt.attrs),
      ...opt.tableOutHandle,
    },
  };
}

function tableAttrs(vm, options = {}) {
  const _default = {
    usePagAttrs: {
      total: 0, 'current-page': 1, 'page-size': 20, background: false,
    },
    currentChange() {
      vm.getData();
    },
    sizeChange() {
      vm.getData();
    },
  };
  const opt = vm._.merge(_default, options);
  let current = opt.usePagAttrs['current-page'];
  return {
    list: [],
    load: false,
    useTableAttrs: opt.useTableAttrs || {},
    usePagAttrs: opt.usePagAttrs,
    pagEvents: {
      'current-change': function (index) {
        if (current != index) {
          vm[opt.tableKey].usePagAttrs['current-page'] = index;
          opt.currentChange();
          current = index;
        }
      },
      'size-change': function (size) {
        vm[opt.tableKey].usePagAttrs['current-page'] = 1;
        vm[opt.tableKey].usePagAttrs['page-size'] = size;
        opt.sizeChange();
      },
    },
  };
}

function rowHandler(vm, options = {}) {
  const opt = _.merge({}, options);

  return {
    label: '操作',
    width: opt.width || '',
    align: 'center',
    render(h, { row }) {
      const btns = Object.keys(opt.types).map((btnName) => {
        if (!row[`${btnName}Hide`]) {
          const btn = h('el-button', {
            attrs: {
              type: row[`${btnName}Type`] || 'primary',
              size: row[`${btnName}Size`] || 'mini',
              disabled: row[`${btnName}Disabled`] || false,
            },
            props: { loading: row[`${btnName}Load`] },
            on: {
              click() {
                if (opt[btnName]) opt[btnName](row);
              },
            },
          }, opt.types[btnName]);

          return btn;
        } return '';
      });
      return h('div', btns);
    },
  };
}

function setHttpRes(options = {}, row) {
  if (options.load) {
    if (row) row[options.load] = true;
    else this[options.load] = true;
  }

  return new Promise(async (resolve) => {
    const res = await this.$axios(options.requestOptions);
    const msg = options.msg || res.msg;
    if (res.code === 0) {
      if (!options.notip) this.$message.success(msg);
      resolve(res);
    } // else this.$message.error(msg);

    if (options.load) {
      if (row) row[options.load] = false;
      else this[options.load] = false;
    }
  });
}

function msgboxRequest(vm, options = {}) {
  const def = {
    title: '提示',
    dangerouslyUseHTMLString: true,
    showCancelButton: true,
    closeOnClickModal: false,
    beforeClose: async (action, instance, done) => {
      if (action == 'confirm') {
        instance.confirmButtonLoading = true;
        const res = await vm.$axios(options.requestOptions);
        if (res.code === 0) {
          vm.$message.success('操作成功');
          done();
          vm.getData();
          setTimeout(() => {
            instance.confirmButtonLoading = false;
          }, 300);
        } else {
          instance.confirmButtonLoading = false;
          // vm.$message.error(res.msg + '');
        }
      } else {
        instance.confirmButtonLoading = false;
        done();
      }
    },
  };
  vm.$msgbox(vm._.merge(def, options)).catch((err) => console.log('err', err));
}

import { render, staticRenderFns } from "./loadImgOss.vue?vue&type=template&id=7c24bc96&scoped=true&"
import script from "./loadImgOss.vue?vue&type=script&lang=js&"
export * from "./loadImgOss.vue?vue&type=script&lang=js&"
import style0 from "./loadImgOss.vue?vue&type=style&index=0&id=7c24bc96&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.1_css-loader@6.8.1_lodash@4.17.21_vue-template-compiler@2.7.14_webpack@5.88.2/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c24bc96",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/web_acadmin_online_docker/node_modules/.pnpm/vue-hot-reload-api@2.3.4/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7c24bc96')) {
      api.createRecord('7c24bc96', component.options)
    } else {
      api.reload('7c24bc96', component.options)
    }
    module.hot.accept("./loadImgOss.vue?vue&type=template&id=7c24bc96&scoped=true&", function () {
      api.rerender('7c24bc96', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/common/loadImgOss.vue"
export default component.exports
import axios from 'axios';
import { Message } from 'element-ui';
import { isNull } from 'lodash';
import router from '@/router/index.js';
import { trackerSend } from '@/monitor/utils';

let service;

// 生产环境下
service = axios.create({
  baseURL: '',
  timeout: 100000,
});

service.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem('token');
    let projectCode = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).projectCode : '';
    if (token) {
      // config.headers.common['Authorization'] = UserModule.token
      config.headers.common.Authorization = token;
    }
    config.headers.common.commonParam = projectCode;
    config.headers.common.os = 'pc';
    return config;
  },
  (error) => {
    // Do something with request error
    console.error('error:', error); // for debug
    Promise.reject(error);
  },
);

let switchToLogin;
service.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      let rsp = response.data;
      if (rsp.error === 0 || rsp.code === 0) {
        return rsp;
      }
      if (rsp.code === 703) {
        if (switchToLogin) {
          return rsp;
        }
        router.replace({
          path: '/login',
          query: {
            redirect: router.history.current.path,
          },
        });
        Message({
          message: rsp.msg,
          type: 'error',
        });
        switchToLogin = true;
        setTimeout(() => {
          switchToLogin = false;
        }, 1000);
        return rsp;
      }
      if (rsp.code !== 0) {
        if (rsp.error_msg && rsp.error_msg !== '请登录') {
          // TODO：将全局错误提示功能优化掉
          if (rsp.error_msg === '请保存后再提交审核') {
            Message({
              message: '请将所有必填项填写完成后再提交审核',
              type: 'error',
            });
          } else if (rsp.error_msg.indexOf('Forbidden.IllegalStatus') !== -1) {
            // 处理阿里云上传视频转码报错文案
            Message({
              message: '视频正在转码中',
              type: 'info',
            });
          } else {
            Message({
              message: rsp.error_msg,
              type: 'error',
            });
          }
        }
        if (rsp.msg && rsp.msg !== '请登录') {
          Message({
            message: rsp.msg,
            type: 'error',
          });
        }
      }
      return rsp;
    }
    Message({
      message: '网络异常，请重试',
      type: 'error',
    });
  },
  (error) => {
    // 对请求错误做些什么
    const response = error.response || {};
    trackerSend('httpError', {
      status: response.status,
      url: error.config.url,
      data: error.config?.data,
      params: error.config?.params,
      method: error.config?.method,
      message: error.message,
    });
    return Promise.reject(error);
  },
);
export default service;

export function get(url, data = {}) {
  return service.get(url, { params: data });
}

export function post(url, data = {}, config={}) {
  return service.post(url, data, config);
}

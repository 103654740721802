<template>
<div class="my-table">
  <Render v-if="topHandler" :render="topHandler" :data="{}"></Render>
  <slot v-else name="top-handle"></slot>
	
  <el-table v-loading="load" :data="list" v-bind="tableAttrs" v-on="tableEvents">
    <el-table-column v-if="tableAttrs.selection" type="selection" align="center" width="45"></el-table-column>

    <el-table-column v-bind="item" v-for="item in columnsList" :key="item.id">
      <template v-if="item.children">
        <el-table-column v-bind="child" v-for="child in item.children" :key="child.label">
          <template slot-scope="{row,$index}">
            <Render v-if="child.render" :render="child.render" :data="{row,$index}"></Render>
            <span v-else>{{row[child.prop]}}</span>
          </template>
        </el-table-column>
      </template>
      <template slot-scope="{row,$index}">
        <Render v-if="item.render" :render="item.render" :data="{row,$index}"></Render>
        <span v-else>{{row[item.prop]}}</span>
      </template>
    </el-table-column>
  </el-table>

  <el-row class="table-bottom-hand">
    <el-col :span="tableAttrs.handlerWidth">
      <Render v-if="bottomHandler" :render="bottomHandler" :data="{}"></Render>
      <slot v-else name="bottom-handle"><div style="text-indent:-666666px;">1</div></slot>
    </el-col>
    <el-col :span="pagAttrs.pageWidth" v-if="pagAttrs.isCreated && list.length">
      <el-pagination v-bind="pagAttrs" v-on="pagEvents"></el-pagination>
    </el-col>
  </el-row>
</div>
</template>

<script>
import Render from '../common/render.js'

const defTableAttrs = {
  'border': true,
  'stripe': true,
  'size': 'small',
  'highlight-current-row': true,
  'handlerWidth': 7
};

const defPagAttrs = {
  'isCreated': true,
  'background': true,
  'layout': 'total, sizes, prev, pager, next, jumper',
  'page-sizes': [10, 20, 30, 50],
  'pageWidth': 17
};

export default {
  name: 'createTable',
  components: {Render},
  props: {
    topHandler: {typeof: Function},
    bottomHandler: {typeof: Function},
    useTableAttrs: {type: Object, default () {return {};}},
    tableEvents: {type: Object, default () {return {};}},
    columns: {type: Array, default () {return [];}},
    list: {type: Array, default () {return [];}},
    load: {type: Boolean, default: false},

    usePagAttrs: {type: Object, default () {return {};}},
    pagEvents: {type: Object, default () {return {};}},
  },
  data () {
    return {
      tableAttrs: {...defTableAttrs, ...this.useTableAttrs},
      pagAttrs: {...defPagAttrs, ...this.usePagAttrs}
    }
  },
  computed: {
    columnsList() {
      return this.columns.filter(item => !item.hidden);
    }
  },
  watch: {
    useTableAttrs: {
      handler (newVal) {
        this.tableAttrs = {...defTableAttrs, ...newVal};
      },
      deep: true
    },
    usePagAttrs: {
      handler (newVal) {
        this.pagAttrs = {...defPagAttrs, ...newVal};
      },
      deep: true
    }
  }
}
</script>

<template>
<div class="modal-box" v-if="isshow">
  <el-dialog v-bind="modal.attrs" :visible.sync="isshow">
    <Render v-if="modal.render" :render="modal.render" :data="modal.data||{}"></Render>
    <component v-if="modal.type && isshow" :is="modal.type" v-bind="modal.config||{}"></component>
    <slot name="content"></slot>
    <div v-if="modal.showBtn" slot="footer" class="dialog-footer">
      <el-button v-if="modal.showCancelBtn" size="small" @click="closeModal">{{modal.cancelBtnText}}</el-button>
      <el-button type="primary" size="small" @click="submitModal">{{modal.submitBtnText}}</el-button>
    </div>
  </el-dialog>
</div>
</template>

<script>
import {importAll, clone} from '../common/com';
import Render from '../common/render.js';
import _ from 'lodash';

const components = importAll(require.context('./components/', false, /\.vue$/), true);

const defConfig = {
  attrs: {
    title: '提示',
    width: '400px',
    showClose: true,
    closeOnClickModal: false
  },
  submitBtnText: '确定',
  cancelBtnText: '取消'
};

export default {
  name: 'myModal',
  components: {Render, ...components},
  props: {
    infos: {
      type: Object,
      default () {
        return {};
      }
    }
  },
  data () {console.log(this.infos)
    return {
      isshow: false,
      modal: _.merge(clone(defConfig), this.infos)
    }
  },
  watch: {
    infos: {
      handler (newVal) {
        this.modal = _.merge(clone(defConfig), newVal);
      },
      deep: true
    }
  },
  methods: {
    closeModal () {
      this.isshow = false;
      this.modal.config.ds = {};
    },
    submitModal(){
      if (this.modal.onsubmit) this.modal.onsubmit();
      else this.closeModal();
    }
  }
}
</script>
export function userVoucher(vm) {
  const rule = [
    {
      is: 'el-input', field: 'couponName', value: '', title: '优惠券名称',
      attrs: {placeholder: '请输入优惠券名称'}
    },
    {
      is: 'el-input', field: 'mobil', value: '', title: '手机号',
      attrs: {placeholder: '请输入手机号'}
    },
    ...createBtn(vm)
  ]
  return {
    forms: {...formAttrs(vm), rule}
  };
}


export function rankingList(vm) {
  const rule = [
    {
      is: 'el-input', field: 'rankingName', value: '', title: '排行榜名称',
      attrs: {placeholder: '请输入排行榜名称'}
    },
    ...createBtn(vm),
    {
      is: 'el-button', innerText: '新建', 
      attrs: {type: 'primary', size: 'mini'},
      on: {
        click () {
          vm.$router.push({name: 'consumptionDetails'});
        }
      }
    }
  ]
  return {
    forms: {...formAttrs(vm), rule}
  };
}


export function offlineList(vm) {
  const rule = [
    {
      is: 'el-input', field: 'name', value: '', title: '活动名称',
      attrs: {placeholder: '请输入活动名称'}
    },
    ...createBtn(vm)
  ]
  return {
    forms: {...formAttrs(vm), rule}
  };
}

export function signUp(vm) {
  const rule = [
    {is: 'el-input', field: 'userMobile', value: '', title: '手机号'},
    {is: 'el-input', field: 'userName', value: '', title: '姓名'},
    ...createBtn(vm, {submitText: '查询'})
  ];

  return {
    forms: {...formAttrs(vm), rule}
  };
}

function formAttrs(vm, options = {}) {
  const opt = vm._.merge({ref: 'forms', inline: true, labelWidth: ''}, options);
  return {
    ref: opt.ref,
    fApi: {},
    validate: opt.validate || {},
    option: {
      form: {inline: opt.inline, labelWidth: opt.labelWidth}
    }
  };
}

function createBtn(vm, options = {}) {
  const _default = {
    formKey: 'forms', tableKey: 'tables',
    submitText: '搜索', submitType: 'primary',
    resetText: '重置', resetType: 'default',
    submitClick() {
      vm.getData();
    },
    resetClick() {
      vm.getData();
    }
  };
  const opt = vm._.merge(_default, options);

  return [
    {
      is: 'el-button', innerText: opt.submitText, 
      attrs: {type: opt.submitType, size: 'mini'},
      on: {
        click () {
          const forms = vm.$refs[opt.formKey];
          forms.$refs.elForm.validate((valid) => {
            if (valid) {
              if (vm.tableKey) opt.tableKey = vm.tableKey;
              if (vm[opt.tableKey]) vm[opt.tableKey].usePagAttrs['current-page'] = 1;
              opt.submitClick();
            }
          });
        }
      },
      afterRender (h) {
        return h('el-button', {
          attrs: {type: opt.resetType, size: 'mini'},
          on: {
            click () {
              if (vm.tableKey) opt.tableKey = vm.tableKey;
              const forms = vm.$refs[opt.formKey];
              vm[opt.formKey].fApi = JSON.parse(JSON.stringify(forms.defData));console.log(vm[opt.formKey].fApi)
              if (vm[opt.tableKey]) vm[opt.tableKey].usePagAttrs['current-page'] = 1;
              opt.resetClick();
            }
          }
        }, opt.resetText);
      },
    }
  ];
}
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "editor-component" },
    [
      _c("LoadImgOss", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false",
          },
        ],
        ref: "LoadImgOss",
        attrs: { preview: false, "event-trigger": "" },
        on: { onSuccess: _vm.onSuccessUploadImg },
      }),
      _c("quill-editor", {
        directives: [{ name: "maxWindow", rawName: "v-maxWindow" }],
        ref: "editor",
        attrs: { options: _vm.editorOption },
        on: {
          change: _vm.changefn,
          focus: function ($event) {
            return _vm.focus($event)
          },
        },
        model: {
          value: _vm.content,
          callback: function ($$v) {
            _vm.content = $$v
          },
          expression: "content",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
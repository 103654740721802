import Layout from '@/layout/index.vue';

const MarketingRouter = {
  path: '',
  component: Layout,
  meta: { title: '营销工具' },
  children: [
    {
      path: '/nb/marketingTool/discountCoupon/index',
      component: () => import('@/views/nb/marketingTool/discountCoupon/index.vue'),
      meta: {
        title: '优惠券',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/nb/marketingTool/couponList/index',
      component: () => import('@/views/nb/marketingTool/couponList/index.vue'),
      meta: {
        title: '领券中心',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/nb/marketingTool/fullCoupon/index',
      component: () => import('@/views/nb/marketingTool/fullCoupon/index.vue'),
      meta: {
        title: '满额返券',
        icon: '',
      },
    },
    {
      path: '/nb/marketingTool/groupBook',
      component: () => import('@/views/nb/marketingTool/group/index.vue'),
      meta: {
        title: '拼团管理',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/marketingTool/rank/list',
      component: () => import('@/views/nb/marketingTool/rank/list.vue'),
      meta: {
        title: '排行榜管理',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/marketingTool/rank/add',
      component: () => import('@/views/nb/marketingTool/rank/add.vue'),
      meta: {
        title: '新增排行榜',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/marketingTool/rank/edit',
      component: () => import('@/views/nb/marketingTool/rank/edit.vue'),
      meta: {
        title: '编辑排行榜',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/marketingTool/rank/editGroup',
      component: () => import('@/views/nb/marketingTool/rank/editGroup.vue'),
      meta: {
        title: '编辑分组',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/operation/adplace',
      component: () => import(/* webpackChunkName: "suggestlist" */ '@/views/operation/adPlace/index.vue'),
      meta: {
        title: '广告位置管理',
      },
    },
    {
      path: '/operation/adplace/add',
      component: () => import(/* webpackChunkName: "addAdPlace" */ '@/views/operation/adPlace/addAdPlace/index.vue'),
      meta: {
        title: '新增广告位置',
      },
    },
    {
      path: '/operation/admanage',
      component: () => import('@/views/operation/ad/index.vue'),
      meta: {
        title: '广告管理',
      },
    },
    {
      path: '/operation/adSpace',
      name: 'adSpace',
      component: () => import('@/views/regionTemp.vue'),
      meta: {
        title: '广告位管理',
      },
    },
    {
      path: '/operation/admanage/addbanner',
      component: () => import(/* webpackChunkName: "addbanner" */ '@/views/operation/ad/addBanner/index.vue'),
      meta: {
        title: '添加轮播图',
      },
    },
    {
      path: '/nb/distribution/goodsSetting',
      component: () => import('@/views/nb/marketingTool/setting/goodsSetting.vue'),
      meta: {
        title: '分销商品管理',
      },
    },
    {
      path: '/nb/distribution/addGoods',
      component: () => import('@/views/nb/marketingTool/setting/addGoods.vue'),
      meta: {
        title: '添加分销商品',
      },
    },
    {
      path: '/operation/kingkong',
      component: () => import(/* webpackChunkName: "kingkong" */ '@/views/operation/kingKong/index.vue'),
      meta: {
        title: '首页金刚区管理',
      },
    },
    {
      path: '/operation/kingkong/detail',
      component: () => import(/* webpackChunkName: "detail" */ '@/views/operation/kingKong/detail/index.vue'),
      meta: {
        title: '首页金刚区管理',
      },
    },
    {
      path: '/ldx/kingKong',
      name: 'kingKong',
      component: () => import('@/views/regionTemp.vue'),
      meta: {
        title: '金刚区管理',
      },
    },
    {
      path: '/operation/kingkong/details',
      name: 'kingKongDetails',
      component: () => import('@/views/operation/kingKong/details/index.vue'),
      meta: {
        title: '首页金刚区管理详情',
      },
    },
    {
      path: '/ldx/kingKongTwoFour',
      name: 'kingKongTwoFour',
      component: () => import('@/views/operation/kingKongTwoFour/list.vue'),
      meta: {
        title: '金刚区管理(新)',
      },
    },
    {
      path: '/operation/goodsAtmosphereImage',
      name: 'goodsAtmosphereImage',
      component: () => import('@/views/operation/goodsAtmosphereImage/index.vue'),
      meta: {
        title: '商品氛围图管理',
      },
    },
    {
      path: '/operation/goodsAtmosphereImage/detail',
      name: 'goodsAtmosphereImageDetail',
      component: () => import('@/views/operation/goodsAtmosphereImage/detail.vue'),
      meta: {
        title: '详情',
      },
    },
    {
      path: '/operation/shareofficial',
      component: () => import('@/views/operation/shareOfficial/index.vue'),
      meta: {
        title: '分享文案',
      },
    },
    {
      path: '/operation/subject',
      component: () => import(/* webpackChunkName: "subject" */ '@/views/operation/subject/index.vue'),
      meta: {
        title: '专题列表',
        keepAlive: true,
      },
    },
    {
      path: '/operation/subject/detail',
      component: () => import(/* webpackChunkName: "detail" */ '@/views/operation/subject/detail/index.vue'),
      meta: {
        title: '专题模板详情',
      },
    },
    {
      path: '/operation/subject/data',
      component: () => import(/* webpackChunkName: "subject" */ '@/views/operation/subjectData/index.vue'),
      meta: {
        title: '专题数据',
      },
    },
    {
      path: '/operation/atmosphereMap/map',
      component: () => import('@/views/operation/atmosphereMap/index.vue'),
      meta: {
        title: '首页氛围图管理',
      },
    },
    {
      path: '/operation/consumptionDetails',
      name: 'consumptionDetails',
      component: () => import('@/views/operation/consumption/details.vue'),
      meta: {
        title: '新增/编辑消费之星排行榜',
      },
    },
    { // 企业排行榜-企业管理
      path: '/companyRank',
      component: () => import('@/views/operation/companyRank/index.vue'),
      meta: {
        title: '企业信息管理',
      },
    },
    {
      path: '/vote/list',
      component: () => import('@/views/operation/vote/list.vue'),
      meta: {
        title: '投票管理',
      },
    },
    {
      path: '/vote/add',
      component: () => import('@/views/operation/vote/add.vue'),
      meta: {
        title: '新增投票活动',
      },
    },
    {
      path: '/vote/edit',
      component: () => import('@/views/operation/vote/edit.vue'),
      meta: {
        title: '编辑投票活动',
      },
    },
    {
      path: '/vote/goods',
      component: () => import('@/views/operation/vote/goods.vue'),
      meta: {
        title: '投票商品',
      },
    },
    {
      path: '/vote/content',
      component: () => import('@/views/operation/vote/content.vue'),
      meta: {
        title: '参投内容',
      },
    },
    {
      path: '/ldx/rankLists',
      name: 'rankLists',
      component: () => import('@/views/regionTemp.vue'),
      meta: {
        title: '榜单管理',
      },
    },
    {
      path: '/ldx/rankDetails',
      name: 'rankDetails',
      component: () => import('@/views/ldx/rankLists/detail.vue'),
      meta: {
        title: '榜单管理详情',
      },
    },
    {
      path: '/ldx/keywords',
      name: 'keywords',
      component: () => import('@/views/ldx/keywords/index.vue'),
      meta: {
        title: '关键词推荐位',
      },
    },
    {
      path: '/ldx/keyDetails',
      name: 'keyDetails',
      component: () => import('@/views/ldx/keywords/details.vue'),
      meta: {
        title: '关键词推荐位详情',
      },
    },
    {
      path: '/marketingTool/solarteam',
      name: 'solarteam',
      component: () => import('@/views/marketingTool/solarteam/index.vue'),
      meta: {
        title: '节气频道页',
      },
    },
    {
      path: '/nb/marketingTool/shareGift',
      name: 'shareGift',
      component: () => import('@/views/nb/marketingTool/shareGift/index.vue'),
      meta: {
        title: '分享有礼',
      },
    },
    {
      path: '/nb/marketingTool/shareGift/detail',
      name: 'shareGiftDetail',
      component: () => import('@/views/nb/marketingTool/shareGift/detail.vue'),
      meta: {
        title: '详情',
      },
    },
    {
      path: '/mt/venue',
      name: 'venue',
      component: () => import('@/views/marketingTool/venue/index.vue'),
      meta: {
        title: '市县馆',
      },
    },
    {
      path: '/marketingTool/channelLiveCode', // 渠道活码（鱼米之乡）
      name: 'ChannelLiveCode',
      component: () => import('@/views/marketingTool/channelLiveCode/index.vue'),
      meta: {
        title: '渠道活码',
      },
    },
  ],
};
export default MarketingRouter;

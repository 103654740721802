import Layout from '@/layout/index.vue'

const settingRouter = {
  path: '',
  component: Layout,
  meta: { title: '设置' },
  children: [
    {
      path: '/setting/navcontrol',
      component: () => import(/* webpackChunkName: "suggestlist" */ '@/views/setting/navControl/index.vue'),
      meta: {
        title: '导航管理'
      }
    },
    {
      path: '/setting/navcontrol/supplier',
      component: () => import(/* webpackChunkName: "suggestlist" */ '@/views/setting/navControl/supplier.vue'),
      meta: {
        title: '商家导航管理'
      }
    },
    {
      path: '/setting/sms/template',
      component: () => import('@/views/setting/smsTemplate.vue'),
      meta: {
        title: '短信模板',
        icon: 'iconcaiwurenminbizhifux'
      }
    },
    {
      path: '/setting/sms/log',
      component: () => import('@/views/setting/smsLog.vue'),
      meta: {
        title: '短信日志',
        icon: 'iconcaiwurenminbizhifux'
      }
    },
    {
      path: '/setting/account/list',
      component: () => import('@/views/setting/account/list.vue'),
      meta: {
        title: '账户管理',
        icon: 'iconcaiwurenminbizhifux'
      }
    },
    {
      path: '/setting/account/detail',
      component: () => import('@/views/setting/account/detail.vue'),
      meta: {
        title: '账户管理详情',
        icon: 'iconcaiwurenminbizhifux'
      }
    },
    {
      path: '/setting/role/list',
      component: () => import('@/views/setting/role/list.vue'),
      meta: {
        title: '角色管理',
        icon: 'iconcaiwurenminbizhifux'
      }
    },
    {
      path: '/setting/role/nav',
      component: () => import('@/views/setting/role/nav.vue'),
      meta: {
        title: '角色导航管理',
        icon: 'iconcaiwurenminbizhifux'
      }
    },
    {
      path: '/setting/image/list',
      component: () => import('@/views/setting/image/list.vue'),
      meta: {
        title: '图片管理',
        icon: 'iconcaiwurenminbizhifux'
      }
    },
    {
      path: '/setting/minipath',
      component: () => import('@/views/setting/miniPath.vue'),
      meta: {
        title: '小程序链接'
      }
    },
    {
      path: '/setting/file',
      component: () => import('@/views/setting/file/index.vue'),
      meta: {
        title: '文件管理',
        icon: 'iconcaiwurenminbizhifux'
      }
    },
    {
      path: '/setting/version',
      component: () => import('@/views/setting/version/index.vue'),
      meta: {
        title: '版本配置',
        icon: ''
      }
    },
    {
      path: '/setting/cache',
      component: () => import('@/views/setting/cache/index.vue'),
      meta: {
        title: '缓存设置',
        icon: ''
      }
    },
    {
      path: '/setting/poster',
      component: () => import('@/views/setting/poster/index.vue'),
      meta: {
        title: '海报管理',
        icon: ''
      }
    }
  ]
}

export default settingRouter

import Layout from '@/layout/index.vue'

const guideRouter = {
  path: '',
  component: Layout,
  meta: { title: '导览管理' },
  children: [
    // {
    //   path: '/nb/guide/list',
    //   component: () => import('@/views/nb/miniProgram/guide/list.vue'),
    //   meta: {
    //     title: '导览管理',
    //     icon: 'iconcaiwurenminbizhifux'
    //   }
    // },
    {
      path: '/nb/guide/line',
      component: () => import('@/views/nb/miniProgram/guide/components/line.vue'),
      meta: {
        title: '关联线路',
        icon: 'iconcaiwurenminbizhifux'
      }
    },
    {
      path: '/nb/guide/service',
      component: () => import('@/views/nb/miniProgram/guide/components/service.vue'),
      meta: {
        title: '关联服务点',
        icon: 'iconcaiwurenminbizhifux'
      }
    },
    // {
    //   path: '/nb/service/list',
    //   component: () => import('@/views/nb/miniProgram/service/list.vue'),
    //   meta: {
    //     title: '服务点管理',
    //     icon: 'iconcaiwurenminbizhifux'
    //   }
    // },
    // {
    //   path: '/nb/line/list',
    //   component: () => import('@/views/nb/miniProgram/line/list.vue'),
    //   meta: {
    //     title: '线路管理',
    //     icon: 'iconcaiwurenminbizhifux'
    //   }
    // },
    {
      path: '/nb/line/service',
      component: () => import('@/views/nb/miniProgram/line/components/service.vue'),
      meta: {
        title: '关联服务点',
        icon: 'iconcaiwurenminbizhifux'
      }
    },
    // {
    //   path: '/nb/map/list',
    //   component: () => import('@/views/nb/miniProgram/map/list.vue'),
    //   meta: {
    //     title: '地图管理',
    //     icon: 'iconcaiwurenminbizhifux'
    //   }
    // },
  ]
}

export default guideRouter

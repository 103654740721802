const base = APPLICATION_ENV.MARKETING_API;
const marketingApis = {
  sharePresent: {
    pageProm: `${base}platform/sharePresent/pageProm`, // 账号列表
    addProm: `${base}platform/sharePresent/addProm`, // 添加
    editProm: `${base}platform/sharePresent/editProm`, // 编辑分享有礼活动
    changeStatus: `${base}platform/sharePresent/changeStatus`, // 上下架分享有礼活动
    detail: `${base}platform/sharePresent/detail`, // 创建分享有礼活动详情
  },
  returnCoupon: {
    detail: `${base}platform/return-coupon/detail`,
    enableState: `${base}platform/return-coupon/enable-state`,
    insert: `${base}platform/return-coupon/insert`,
    page: `${base}platform/return-coupon/page`,
    update: `${base}platform/return-coupon/update`,
    userPage: `${base}platform/return-coupon/user/page`,
  },
};

export default marketingApis;

<template>
  <div class="has-logo">
    <logo :collapse="isCollapse" />
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :collapse="isCollapse"
        background-color="#fff"
        text-color="#222"
        active-text-color="#fff"
        :unique-opened="true"
        :collapse-transition="false"
        mode="vertical"
      >
        <sidebar-item
          v-for="route in menulist"
          :key="route.path"
          :item="route"
          :is-collapse="isCollapse"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SidebarItem from "./SidebarItem.vue";
import variables from "@/styles/_variables.scss";
import Logo from "./Logo.vue";

export default {
  name: "SideBar",
  components: {
    SidebarItem,
    Logo,
  },
  data() {
    return {
      menulist: [],
    };
  },
  computed: {
    ...mapState({
      sidebar: (state) => state.app.sidebar
    }),
    routes() {
      return this.$router.options.routes;
    },
    variables() {
      return variables;
    },
    isCollapse() {
      return !this.sidebar.opened;
    },
  },

  mounted() {
    this.getMenuList();
  },
  methods: {
    async getMenuList() {
      const res = await this.$axios.post(
        this.$api.nav.getlist
      );
      if (res.code === 0) {
        this.menulist = res.data;
      }
    },
  },
};
</script>

<style lang="scss">
.sidebar-container {
  // reset element-ui css
  .horizontal-collapse-transition {
    transition: 0s width ease-in-out, 0s padding-left ease-in-out,
      0s padding-right ease-in-out;
  }

  .scrollbar-wrapper {
    overflow-x: hidden !important;
  }

  .el-scrollbar__view {
    height: 100%;
  }

  .el-scrollbar__bar {
    &.is-vertical {
      right: 0px;
    }
    &.is-horizontal {
      display: none;
    }
  }
}
.menu-wrapper {
  .router-link-active {
    .is-active {
      background: #3bccf8 !important;
    }
  }
}
.el-menu-item:hover {
  background: #3bccf8 !important;
  color: #fff !important;
  .iconfont {
    color: #fff;
  }
}
.el-submenu__title:hover {
  background: #3bccf8 !important;
  color: #fff !important;
  .iconfont {
    color: #fff;
  }
}
.el-menu--inline {
  background: #d7f4fd !important;
}
</style>

<style lang="scss" scoped>
.has-logo {
  .el-scrollbar {
    height: calc(100% - 50px);
  }
}
.el-scrollbar {
  height: 100%;
}
.el-menu {
  border: none;
  height: 100%;
  width: 100% !important;
}
</style>

import Layout from '@/layout/index.vue'

const distributionRouter = {
  path: '',
  component: Layout,
  meta: { title: '分销管理' },
  children: [
    {
      path: '/distribution/newUser/detail',
      component: () => import('@/views/nb/marketing/distribution/newUserDetail.vue'),
      meta: {
        title: '邀请新人设置'
      }
    },
    {
      path: '/distribution/newUser/list',
      component: () => import('@/views/nb/marketing/distribution/newUserList.vue'),
      meta: {
        title: '邀请新人明细'
      }
    },
    {
      path: '/distribution/commission/list',
      component: () => import('@/views/nb/marketing/distribution/commissionList.vue'),
      meta: {
        title: '佣金明细'
      }
    },
    {
      path: '/distribution/extract/list',
      component: () => import('@/views/nb/marketing/distribution/extractList.vue'),
      meta: {
        title: '余额提现'
      }
    },
    {
      path: '/distribution/extract/detail',
      component: () => import('@/views/nb/marketing/distribution/extractDetail.vue'),
      meta: {
        title: '裂变设置'
      }
    },
    {
      path: '/distribution/commission/log',
      component: () => import('@/views/nb/marketing/distribution/commissionLog.vue'),
      meta: {
        title: '分销日志'
      }
    }
  ]
}

export default distributionRouter

<template>
  <div class="page-header">
    <span class="title-name">{{ title }}</span>
    <div class="content">
      <slot />
    </div>
  </div>
</template>

<script>
/**
 * 页面标题组件
 * @property {String} title 标题内容
 * @example <page-header title="商品基本信息"></page-header>
 */
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
  .page-header {
    height: 58px;
    position: relative;
    background: #fff;
    box-shadow: 0px 0px 16px 1px rgba(0, 0, 0, 0.08);
    .title-name {
      position: absolute;
      font-size: 16px;
      color: #0D0202;
      font-weight: 600;
      line-height: 58px;
      left: 30px;
    }
    .content {
      width: 100%;
      height: 100%;
      position: relative;
    }
  }
</style>

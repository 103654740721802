const base = APPLICATION_ENV.USER_API
const adminApis = {
  admin: {
    list: base + 'backplat/admin/list', // 账号列表
    view: base + 'backplat/admin/view', // 账号查看
    add: base + 'backplat/admin/add', // 账号新增
    delete: base + 'backplat/admin/delete', // 账号删除
    edit: base + 'backplat/admin/edit', // 账号编辑
    disable: base + 'backplat/admin/disable', // 账号禁用
    enable: base + 'backplat/admin/enable', // 账号启用
    accountInfo: base + 'backplat/admin/accountInfo', // 登录账号信息
    accountInfoEdit: base + 'backplat/admin/accountInfoEdit', // 登录账号信息修改
    changeMobileVerify: base +　'backplat/admin/changeMobileVerify', // 登陆账号修改手机号校验
    changeMobile: base + 'backplat/admin/changeMobile', // 登陆账号修改手机号
    changePass: base + 'backplat/admin/changePass', // 修改密码
    resetPass: base + 'backplat/admin/resetPass' // 重置密码
  }
}

export default adminApis

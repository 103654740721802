import Layout from '@/layout/index.vue'

const serviceRouter = {
  path: '',
  component: Layout,
  meta: { title: '服务商管理' },
  children: [
    {
      path: '/user/service/list',
      component: () => import('@/views/nb/user/service/list.vue'),
      meta: {
        title: '服务商列表'
      }
    },
    {
      path: '/user/service/detail',
      component: () => import('@/views/nb/user/service/detail.vue'),
      meta: {
        title: '服务商详情'
      }
    }

  ]
}

export default serviceRouter

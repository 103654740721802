import dayjs from 'dayjs'

const sortContent = (row) => {
  let _sort = {
    sort_content: '',
    sort_name: row.prop,
  };

  if (row.order === 'ascending') {
    _sort.sort_content = 'asc';
  } else if (row.order === 'descending') {
    _sort.sort_content = 'desc';
  }

  return _sort;
};

/**
 * 识别是否支持webp
 * @returns Boolean
 */
export const isSupportWebp = function () {
  const canvas = document.createElement('canvas');
  if ((canvas.getContext && canvas.getContext('2d'))) {
    return canvas.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  }
  return false;
};

/**
 * @param {String | number} timeStamp
 * @returns {String} timeString
 */
export function dateFormat(date, fmt) {
  if (!date) return '';
  let dateObj = new Date(date);
  if (!fmt) fmt = 'yyyy-MM-dd hh:mm:ss';

  if (!dateObj || dateObj == null) return null;
  let o = {
    'M+': dateObj.getMonth() + 1, // 月份
    'd+': dateObj.getDate(), // 日
    'h+': dateObj.getHours(), // 小时
    'm+': dateObj.getMinutes(), // 分
    's+': dateObj.getSeconds(), // 秒
    'q+': Math.floor((dateObj.getMonth() + 3) / 3), // 季度
    S: dateObj.getMilliseconds(), // 毫秒
  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (`${dateObj.getFullYear()}`).substr(4 - RegExp.$1.length));
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : ((`00${o[k]}`).substr((`${o[k]}`).length)));
  }
  return fmt;
}

/**
 * 返回时间格式
 * *formatType = 1 --->保留所有；formatType = 2 --->只保留年月日;formatType = 3 ---> 只保留时分秒
 */
export const formatDate = function (timeStamp, formatType = 1) {
  let time = new Date(parseInt(timeStamp));
  if (formatType === 1) {
    return `${time.getFullYear()}-${time.getMonth() + 1}-${time.getDate()} ${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}`;
  } if (formatType == 2) {
    return `${time.getFullYear()}-${time.getMonth() + 1}-${time.getDate()}`;
  } if (formatType == 3) {
    return `${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}`;
  }
};

/**
 * 识别是否支持webp
 * @returns Boolean
 */

const formatImage = (imgVal) => {
  const {
    url,
    w,
    h,
    mode = 0,
    r,
  } = imgVal;
  const urlReg = /(wsnbh-img\.hzanchu\.com)/;

  if (urlReg.test(url)) {
    let _URL = '';
    if (r) {
      _URL += `/circle,r_${r}`;
    }

    if (mode === 1) {
      _URL += `/resize,m_lfit,h_${h},w_${w}`;
    }

    return `${url}?x-oss-process=image/format${isSupportWebp() ? ',webp' : ''}${_URL}`;
  }
  return url;
};
// 获取url参数
export function getQueryString(name) {
  let reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i');
  let r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return null;
}
export {
  sortContent,
  formatImage,
};
/**
 * 设备来源（orderFrom）
 * @returns Boolean
 */
export function getOrderFrom(from) {
  let data = {
    1: 'App-Android',
    2: 'App-ios',
    3: '小程序',
    4: 'H5',
    5: '其他',
  };
  return data[from];
}

export function createDomID(len) {
  return Number(Math.random().toString().substr(3, len)).toString(36);
}

/**
 * @description:
 * @param {*} obj
 * @return {*} object
 */
export function deepClone(obj) {
  if (typeof obj !== 'object') return obj;
  let newobj = obj.constructor === Array ? [] : {};

  for (let i in obj) {
    if (obj.hasOwnProperty(i)) {
      if (typeof obj[i] === 'object') {
        newobj[i] = deepClone(obj[i]);
      } else {
        newobj[i] = obj[i];
      }
    }
  }
  return newobj;
}

/**
 * @description: 获取浏览器窗口宽高
 * @param {*}
 * @return {*}
 */
export function getBrowserWindowSize() {
  let de = document.documentElement;
  return {
    width: (window.innerWidth
      || (de && de.clientWidth)
      || document.body.offsetWidth
    ),
    height: (window.innerHeight
      || (de && de.clientHeight)
      || document.body.offsetHeight
    ),
  };
}

export function getViewportHeight() {
  let de = document.documentElement || document.body;
  return de.clientHeight;
}

export function getOffsetTopByBody(el) {
  let offsetTop = 0;
  while (el && el.tagName !== 'BODY') {
    offsetTop += el.offsetTop;
    el = el.offsetParent;
  }
  return offsetTop;
}

export function getOffsetLeftByBody(el) {
  let offsetLeft = 0;
  while (el && el.tagName !== 'BODY') {
    offsetLeft += el.offsetLeft;
    el = el.offsetParent;
  }
  return offsetLeft;
}

export function downloadURL(url) {
  const fileName = this.getFileName(decodeURI(url)).split('.')[0]
  fetch(url).then(res => res.blob()).then(blob => {
    const a = document.createElement('a')
    const fileUrl = URL.createObjectURL(blob)
    a.href = fileUrl
    a.download = `${this.downloadFileName ? `${fileName}_${this.downloadFileName}` : fileName}_${dayjs(new Date()).format('YYYYMMDD_HHmmss')}`  // 下载文件的名字
    document.body.appendChild(a)
    a.click()
    URL.revokeObjectURL(fileUrl)
    document.body.removeChild(a)
  })
}

export function downloadRes(res, fileName, type) {
  const file = new Blob([res], {type, crossOrigin: 'Anonymous'})
  const fileUrl = URL.createObjectURL(file)
  const a = document.createElement('a')
  a.href = fileUrl
  a.download = `${fileName ? `${fileName}_` : ''}${dayjs(new Date()).format('YYYYMMDD_HHmmss')}`  // 下载文件的名字
  document.body.appendChild(a)
  a.click()
  URL.revokeObjectURL(fileUrl)
  document.body.removeChild(a)
}
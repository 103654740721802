import moment from 'moment';

let timer = null;
export function lazy(fn, delay = 300) {
  if (timer) clearTimeout(timer);
  timer = setTimeout(fn, delay);
}

export function onlyValue() {
  return (((1 + Math.random()) * 0x10000) | 0).toString(16);
}

// 示例: <el-input v-model="forms.contentTitle" @input="inputLimit(forms, 'contentTitle', 20)"></el-input>
export function inputLimit(target, property, length) {
  const name = target[property];
  if (name.length > length) target[property] = name.substring(0, length);
}
// 示例: <el-input v-model="forms.contentTitle" @input="forms.aa = inputNumber(forms.aa, 2)"></el-input>
export function inputNumber(str, limit = 2) {
  const res = limit ? str.replace(/[^0-9\.]/g,'') : str.replace(/[^0-9]/g,'');
  const i = res.indexOf('.');
  if (i > 0) return res.substring(0, i + limit + 1);
  else return res;
}

// 示例: <el-input v-model="forms.contentTitle" @input="onlyInt(forms, 'price')"></el-input>
export function onlyInt(target, property) {
  target[property] = target[property].replace(/^0|\D+/, '');
}

export function handleTime(time, format = 'YYYY/MM/DD HH:mm:ss') {
  if (!time) return '';
  if (typeof time === 'string') time = +time;
  return moment(time).format(format);
}

export function clone(obj) {
  if (typeof obj == 'object' && obj !== null) {
    const res = obj instanceof Array ? [] : {};

    if (obj instanceof Array) {
      for (let i = 0; i < obj.length; i++) {
        res[i] = clone(obj[i]);
      }
    } else {
      for (let key in obj) {
        res[key] = clone(obj[key]);
      }
    }
    return res;
  } else {
    return obj;
  }
}

export function merge(target, obj) {
  for (let key in obj) {
    if (!target[key]) target[key] = {};

    if (Object.prototype.toString.call(obj[key]) === '[object Object]') {
      target[key] = merge(target[key], obj[key]);
    } else {
      target[key] = obj[key];
    }
  }
  return target;
}

export function importAll(ctx, defaults) {
  const map = {};

  ctx.keys().forEach(key => {
    const name = key.substring(2, key.lastIndexOf('.'));
    if (defaults) map[name] = ctx(key).default;
    else map[name] = ctx(key);
  });

  return map;
}

export function copyOrderId(vm, toBeCopy){
  let tag = document.createElement('input');
  tag.setAttribute('id', 'cp_zdy_input');
  tag.value = toBeCopy;
  
  document.getElementsByTagName('body')[0].appendChild(tag);
  document.getElementById('cp_zdy_input').select();
  document.execCommand('copy');
  document.getElementById('cp_zdy_input').remove();

  vm.$message.success('复制成功');
}

// validate
export function isEmptyRange(rule, value, callback) {
  if (!value) {
    callback(new Error(rule.message));
  } else {
    callback();
  }
};

// 上传图片接口
export async function uploadOssApi(data, onUploadProgress) {
  const params = new FormData();
  params.append('multipartFile', data);

  const options = {
    url: this.$api.nb_common.newUpload,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: params
  };

  if (onUploadProgress) options.onUploadProgress = onUploadProgress;
  return this.$axios(options);
}

export function isEmpty(rule, value, callback) {
  const type = Object.prototype.toString.call(value);
  const res = type === '[object String]' && !value.replace(/^\s+|\s+$/g, '')
            || type === '[object Array]' && !value.length
            || !value;

  if (res) callback(new Error(rule.message));
  else callback();
};
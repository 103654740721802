import Layout from '@/layout/index.vue';

const ContentRouter = {
  path: '',
  component: Layout,
  meta: { title: '内容管理' },
  children: [
    {
      path: '/nb/contentManage/nombo/index',
      component: () => import('@/views/nb/contentManage/nombo/index.vue'),
      meta: {
        title: '农民说管理',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/nb/contentManage/recommend/index',
      component: () => import('@/views/nb/contentManage/recommend/index.vue'),
      meta: {
        title: '农民说用户推荐管理',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/nb/contentManage/topic/index',
      component: () => import('@/views/nb/contentManage/createTopic/index.vue'),
      meta: {
        title: '话题管理',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/nb/contentManage/topic/detail',
      component: () => import('@/views/nb/contentManage/createTopic/detail/index.vue'),
      meta: {
        title: '话题详情',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/contentManage/comment/list',
      component: () => import('@/views/nb/contentManage/comment/index.vue'),
      meta: {
        title: '评论管理',
        icon: 'iconcaiwurenminbizhifux',
      },
    },
    {
      path: '/operation/minsutong',
      component: () => import('@/views/operation/minsutong/index.vue'),
      meta: {
        title: '桐游乡村小程序运营',
      },
      children: [
        {
          path: '24solarTerms',
          name: '24solarTerms',
          component: () => import('@/views/operation/minsutong/24solarTerms/index.vue'),
          meta: {
            title: '24节气宣传',
          },
        },
        {
          path: 'adManage',
          name: 'adManage',
          component: () => import('@/views/operation/minsutong/adManage/index.vue'),
          meta: {
            title: '广告位管理',
          },
        },
        {
          path: 'beautiCountryside',
          name: 'beautiCountryside',
          component: () => import('@/views/operation/minsutong/beautiCountryside/index.vue'),
          meta: {
            title: '美丽乡村',
          },
        },
        {
          path: 'beautiCountryside/add',
          name: 'addBeautiCountryside',
          component: () => import('@/views/operation/minsutong/beautiCountryside/add.vue'),
          meta: {
            title: '美丽乡村',
          },
        },
        {
          path: 'dontMissTonglu',
          name: 'dontMissTonglu',
          component: () => import('@/views/operation/minsutong/dontMissTonglu/index.vue'),
          meta: {
            title: '不可错过的桐庐',
          },
        },
        {
          path: 'recomProductConf',
          name: 'recomProductConf',
          component: () => import('@/views/operation/minsutong/recomProductConf/index.vue'),
          meta: {
            title: '推荐商品配置',
          },
        },
        {
          path: 'recomProductConf/detail',
          name: 'recomProductConfDetail',
          component: () => import('@/views/operation/minsutong/recomProductConf/detail.vue'),
          meta: {
            title: '商品管理',
          },
        },
        {
          path: 'comments',
          name: 'mstComments',
          component: () => import('@/views/operation/minsutong/comments/index.vue'),
          meta: {
            title: '民宿通评论管理',
          },
        },

      ],
    },
    {
      path: '/nb/contentManage/videos',
      component: () => import('@/views/operation/videos/index.vue'),
      meta: { title: '视频内容管理' },
    },
    {
      path: '/nb/contentManage/videosDetail',
      name: 'videosDetail',
      component: () => import('@/views/operation/videos/detail.vue'),
      meta: { title: '视频内容管理详情' },
    },
    {
      path: '/strollnongbo/article',
      component: () => import('@/views/nb/contentManage/article/index.vue'),
      meta: { title: '种草' },
    },
    {
      path: '/strollnongbo/article/detail',
      component: () => import('@/views/nb/contentManage/article/detail.vue'),
      meta: { title: '种草详情' },
    },
    {
      path: '/nb/contentManage/recommends',
      name: 'recommends',
      component: () => import('@/views/regionTemp.vue'),
      meta: { title: '推荐位管理' },
    },
    {
      path: '/nb/contentManage/recommends/detail',
      name: 'recommendsDetail',
      component: () => import('@/views/operation/recommends/detail.vue'),
      meta: { title: '推荐位详情' },
    },
    {
      path: '/nb/contentManage/blacklist',
      name: 'blacklist',
      component: () => import('@/views/regionTemp.vue'),
      meta: { title: '黑名单管理' },
    },
    {
      path: '/nb/channel',
      name: 'nbChannel',
      component: () => import('@/views/ldx/channel/channel.vue'),
      meta: { title: '渠道活码管理' },
    },
  ],
};

export default ContentRouter;
